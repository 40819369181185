import classNames from "classnames";
import { FC } from "react";
import LoadingIcon from "../../components/LodingIcon";
import ManifestHeaderTable from "./ManifestHeaderTable";
import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import {
  ManifestHeaderInterface,
  ManifestIncidence,
  ManifestStatus,
  ManifestType,
} from "../../interfaces/ManifestInterface";
import {
  createPendingDetails,
  removeAllProcessedDetails,
  setReceiptHeader,
  setReceiptLoadFromDB,
} from "../../store/slices/receipt";
import { alertService, loaderService } from "../../services";
import {
  createObjectManifestDetailSingle,
  getManifestDetails,
} from "../../services/manifestServices";

interface ReceiptDraftTableProps {
  loadingReceipts: boolean;
  postponedReceiptList: ManifestHeaderInterface[];
}
const ReceiptDraftTable: FC<ReceiptDraftTableProps> = ({ loadingReceipts, postponedReceiptList }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClickPostponedManifest = async (
    manifest: ManifestHeaderInterface
  ) => {
    loaderService.start();
    const masterShipmentHeaderID = manifest.masterShipmentHeaderID;

    if (!masterShipmentHeaderID) {
      alertService.error("El manifiesto parece no estar registrado.");
      loaderService.stop();
      return;
    }

    const details = await getManifestDetails(masterShipmentHeaderID);

    if (details.didError || details.model === null) {
      alertService.error(
        "Hubo un error obteniendo los detalles del manifiesto."
      );
      loaderService.stop();
      return;
    }

    const filteredDetails = details.model.filter(
      (d) => d.statusID !== ManifestStatus.ELIMINADO
    );

    const receiptDetails = createObjectManifestDetailSingle(
      filteredDetails.map((d) => ({
        ...d,
        incidence: ManifestIncidence.NOT_VALIDATED,
        errors: "",
        warnings: "",
        incidenceWarning: false,
      })),
      ManifestType.DESPACHO
    );

    dispatch(setReceiptLoadFromDB(true));
    dispatch(createPendingDetails(receiptDetails));
    dispatch(removeAllProcessedDetails());
    dispatch(setReceiptHeader(manifest));

    loaderService.stop();
    navigate("/receipt/create");
  };

  return (
    <div
      className={classNames(
        "flex flex-1 flex-col bg-white gap-4 rounded-lg border px-6 py-6 w-full"
      )}
    >
      <h1 className="text-2xl font-semibold text-gray-800 truncate mb-2">
        Recepciones en Borrador
      </h1>
      <div
        className={classNames(
          "flex flex-1 flex-row bg-white gap-4 rounded-lg justify-center items-center"
        )}
      >
        {loadingReceipts && (
          <div className="my-12">
            <LoadingIcon size="3.5rem" />
          </div>
        )}

        {!loadingReceipts && (
          <ManifestHeaderTable
            manifests={postponedReceiptList}
            onManifestClick={handleClickPostponedManifest}
          />
        )}
      </div>
    </div>
  );
};

export default ReceiptDraftTable;
