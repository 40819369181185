import api from "./api";
import { Nullable } from "../interfaces";
import { DigitalDocsInterfaceRequest } from "../interfaces/DigitalDocsInterface";
import { FileDocument } from "./shipmentServices";

const baseURLAPI = `${process.env.REACT_APP_API_HOST}/v1/Shipment`;

export const getLiabilityWaiverDigitalDoc = async (
  request: DigitalDocsInterfaceRequest
): Promise<Nullable<FileDocument>> => {
  const uri = `${baseURLAPI}/DownloadDocumentDeliveryLiability`;
  try {
    const response = await api.post(uri, request, {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    });

    if (response.status === 200) {
      return {
        file: response.data,
        fileName:
          "DigitalLiabilityWaiver_" + request.list[0].shipmentHeaderID + ".pdf",
      };
    }
  } catch (error) {}
  return null;
};

export const getPODDigitalDoc = async (
  request: DigitalDocsInterfaceRequest
): Promise<Nullable<FileDocument>> => {
  const uri = `${baseURLAPI}/DownloadDocumentDeliveryDigital`;
  try {
    const response = await api.post(uri, request, {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    });

    if (response.status === 200) {
      return {
        file: response.data,
        fileName: "DigitalPOD_" + request.list[0].shipmentHeaderID + ".pdf",
      };
    }
  } catch (error) {}
  return null;
};
