import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ManifestDetailSingleInterface,
  ManifestHeaderInterface,
} from "../../interfaces/ManifestInterface";

interface ReceiptState {
  loadFromDB: boolean;
  receiptHeader: ManifestHeaderInterface | null;
  dispatchHeader: ManifestHeaderInterface | null;
  pendingDetails: { [id: string]: ManifestDetailSingleInterface };
  processedDetails: { [id: string]: ManifestDetailSingleInterface };
}

const initialState: ReceiptState = {
  loadFromDB: false,
  receiptHeader: null,
  dispatchHeader: null,
  pendingDetails: {},
  processedDetails: {},
};

const receipt = createSlice({
  name: "receipt",
  initialState,
  reducers: {
    setReceiptHeader: (
      state,
      action: PayloadAction<ManifestHeaderInterface | null>
    ) => {
      state.receiptHeader = action.payload;
    },
    setDispatchHeader: (
      state,
      action: PayloadAction<ManifestHeaderInterface | null>
    ) => {
      state.dispatchHeader = action.payload;
    },
    setReceiptLoadFromDB: (state, action: PayloadAction<boolean>) => {
      state.loadFromDB = action.payload;
    },
    clearReceiptValues: (state) => {
      state.receiptHeader = null;
      state.dispatchHeader = null;
      state.pendingDetails = {};
      state.processedDetails = {};
    },
    addPendingDetails: (
      state,
      action: PayloadAction<ManifestDetailSingleInterface[]>
    ) => {
      for (const detail of action.payload) {
        const id = `${detail.shipmentNumber}-${detail.pieceNumber}`;
        if (!(id in state.pendingDetails)) {
          state.pendingDetails[id] = {
            ...detail,
            _date: new Date().toISOString(),
          };
        }
      }
    },
    createPendingDetails: (
      state,
      action: PayloadAction<ManifestDetailSingleInterface[]>
    ) => {
      const pendingDetails: { [id: string]: ManifestDetailSingleInterface } =
        {};
      for (const detail of action.payload) {
        const id = `${detail.shipmentNumber}-${detail.pieceNumber}`;
        pendingDetails[id] = {
          ...detail,
          _date: new Date().toISOString(),
        };
      }
      state.pendingDetails = pendingDetails;
    },
    updatePendingDetails: (
      state,
      action: PayloadAction<ManifestDetailSingleInterface[]>
    ) => {
      for (const detail of action.payload) {
        const id = `${detail.shipmentNumber}-${detail.pieceNumber}`;
        if (id in state.pendingDetails) {
          state.pendingDetails[id] = {
            ...detail,
            _date: new Date().toISOString(),
          };
        }
      }
    },
    removePendingDetails: (
      state,
      action: PayloadAction<ManifestDetailSingleInterface[]>
    ) => {
      const pendingDetails: { [id: string]: ManifestDetailSingleInterface } =
        {};
      const idsToRemove = new Set(
        action.payload.map((d) => `${d.shipmentNumber}-${d.pieceNumber}`)
      );

      for (const id in state.pendingDetails) {
        if (!idsToRemove.has(id)) {
          pendingDetails[id] = state.pendingDetails[id];
        }
      }

      state.pendingDetails = pendingDetails;
    },
    addProcessedDetails: (
      state,
      action: PayloadAction<ManifestDetailSingleInterface[]>
    ) => {
      for (const detail of action.payload) {
        const id = `${detail.shipmentNumber}-${detail.pieceNumber}`;
        if (!(id in state.processedDetails)) {
          state.processedDetails[id] = {
            ...detail,
            _date: new Date().toISOString(),
          };
        }
      }
    },
    updateProcessedDetails: (
      state,
      action: PayloadAction<ManifestDetailSingleInterface[]>
    ) => {
      for (const detail of action.payload) {
        const id = `${detail.shipmentNumber}-${detail.pieceNumber}`;
        if (id in state.processedDetails) {
          state.processedDetails[id] = {
            ...detail,
            _date: new Date().toISOString(),
          };
        }
      }
    },
    removeProcessedDetails: (
      state,
      action: PayloadAction<ManifestDetailSingleInterface[]>
    ) => {
      const processedDetails: { [id: string]: ManifestDetailSingleInterface } =
        {};
      const idsToRemove = new Set(
        action.payload.map((d) => `${d.shipmentNumber}-${d.pieceNumber}`)
      );

      for (const id in state.processedDetails) {
        if (!idsToRemove.has(id)) {
          processedDetails[id] = state.processedDetails[id];
        }
      }

      state.processedDetails = processedDetails;
    },
    removeAllProcessedDetails: (state) => {
      state.processedDetails = {};
    },
  },
});

export const {
  setReceiptHeader,
  setDispatchHeader,
  setReceiptLoadFromDB,
  clearReceiptValues,
  addPendingDetails,
  createPendingDetails,
  updatePendingDetails,
  removePendingDetails,
  addProcessedDetails,
  updateProcessedDetails,
  removeProcessedDetails,
  removeAllProcessedDetails,
} = receipt.actions;

export default receipt.reducer;
