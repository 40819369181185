import { FC, useMemo } from "react";
import {
  ManifestDetailSingleInterface,
  ManifestIncidence,
} from "../../interfaces/ManifestInterface";
import classNames from "classnames";

interface ManifestInvalidDetailTableProps {
  details: ManifestDetailSingleInterface[];
}
const ManifestInvalidDetailTable: FC<ManifestInvalidDetailTableProps> = ({
  details,
}) => {
  const filteredDetails = useMemo(() => {
    return details.filter((d) => d.incidence !== ManifestIncidence.NO_ERROR);
  }, [details]);

  return (
    <div className="max-w-[40rem] max-h-[15rem] overflow-y-auto">
      <table className="table-auto w-full">
        <thead>
          <tr className="text-center">
            <th className="px-4 py-2 font-semibold text-xs text-gray-800">
              GUÍA
            </th>

            <th className="px-4 py-2 font-semibold text-xs text-gray-800">
              PIEZA
            </th>

            <th className="px-4 py-2 font-semibold text-left text-xs text-gray-800">
              ERRORES
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredDetails.map((detail, index) => (
            <tr
              key={`${detail.shipmentNumber}-${detail.pieceNumber}`}
              className={classNames(
                "hover:bg-gray-100 text-center transition-opacity duration-500",
                index % 2 === 0 ? "bg-gray-50" : "bg-white"
              )}
            >
              <td className="px-4 py-2 text-xs text-gray-700 font-semibold truncate">
                {detail.shipmentNumber}
              </td>

              <td className="px-4 py-2 text-xs text-gray-700 font-semibold truncate">
                {detail.pieceNumber}
              </td>

              <td className="px-4 py-2 text-xs text-gray-700 text-left font-semibold">
                {detail.errors.split(".").join("\n")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManifestInvalidDetailTable;
