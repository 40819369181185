import { FC, useMemo, useState } from "react";
import { FormTextInput } from "../../components/FormFields";
import { useAppSelector } from "../../store/hooks";
import classNames from "classnames";
import {
  ManifestDetailSingleInterface,
  ManifestIncidence,
} from "../../interfaces/ManifestInterface";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import DispatchDetailItem from "./DispatchDetailItem";

interface DispatchDetailTableProps {
  detailsBeingValidated: string[];
  addDetailsBeingValidated: (details: ManifestDetailSingleInterface[]) => void;
  removeDetailsBeingValidated: (details: ManifestDetailSingleInterface[]) => void;
  onRetry: () => void;
  onRetryDetail: (detail: ManifestDetailSingleInterface) => Promise<void>;
}
const DispatchDetailTable: FC<DispatchDetailTableProps> = ({
  detailsBeingValidated,
  addDetailsBeingValidated,
  removeDetailsBeingValidated,
  onRetry,
  onRetryDetail,
}) => {
  const pendingDetails = useAppSelector(
    (state) => state.dispatch.pendingDetails
  );
  const processedDetails = useAppSelector(
    (state) => state.dispatch.processedDetails
  );

  const [search, setSearch] = useState("");

  const detailList = useMemo(() => {
    return Object.keys(pendingDetails)
      .map((id) => pendingDetails[id])
      .concat(Object.keys(processedDetails).map((id) => processedDetails[id]))
      .sort((a, b) => {
        if (a._date === undefined) return 1;
        if (b._date === undefined) return -1;
        return a._date < b._date ? 1 : -1;
      });
  }, [pendingDetails, processedDetails]);

  const filteredDetailList = useMemo(() => {
    const tokens = search.toLowerCase().split(" ");

    return detailList.filter((d) =>
      tokens.every(
        (t) =>
          d.shipmentNumber.toLowerCase().includes(t) ||
          d.serviceName?.toLowerCase().includes(t) ||
          d.locationDestinationCode.toLowerCase().includes(t)
      )
    );
  }, [detailList, search]);

  const totalShipments = useMemo(() => {
    const shipmentNumbers = new Set();

    for (const detail of filteredDetailList) {
      shipmentNumbers.add(detail.shipmentNumber);
    }

    return shipmentNumbers.size;
  }, [filteredDetailList]);

  const totalWeight = useMemo(() => {
    return filteredDetailList.reduce((acc, d) => acc + d.physicalWeight, 0);
  }, [filteredDetailList]);

  const reloadable = useMemo(() => {
    return (
      detailList.some(
        (d) =>
          (d.incidence === ManifestIncidence.CRITIC_ERROR ||
            d.incidence === ManifestIncidence.NOT_VALIDATED) &&
          !detailsBeingValidated.some(
            (v) => v === `${d.shipmentNumber}-${d.pieceNumber}`
          )
      )
    );
  }, [detailsBeingValidated, detailList]);

  return (
    <div className="flex flex-col w-full bg-white gap-4 rounded-lg border px-8 pb-6 pt-4">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between my-4">
        <div className="flex-1 sm:pr-2 mb-4">
          <FormTextInput
            value={search}
            label="Filtrar"
            placeholder="Por número de guía, origen, destino o servicio"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="flex-1 sm:pl-2 mb-4">
          <label className="block text-m font-medium leading-6 text-gray-800 mb-2 truncate">
            Totales
          </label>
          <div className="border bg-white rounded-xl shadow-md hover:shadow-lg flex flex-col sm:flex-row sm:items-center p-2">
            <div className="flex items-center w-full sm:w-1/3">
              <label className="block text-m leading-6 text-gray-800 truncate mb-1 sm:mb-0 mr-2">
                Total guías:
              </label>
              <div className="text-gray-700 text-sm font-semibold sm:my-1">
                {totalShipments}
              </div>
            </div>
            <div className="flex items-center w-full sm:w-1/3">
              <label className="block text-m leading-6 text-gray-800 truncate mb-1 sm:mb-0 mr-2">
                Total piezas:
              </label>
              <div className="text-gray-700 text-sm font-semibold sm:my-1">
                {filteredDetailList.length}
              </div>
            </div>
            <div className="flex items-center w-full sm:w-1/3">
              <label className="block text-m leading-6 text-gray-800 truncate mb-1 sm:mb-0 mr-2">
                Total peso:
              </label>
              <div className="text-gray-700 text-sm font-semibold sm:my-1">
                {totalWeight.toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto max-h-96 overflow-y-auto">
        <table className="table-auto w-full">
          <thead>
            <tr className="text-center">
              <th className="px-4 py-2 font-semibold text-xs text-gray-800">
                GUÍA
              </th>

              <th className="px-4 py-2 font-semibold text-xs text-gray-800">
                ORIGEN
              </th>

              <th className="px-4 py-2 font-semibold text-xs text-gray-800">
                DESTINO
              </th>

              <th className="px-4 py-2 font-semibold text-xs text-gray-800">
                SERVICIO
              </th>

              <th className="px-4 py-2 font-semibold text-xs text-gray-800">
                PIEZA
              </th>

              <th className="px-4 py-2 font-semibold text-xs text-gray-800">
                TOTAL PIEZAS
              </th>

              <th className="px-4 py-2 font-semibold text-xs text-gray-800">
                PESO
              </th>

              <th className="px-4 py-2 font-semibold text-xs text-gray-800">
                ESTATUS
              </th>

              <th className="px-4 py-2 font-semibold text-xs text-gray-800">
                <div className="flex items-center justify-center gap-1 h-7">
                  <p>ACCIÓN</p>

                    <ArrowPathIcon
                      className={classNames(
                        "text-blue-600 w-7 h-7",
                        !reloadable && "hidden",
                        reloadable && "cursor-pointer hover:animate-spin"
                      )}
                      onClick={onRetry}
                      title="Reintentar validaciones"
                    />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredDetailList.map((detail, index) => (
              <DispatchDetailItem
                detail={detail}
                index={index}
                key={`${detail.shipmentNumber}-${detail.pieceNumber}-${index}`}
                detailsBeingValidated={detailsBeingValidated}
                onRetry={async () => await onRetryDetail(detail)}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DispatchDetailTable;
