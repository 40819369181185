import { ShipmentGuideModel } from "../interfaces";

const BARCODE_SIZE = 30;

const REGEX_BUCODE = /\b0+/g;

export const processBarcode = (barCode: string): ShipmentGuideModel | null => {
  if (barCode.length !== BARCODE_SIZE) {
    return null;
  }

  try {
    return {
      serviceCode: parseInt(barCode.substring(0, 2)),
      sourceBUCode: (isNaN(barCode.substring(2, 7) as any)
        ? barCode.substring(2, 7).replace(REGEX_BUCODE, "")
        : parseInt(barCode.substring(2, 7))
      ).toString(),
      shipmentNumber: parseInt(barCode.substring(7, 15)).toString(),
      pieceNumber: parseInt(barCode.substring(15, 18)),
      locationDestinationCode: barCode.substring(18, 21),
      totalPiece: parseInt(barCode.substring(21, 24)),
      totalWeigth: parseFloat(
        `${barCode.substring(24, 27)}.${barCode.substring(27, 30)}`
      ),
      barCode: barCode,
    };
  } catch (error) {
    return null;
  }
};
