import * as Yup from "yup";
import { FC, useMemo, useState } from "react";
import { FormTextInput } from "../../components/FormFields";
import { PrimaryButton } from "../../components/Buttons";
import { processBarcode } from "../../utils";
import { alertService } from "../../services";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  ManifestDetailSingleInterface,
  ManifestIncidence,
  ManifestType,
} from "../../interfaces/ManifestInterface";
import {
  addPendingDetails,
  addProcessedDetails,
  removePendingDetails,
} from "../../store/slices/receipt";
import { Formik, FormikHelpers } from "formik";
import {
  createManifestDetail,
  createObjectManifestDetail,
  getOfficeShipment,
  validationManifestDetail,
} from "../../services/manifestServices";

interface ShipmentPieceFormValues {
  shipmentNumber: string;
  pieceNumber: number;
  random: number;
}
const INITIAL_VALUES: ShipmentPieceFormValues = {
  shipmentNumber: "",
  pieceNumber: 0,
  random: 0,
};

interface ReceiptDetailFormProps {
  addDetailsBeingValidated: (details: ManifestDetailSingleInterface[]) => void;
  removeDetailsBeingValidated: (
    details: ManifestDetailSingleInterface[]
  ) => void;
}
const ReceiptDetailForm: FC<ReceiptDetailFormProps> = ({
  addDetailsBeingValidated,
  removeDetailsBeingValidated,
}) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const businessServices = useAppSelector(
    (state) => state.inmutable.businessServices
  );
  const receiptHeader = useAppSelector((state) => state.receipt.receiptHeader);
  const pendingDetails = useAppSelector(
    (state) => state.receipt.pendingDetails
  );
  const processedDetails = useAppSelector(
    (state) => state.receipt.processedDetails
  );

  const [barCode, setBarCode] = useState("");
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);

  const validationSchema = Yup.object().shape({
    shipmentNumber: Yup.string().required("El número de guía es requerido"),
    pieceNumber: Yup.number().required("El número de pieza es requerido"),
  });

  const detailList = useMemo(() => {
    return Object.keys(processedDetails)
      .map((id) => processedDetails[id])
      .concat(Object.keys(pendingDetails).map((id) => pendingDetails[id]));
  }, [processedDetails, pendingDetails]);

  const addDetail = async (detail: ManifestDetailSingleInterface) => {
    const userLogin = user.user?.userLogin;
    const buCode = user.businessUnit?.code;
    const masterShipmentHeaderID = receiptHeader?.masterShipmentHeaderID;

    if (!userLogin) {
      alertService.error(
        "No hay un nombre de usuario asociado a la sesión actual"
      );
      return;
    }
    if (!buCode) {
      alertService.error("No hay una tienda asociada a la sesión del usuario");
      return;
    }
    if (!masterShipmentHeaderID) {
      alertService.error(
        "Se necesita crear el manifiesto de recepción antes de agregar piezas"
      );
      return;
    }
    if (
      detailList.some(
        (d) =>
          d.shipmentNumber === detail.shipmentNumber &&
          d.pieceNumber === detail.pieceNumber
      )
    ) {
      alertService.warn(
        `Ya se agregó la pieza ${detail.pieceNumber} de la guía ${detail.shipmentNumber}`,
        "",
        { autoClose: false }
      );
      return;
    }

    dispatch(addPendingDetails([detail]));
    const validations = await validationManifestDetail(
      [detail],
      buCode,
      ManifestType.RECEPCION
    );

    if (
      validations.didError ||
      !validations.model ||
      validations.model.length === 0
    ) {
      alertService.error(
        "Hubo un error validando la pieza",
        validations.errorMessage
      );
      return;
    }

    const validatedDetail = {
      ...detail,
      incidence: validations.model[0].incidence,
      errors: validations.model[0].errors,
    };
    if (validatedDetail.incidence !== ManifestIncidence.NO_ERROR) {
      dispatch(removePendingDetails([validatedDetail]));
      dispatch(addProcessedDetails([validatedDetail]));
      return;
    }

    const manifestDetail = createObjectManifestDetail(
      [validatedDetail],
      masterShipmentHeaderID,
      userLogin,
      buCode,
      false
    );
    const insertedDetail = await createManifestDetail(manifestDetail);

    if (insertedDetail.didError || !insertedDetail.model) {
      alertService.error(
        "Hubo un error insertando la pieza al manifiesto",
        insertedDetail.errorMessage
      );
      const invalidDetail = {
        ...detail,
        incidence: ManifestIncidence.ERROR,
        errors: insertedDetail.errorMessage,
      };
      dispatch(removePendingDetails([invalidDetail]));
      dispatch(addProcessedDetails([invalidDetail]));
      return;
    }

    const completeDetail = {
      ...validatedDetail,
      containerDetailID: insertedDetail.model[0].containerDetailID,
    };
    dispatch(removePendingDetails([completeDetail]));
    dispatch(addProcessedDetails([completeDetail]));
  };

  const handleBarCodeSubmit = async (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Enter" && barCode !== "") {
      const shipment = processBarcode(barCode);
      if (!shipment) {
        alertService.error(
          "El código de barra no pertenece a una enumeración de Tealca."
        );
        return;
      }

      const service = businessServices.find(
        (s) => s.serviceCode === shipment.serviceCode.toString()
      );
      if (!service) {
        alertService.error(
          "El servicio de la guía asociada a la pieza no está habilitada para esta tienda."
        );
        return;
      }

      // Reload form data
      setInitialValues({
        ...INITIAL_VALUES,
        random: Math.random(),
      });
      setBarCode("");

      const detail: ManifestDetailSingleInterface = {
        shipmentDetailID: shipment.shipmentDetailID,
        containerDetailID: null,
        shipmentNumber: shipment.shipmentNumber,
        buCodeSource: shipment.sourceBUCode,
        serviceCode: shipment.serviceCode.toString(),
        serviceID: service?.serviceID,
        serviceName:
          service?.serviceShortName?.toUpperCase() ||
          service?.serviceDescription?.toUpperCase() ||
          "",
        pieceNumber: shipment.pieceNumber,
        locationDestinationCode: shipment.locationDestinationCode,
        totalPieces: shipment.totalPiece,
        manifestType: ManifestType.DESPACHO,
        physicalWeight: shipment.totalWeigth ?? 0,
        creationDate: shipment.creationDate ?? "",
        daysCreation: 0,
        difference: 0,
        incidence: ManifestIncidence.NOT_VALIDATED,
        incidenceWarning: false,
        warnings: "",
        errors: "",
      };
      addDetailsBeingValidated([detail]);
      await addDetail(detail);
      removeDetailsBeingValidated([detail]);
    }
  };

  const handleShipmentPieceSubmit = async (
    values: ShipmentPieceFormValues,
    formik: FormikHelpers<ShipmentPieceFormValues>
  ) => {
    const shipment = await getOfficeShipment(values.shipmentNumber);
    if (!shipment) {
      formik.setFieldError(
        "shipmentNumber",
        `La guía ${values.shipmentNumber} no fue encontrada.`
      );
      return;
    }

    const shipmentDetail = shipment.shipmentDetail.find(
      (d) => d.pieceNumber === values.pieceNumber
    );
    if (!shipmentDetail) {
      formik.setFieldError(
        "pieceNumber",
        `La guía ${values.shipmentNumber} no tiene una pieza con número ${values.pieceNumber}.`
      );
      return;
    }

    const service = businessServices.find(
      (s) => s.serviceCode === shipment.serviceCode.toString()
    );
    if (!service) {
      alertService.error(
        "El servicio de la guía asociada a la pieza no está habilitada para esta tienda."
      );
      return;
    }

    formik.resetForm();
    setBarCode("");

    const detail: ManifestDetailSingleInterface = {
      shipmentDetailID: shipmentDetail.shipmentDetailID,
      containerDetailID: null,
      shipmentNumber: shipment.shipmentNumber,
      buCodeSource: shipment.buCodeSource,
      serviceCode: shipment.serviceCode.toString(),
      serviceID: service?.serviceID,
      serviceName:
        service?.serviceShortName?.toUpperCase() ||
        service?.serviceDescription?.toUpperCase() ||
        "",
      pieceNumber: shipmentDetail.pieceNumber,
      locationDestinationCode: shipment.locationConsigneeCode,
      totalPieces: shipment.totalPieces,
      manifestType: ManifestType.DESPACHO,
      physicalWeight: shipment.shipmentDetail.reduce(
        (acc, d) => acc + d.chargedWeight,
        0
      ),
      creationDate: shipment.creationDate ?? "",
      daysCreation: 0,
      difference: 0,
      incidence: ManifestIncidence.NOT_VALIDATED,
      incidenceWarning: false,
      warnings: "",
      errors: "",
    };
    addDetailsBeingValidated([detail]);
    await addDetail(detail);
    removeDetailsBeingValidated([detail]);
  };

  return (
    <div className="flex flex-col sm:flex-row w-full bg-white gap-4 rounded-lg border px-8 pb-6 pt-4">
      <div className="flex-1">
        <FormTextInput
          id="barCode"
          name="barCode"
          label="Código de Barras"
          value={barCode}
          onChange={(e) => setBarCode(e.target.value)}
          onKeyUp={handleBarCodeSubmit}
          placeholder="Escanee el código de barras"
          maxLength={30}
        />
      </div>

      <div className="flex w-[1px] bg-gray-300" />

      <Formik<ShipmentPieceFormValues>
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleShipmentPieceSubmit}
      >
        {(formik) => (
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-1 flex-row gap-4"
          >
            <div className="flex-[2]">
              <FormTextInput
                id="shipmentNumber"
                name="shipmentNumber"
                label="Número de Guía"
                value={formik.values.shipmentNumber}
                onChange={formik.handleChange}
                error={formik.errors.shipmentNumber}
                placeholder="Introduzca el número de guía"
                maxLength={20}
              />
            </div>

            <div className="flex-1">
              <FormTextInput
                id="pieceNumber"
                name="pieceNumber"
                label="Número de Pieza"
                type="number"
                value={formik.values.pieceNumber}
                onChange={formik.handleChange}
                error={formik.errors.pieceNumber}
                min={0}
                max={999}
                placeholder="Introduzca el número de pieza"
              />
            </div>

            <div className="flex items-end">
              <PrimaryButton>Agregar</PrimaryButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ReceiptDetailForm;
