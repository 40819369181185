import { FC, useEffect, useMemo, useState } from "react";
import Modal from "../Modal";
import WarningModal from "../WarningModal";
import { useAppSelector } from "../../store/hooks";
import {
  savePOD,
  savePODAttachment,
  updatePOD,
} from "../../services/podServices";
import { LinkText, PrimaryButton, SecondaryButton } from "../Buttons";
import { AccountInterface, ShipmentInterface, ShipmentStatus } from "../../interfaces";
import FingerprintReader from "../FingerprintReader";
import WebcamCapture from "../Webcam";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import {
  FormTextInput,
  FormFileUpload,
  FormTextAreaInput,
  FormSimpleRadioGroup,
} from "../FormFields";
import {
  getShipment,
  alertService,
  loaderService,
  updateShipmentStatus,
  getBUConfiguration,
} from "../../services";

interface PickupModalProps {
  openModal: boolean;
  shipmentNumbers: string[];
  shipments?: ShipmentInterface[];
  consignee: AccountInterface;
  liabilityShipments: string[];
  onSubmit?: () => void;
  onDownloadPOD?: () => void;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export const PickupModal: FC<PickupModalProps> = ({
  openModal,
  shipments,
  shipmentNumbers,
  consignee,
  liabilityShipments,
  setOpenModal,
  onSubmit = () => { },
  onDownloadPOD = () => { },
}) => {
  const user = useAppSelector((state) => state.user);
  const buCode = useAppSelector((state) => state.user.businessUnit?.code);
  const taxIdentificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );
  const loading = loaderService.useIsLoading();

  const [error, setError] = useState("");
  const [nameError, setNameError] = useState("");
  const [idError, setIdError] = useState("");
  const [name, setName] = useState<string>();
  const [id, setId] = useState<string>();
  const [observation, setObservation] = useState<string>("");
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [attachments, setAttachments] = useState<string[]>([""]);
  const [fingerprint, setFingerprint] = useState<string | undefined>();
  const [fingerprintUploaded, setFingerprintUploaded] = useState(false);
  const [openModalWarning, setOpenModalWarning] = useState(false);
  const [photoUploaded, setPhotoUploaded] = useState(false);
  const [isDigitalPODRequired, setIsDigitalPODRequired] = useState(false);
  const [fingerprintTemplate, setFingerprintTemplate] = useState<
    string | undefined
  >();
  const [attachmentModes, setAttachmentModes] = useState<string[]>(
    attachments.map(() => "capture")
  );

  const identificationType = useMemo(() => {
    return taxIdentificationTypes.find(
      (t) => t.taxIdentificationTypeId === consignee?.taxIdentificationTypeID
    );
  }, [consignee, taxIdentificationTypes]);

  const handleAttachmentModeChange = (index: number, mode: string) => {
    const updatedModes = [...attachmentModes];
    updatedModes[index] = mode;
    setAttachmentModes(updatedModes);
  };

  const handleFileChange = (file: File[], index: number) => {
    // Verify that the file is an image
    if (file[0]?.type.startsWith("image/")) {
      setError("");
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onloadend = () => {
        const base64data = reader.result as string;
        const updatedAttachments = [...attachments];
        updatedAttachments[index] = base64data;
        setAttachments(updatedAttachments);
        setAttachmentModes((prev) => {
          const updatedModes = [...prev];
          updatedModes[index] = "capture";
          return updatedModes;
        });
      };
    } else {
      setError("El archivo debe ser una imagen");
    }
  };

  const handleSubmit = async () => {
    loaderService.start();

    if (!name) {
      loaderService.stop();
      setNameError("El nombre es requerido");
      return;
    }
    if (!id) {
      loaderService.stop();
      setIdError("La cédula de identidad es requerida");
      return;
    }
    setOpenModal(false);

    let shipmentsToDeliver: ShipmentInterface[] = [];
    if (!shipments || shipments?.length === 0) {
      // Get all shipments
      for (const shipmentNumber of shipmentNumbers) {
        const shipment = await getShipment(shipmentNumber);
        if (!!shipment) {
          shipmentsToDeliver.push(shipment);
        }
      }
    } else {
      shipmentsToDeliver = shipments;
    }


    for (const shipment of shipmentsToDeliver) {
      // Create POD
      const podResponse = await savePOD(
        shipment.id!,
        shipment.consignee.id,
        name,
        id,
        observation,
        user.user!,
        user.businessUnit!
      );
      if (!podResponse || !!podResponse.didError || !podResponse.model) {
        alertService.error(
          `Hubo un error al crear el POD de la guía ${shipment.shipmentNumber}.`,
          podResponse.errorMessage
        );
        loaderService.stop();
        return;
      }

      let fingerprintAttachmentID: string | null = null;

      if (fingerprint || attachments) {
        // Save POD attachment
        const attachmentResponse = await savePODAttachment(
          fingerprint,
          attachments,
          podResponse.model.podid,
          user.user!.userLogin
        );

        if (!!attachmentResponse.didError) {
          alertService.error(
            `Hubo un error al guardar la huella y la foto de la guía ${shipment.shipmentNumber}.`
          );
          loaderService.stop();
          return;
        }
        fingerprintAttachmentID =
          attachmentResponse.model?.podAttachmentID ?? null;
      }

      // Update POD with fingerprint attachment or physical signature
      const podUpdateResponse = await updatePOD(
        [shipment.shipmentNumber!],
        fingerprintAttachmentID,
        checkboxChecked,
        user.user!.userLogin
      );

      if (!!podUpdateResponse.didError) {
        alertService.error(
          "Hubo un error al actualizar el POD con la huella o la firma."
        );
        loaderService.stop();
        return;
      }

      // Update shipment status
      const response = await updateShipmentStatus(
        ShipmentStatus.DELIVERED,
        shipment.id,
        shipment.shipmentNumber,
        user.user ?? undefined
      );

      if (!!response.didError) {
        alertService.error(
          `Hubo un error al actualizar el estado de la guía ${shipment.shipmentNumber}.`
        );
        loaderService.stop();
        return;
      }
    }

    loaderService.stop();
    onSubmit();
  };

  const canSubmit = () => {
    return (
      (fingerprintUploaded || photoUploaded || checkboxChecked) &&
      !loading &&
      name &&
      id &&
      id.length >= 7
    );
  };

  const onChangeCheckbox = (check: boolean) => {
    if (fingerprint || attachments) {
      if (!checkboxChecked) {
        setOpenModalWarning(true);
      } else {
        setCheckboxChecked(false);
      }
    } else {
      setCheckboxChecked(check);
    }
  };

  const addAttachmentSlot = () => {
    if (attachments.length < 5) {
      setAttachments([...attachments, ""]);
      setAttachmentModes([...attachmentModes, "capture"]);
    }
  };

  useEffect(() => {
    if (identificationType && !["J-", "G-"].includes(identificationType.abreviationName)) {
      setName(consignee.accountFullName);
      setId(consignee.identificationNumber);
    }

  }, [consignee, identificationType, setName, setId]);

  useEffect(() => {
    setPhotoUploaded(attachments.length > 0 && attachments.some((attachment) => attachment !== ""));
    setFingerprintUploaded(!!fingerprint);
  }, [attachments, fingerprint]);

  useEffect(() => {
    if (name === "")
      setNameError("El nombre de la persona que recibe es requerida");
    else if (name !== "") setNameError("");
  }, [name]);

  useEffect(() => {
    if (id === "") setIdError("La cédula de identidad es requerida");
    else if (id !== "") setIdError("");
    if (id !== undefined && id.length < 7 && id !== "")
      setIdError("La cédula debe tener al menos 7 dígitos");
  }, [id]);

  useEffect(() => {
    const loadBUConfiguration = async () => {
      const buConfiguration = await getBUConfiguration(buCode!);
      setIsDigitalPODRequired(buConfiguration.model?.digitalLiabilityWaiver!);
    };
    if (buCode) loadBUConfiguration();
  }, [buCode]);

  return (
    <Modal
      openModal={openModal}
      setOpenModal={setOpenModal}
      className="w-full max-w-[40rem]"
    >
      <div className="flex flex-col md:flex-row md:gap-2 mb-4">
        <p className="font-light text-xl">
          {`Entrega de ${shipmentNumbers.length > 1 ? "las guías: " : "la guía: "
            }`}{" "}
          <span className="text-indigo-600">{shipmentNumbers.join(", ")}</span>
        </p>
      </div>

      <div className="flex justify-center items-center mb-4">
        <div className="bg-gray-100 p-4 rounded border border-gray-300 text-center">
          <p className="text-sm">
            Declaro que he revisado el contenido íntegro de la encomiendo y
            libero a TEALCA de cualquier reclamo respecto de la misma.
          </p>
        </div>
      </div>

      <div className="mb-6">
        <FormTextInput
          label="Persona que recibe"
          isRequiredLabel
          name="fullName"
          type="text"
          value={name}
          error={nameError}
          maxLength={150}
          onChange={(e) => {
            const value = e.target.value;
            if (/^[a-zA-Z\s]*$/.test(value)) {
              setName(value);
            }
          }}
        />
      </div>
      <div className="mb-6">
        <FormTextInput
          label="Cédula de Identidad"
          isRequiredLabel
          name="identityCard"
          type="text"
          value={id}
          error={idError}
          maxLength={20}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*$/.test(value)) {
              setId(value);
            }
          }}
        />
      </div>

      {attachments.map((attachment, index) => (
        <div key={index} className="relative mb-4">
          {index > 0 && (
            <button
              className="absolute top-0 right-0 bg-transparent text-gray-600 rounded-full w-6 h-6 flex items-center justify-center"
              onClick={() => {
                const updatedAttachments = [...attachments];
                const updatedModes = [...attachmentModes];
                updatedAttachments.splice(index, 1);
                updatedModes.splice(index, 1);
                setAttachments(updatedAttachments);
                setAttachmentModes(updatedModes);
              }}
            >
              <XMarkIcon className="w-5 h-5" />
            </button>
          )}
          <div className="border border-gray-300 shadow-md rounded-xl p-4 mb-4">
            <FormSimpleRadioGroup
              horizontal
              className="mb-4"
              name={`liabilityWaiver-${index}`}
              selected={attachmentModes[index]}
              options={[
                { value: "capture", name: "Tomar foto" },
                { value: "upload", name: "Cargar Foto" },
              ]}
              onSelectOption={(option) =>
                handleAttachmentModeChange(index, option)
              }
              disabled={loading}
            />
            {attachmentModes[index] === "capture" ? (
              <WebcamCapture
                photo={attachment}
                setPhoto={(photo) => {
                  const updatedAttachments = [...attachments];
                  updatedAttachments[index] = photo!;
                  setAttachments(updatedAttachments);
                }}
                setCheck={setCheckboxChecked}
              />
            ) : (
              <FormFileUpload
                error={error}
                description="El archivo debe ser una imagen"
                onSelectFile={(file) => handleFileChange(file, index)}
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
              />
            )}
          </div>
        </div>
      ))}
      {attachments.length < 5 && !attachments.includes("") && (
        <div className="flex justify-center mb-8">
          <button
            onClick={addAttachmentSlot}
            className="flex flex-col items-center justify-center w-full border border-dashed border-gray-300 rounded-lg p-4 bg-gray-50 hover:bg-gray-100 transition"
          >
            <div className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full">
              <PlusIcon className="w-6 h-6 text-gray-600" />
            </div>
            <span className="mt-2 text-sm font-medium text-gray-600">
              Agregar otro adjunto
            </span>
          </button>
        </div>
      )}

      <div className="border border-gray-300 shadow-md rounded-xl p-4 mb-4">
        <FingerprintReader
          fingerprint={fingerprint}
          setFingerprint={setFingerprint}
          setTemplate={setFingerprintTemplate}
          setCheck={setCheckboxChecked}
        />
      </div>
      {!isDigitalPODRequired && (
        <div className="flex items-center my-6">
          <input
            type="checkbox"
            id="confirmationCheckbox"
            checked={checkboxChecked}
            onChange={(e) => onChangeCheckbox(e.target.checked)}
            className="mr-2"
          />
          <label
            htmlFor="confirmationCheckbox"
            className="text-sm font-medium text-gray-900"
          >
            La huella y firma se registrarán en el documento físico
          </label>
        </div>
      )}

      {checkboxChecked && liabilityShipments.length > 0 && (
        <>
          <LinkText
            text="Imprimir documento para POD"
            onClick={() => onDownloadPOD()}
          />
          <br />
          <br />
        </>
      )}

      <FormTextAreaInput
        rows={4}
        maxLength={250}
        id="observations"
        name="observations"
        label="Observaciones"
        className="resize-none"
        onChange={(e) => setObservation(e.target.value)}
        value={observation}
      />

      <hr className="my-4" />

      <div className="flex w-full items-center justify-between">
        <SecondaryButton className="w-32" onClick={() => setOpenModal(false)}>
          Cancelar
        </SecondaryButton>

        <PrimaryButton
          disabled={!canSubmit()}
          className="w-32"
          onClick={handleSubmit}
        >
          Entregar
        </PrimaryButton>
      </div>
      <WarningModal
        openModal={openModalWarning}
        setOpenModal={setOpenModalWarning}
        message="¿Está seguro que registrará el POD en el documento físico? Esta acción eliminará las imágenes adjuntas y/o huella cargada."
        onPrimaryButton={() => {
          setAttachments([""]);
          setFingerprint(undefined);
          setCheckboxChecked(true);
          setOpenModalWarning(false);
        }}
      />
    </Modal>
  );
};
