import React, { useMemo, useState, FC, useCallback, useEffect } from "react";
import * as Yup from "yup";
import Modal from "../Modal";
import classNames from "classnames";
import PaymentChange from "./PaymentChange";
import { Formik, FormikProps } from "formik";
import { useAppSelector } from "../../store/hooks";
import voucherAPR from "../../assets/Voucher_APR.png";
import ProofOfPaymentModal from "./ProofOfPaymentModal";
import parsePhoneNumberFromString from "libphonenumber-js";
import IncidenceTicketModal from "../IncidenceTicketModal";
import {
  loaderService,
  getVtid,
  NetworkMonitor,
  getAvailableChange,
} from "../../services";
import { UserInterface } from "../../interfaces/UserInterface";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { LinkText, PrimaryButton, SecondaryButton } from "../Buttons";
import PaymentForm, { PaymentDateEnum, PaymentFormValues } from "./PaymentForm";
import {
  Currency,
  formatFloat,
  currencyExchange,
  currencyExchangeText,
  useCurrencyExchanges,
} from "../../utils";
import PaymentConfirmModal, {
  PaymentConfirmModalStatus,
} from "./PaymentConfirmModal";
import RetentionsForm, {
  PercentageInterface,
  RetentionFormValues,
} from "./RetentionForm";
import {
  Banco,
  Nullable,
  ItemInterface,
  PaymentInterface,
  AccountInterface,
  DocumentInterface,
  PaymentMethodEnum,
  PaymentMethodInterface,
  PaymentStatusEnum,
  DocumentType,
  DocumentStatus,
  BankAccountInterface,
  PaymentMode,
} from "../../interfaces";
import { NetworkLog } from "../../interfaces/ReportInterface";
import PaymentItem from "./PaymentItem";
import { FormTextInput } from "../FormFields";

const defaultPercentages: PercentageInterface[] = [
  {
    id: 14,
    name: "Retención de IVA",
    value: "iva-retention",
    percentage: 0.16,
    initialPercentage: 0.16,
  },
  {
    id: 15,
    name: "Retención de ISLR",
    value: "islr-retention",
    percentage: 0.05,
    initialPercentage: 0.03,
  },
];

enum PaymentType {
  PAY,
  RETENTION,
  CHANGE,
}

interface OnPayResponse {
  error: boolean;
  message: string;
  currencyId?: number;
  payment?: PaymentInterface;
  savedDocument?: DocumentInterface;
}

export interface PaymentListProps {
  items?: ItemInterface[];
  document?: DocumentInterface;
  payments: PaymentInterface[];
  remaining: number;
  paymentMode?: PaymentMode;
  owner?: AccountInterface;
  editable?: boolean;
  detailView?: boolean;
  onPay?: (
    payment: PaymentInterface,
    avoidVerification?: boolean
  ) => Promise<OnPayResponse>;
  onCancelRetention?: (retention: PaymentInterface) => Promise<void>;
  onCancelPayment?: (payment: PaymentInterface) => Promise<void>;
  onApproveReintegration?: (
    reintegration: PaymentInterface,
    bankAccount: BankAccountInterface
  ) => void;
  onSaveProofOfPayment?: (
    file: File,
    retention: PaymentInterface,
    user: UserInterface,
    statusID: number,
    reference?: string,
    isModification?: boolean
  ) => void;
  onDeleteProofOfPayment?: (retention: PaymentInterface) => Promise<void>;
}

const PaymentList: FC<PaymentListProps> = ({
  items,
  document,
  payments,
  remaining,
  paymentMode,
  owner,
  editable = false,
  detailView = false,
  onPay = () =>
    Promise.resolve({
      error: true,
      message: "",
      payment: undefined,
      savedDocument: undefined,
      currencyId: undefined,
    }),
  onCancelRetention = () => {},
  onCancelPayment = () => {},
  onApproveReintegration = () => {},
  onSaveProofOfPayment = () => {},
  onDeleteProofOfPayment = () => {},
}) => {
  const exchanges = useCurrencyExchanges();
  const user = useAppSelector((state) => state.user);

  const [apr, setApr] = useState("");
  const [seq, setSeq] = useState("");
  const [seqError, setSeqError] = useState("");
  const [vtid, setVtid] = useState<string>();
  const [aprError, setAprError] = useState("");
  const [error, setError] = useState<string>();
  const loading = loaderService.useIsLoading();
  const [errorCount, setErrorCount] = useState(0);
  const [logs, setLogs] = useState<NetworkLog[]>([]);
  const [payment, setPayment] = useState<PaymentInterface>();
  const [openPayModal, setOpenPayModal] = useState(false);
  const [openRetModal, setOpenRetModal] = useState(false);
  const [openProofModal, setOpenProofModal] = useState(false);
  const [openChangeModal, setOpenChangeModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [retention, setRetention] = useState<PaymentInterface>();
  const [openAddRejectedPayment, setOpenAddRejectedPayment] = useState(false);
  const [status, setStatus] = useState(PaymentConfirmModalStatus.CONFIRM);
  const [showRetentionMethods, setShowRetentionMethods] = useState(false);
  const [isModification, setIsModification] = useState(false);
  const [openIncidenceModal, setOpenIncidenceModal] = useState(false);
  const [openRegisterMerchantPayment, setOpenRegisterMerchantPayment] =
    useState(false);
  const [openDeletePOPModal, setOpenDeletePOPModal] = useState(false);
  const [paymentConfirmationModalClose, setPaymentConfirmationModalClose] =
    useState(false);
  const [availableChange, setAvailableChange] = useState(0.01);
  const payMethods = useAppSelector((state) => state.user.paymentMethods ?? []);
  const businessUnit = useAppSelector((state) => state.user.businessUnit);
  const [retentionToCancel, setRetentionToCancel] =
    useState<PaymentInterface>();
  const [retentionToDeletePOP, setRetentionToDeletePOP] =
    useState<PaymentInterface>();
  const initialRetentionMethods = useAppSelector(
    (state) => state.user.retentionMethods ?? []
  );

  const canAddRetentions = useMemo(() => {
    return (
      !!owner?.abreviationName &&
      owner.abreviationName !== "P-" &&
      ((owner.abreviationName !== "V-" && owner.abreviationName !== "E-") ||
        owner.identificationNumber.length === 9)
    );
  }, [owner]);

  const paymentsSum = useMemo(() => {
    return +payments
      .filter(
        (p) =>
          p.status !== PaymentStatusEnum.CANCELED &&
          p.status !== PaymentStatusEnum.REJECT
      )
      .reduce((acc, t) => acc + t.amount, 0)
      .toFixed(2);
  }, [payments]);

  const absoluteTotal = useMemo(() => {
    if (!items) return document?.total ?? 0;
    return items
      .filter((item) => !item.rate.isPercentage)
      .reduce((acc, item) => acc + item.rate.value, 0);
  }, [items, document?.total]);

  const subTotal = useMemo(() => {
    if (!items) return document?.subTotal ?? 0;
    const percentageTotal = items
      .filter((item) => item.rate.isPercentage)
      .reduce((acc, item) => acc + item.rate.value, 0);

    return absoluteTotal * (1 + percentageTotal / 100);
  }, [items, absoluteTotal, document?.subTotal]);

  const ivaValue = useMemo(() => {
    if (!items)
      return document?.taxes?.find((t) => t.name === "IVA")?.value ?? 0;
    return +items.reduce((acc, item) => acc + item.rate.iva, 0).toFixed(2);
  }, [items, document?.taxes]);

  const filteredPayMethods = useMemo(() => {
    const basePayMethods =
      user.user?.roleName === "Superadministrador"
        ? [
            {
              paymentMethodID: PaymentMethodEnum.SUPERADMIN_MERCHANT_P2C,
              paymentMethodName: "Merchant P2C",
              currencyID: 1,
              bankID: 4,
              bankAccountID: 1,
              bankAccounts: [],
            },
            {
              paymentMethodID:
                PaymentMethodEnum.SUPERADMIN_MERCHANT_TARJETA_DE_DEBITO,
              paymentMethodName: "Merchant Punto de venta",
              currencyID: 1,
              bankID: 27,
              bankAccountID: 0,
              bankAccounts: [],
            },
          ]
        : [
            {
              paymentMethodID: PaymentMethodEnum.MERCHANT,
              paymentMethodName: "Merchant (Punto de venta)",
              currencyID: 1,
              bankID: 27,
              bankAccountID: 0,
              bankAccounts: [],
            },
          ];

    return [
      ...basePayMethods,
      ...payMethods.filter(
        (p) =>
          ![
            PaymentMethodEnum.PAGO_MERCHANT_TARJETA_DE_DEBITO,
            PaymentMethodEnum.PAGO_MERCHANT_TARJETAS_DE_CREDITO,
            PaymentMethodEnum.PAGO_MERCHANT_P2C,
            PaymentMethodEnum.NOTA_DE_CREDITO,
            // PaymentMethodEnum.AJUSTE_DECIMAL,
          ].includes(p.paymentMethodID) &&
          (document?.documentType !== DocumentType.INVOICE ||
            !!document?.urlDocument ||
            p.paymentMethodID !== PaymentMethodEnum.EFECTIVO ||
            p.currencyID === 1) &&
          ((paymentMode !== PaymentMode.BOXOFFICE_CREDIT &&
            paymentMode !== PaymentMode.DEST_BOXOFFICE_CREDIT) ||
            p.paymentMethodID !== PaymentMethodEnum.EFECTIVO)
      ),
    ];
  }, [
    payMethods,
    document?.documentType,
    paymentMode,
    document?.urlDocument,
    user.user?.roleName,
  ]);

  const orderedPayments = useMemo(() => {
    return [...payments].sort((a, b) => {
      // sort by date
      if (!a.paymentDate || !b.paymentDate) return 0;
      return (
        new Date(a.paymentDate).getTime() - new Date(b.paymentDate).getTime()
      );
    });
  }, [payments]);

  const validPayments = useMemo(() => {
    return orderedPayments.filter(
      (p) =>
        p.status !== PaymentStatusEnum.CANCELED &&
        p.status !== PaymentStatusEnum.REJECT
    );
  }, [orderedPayments]);

  const notValidPayments = useMemo(() => {
    return orderedPayments.filter(
      (p) =>
        p.status === PaymentStatusEnum.CANCELED ||
        p.status === PaymentStatusEnum.REJECT
    );
  }, [orderedPayments]);

  const selectPayment = (
    payment: PaymentInterface,
    currencyFrom: Currency,
    type: PaymentType = PaymentType.PAY
  ) => {
    setPayment({
      ...payment,
      amount: currencyExchange(payment.amount, exchanges, "BS", currencyFrom),
      paymentAmount: +payment.amount,
    });
    setStatus(PaymentConfirmModalStatus.CONFIRM);

    if (type === PaymentType.RETENTION) {
      setOpenRetModal(true);
    } else if (type === PaymentType.PAY) {
      setOpenPayModal(true);
    } else {
      setOpenChangeModal(true);
    }
  };

  const handlePayAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    formik: FormikProps<RetentionFormValues | PaymentFormValues>,
    max: number
  ) => {
    const setValue = (value: string) => {
      e.target.value = value;
    };

    const realMax = [
      PaymentMethodEnum.MERCHANT,
      PaymentMethodEnum.PAGO_MERCHANT_P2C,
      PaymentMethodEnum.PAGO_MERCHANT_TARJETA_DE_DEBITO,
    ].includes(formik.values.paymentMethod.paymentMethodID)
      ? max
      : undefined;
    // const realMax = undefined;

    if (!formatFloat(e.target.value, setValue, 0, realMax)) {
      return;
    }

    formik.setFieldValue("amount", e.target.value);
  };

  const pay = useCallback(async () => {
    if (!payment) return;

    setStatus(PaymentConfirmModalStatus.PROCESSING);

    // The amount to which the taxes will be applied will be equal to the
    // minimum between the amount to be paid and the rest less the previous
    // calculation
    const currentIGTF = document?.igtfAmount ?? 0;
    let igtfAmount = 0;
    let igtfPercentage = 0;
    if (
      payment.paymentMethod.paymentMethodID === PaymentMethodEnum.EFECTIVO &&
      payment.paymentMethod.currencyID === 2
    ) {
      igtfAmount = Math.max(
        0,
        Math.min(payment.amount, remaining - currentIGTF)
      );
      igtfAmount = +(igtfAmount * 0.03).toFixed(2);
      igtfPercentage = igtfAmount / payment.amount;
    }

    const result = await onPay({
      ...payment,
      igtfAmount,
      igtfPercentage,
    });

    if (!result.error) {
      setError("");
      setStatus(PaymentConfirmModalStatus.SUCCESS);
    } else {
      setError(result.message);
      setStatus(PaymentConfirmModalStatus.ERROR);
    }

    if (
      result.error &&
      (payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_P2C ||
        payment.paymentMethod.paymentMethodID ===
          PaymentMethodEnum.TRANSFERENCIA ||
        payment.paymentMethod.paymentMethodID ===
          PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA)
    ) {
      setErrorCount((c) => c + 1);
    } else {
      setErrorCount(0);
    }

    if (result.payment) setPayment(result.payment);
  }, [document?.igtfAmount, onPay, payment, remaining]);

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required("El campo es obligatorio")
      .when("paymentMethod", {
        is: (method: PaymentMethodInterface) =>
          method.paymentMethodID === PaymentMethodEnum.RETENCION_DE_IVA,
        then: () =>
          Yup.number().test(
            "amount-validation",
            "El delta no debe ser mayor a 1",
            function (value) {
              const v = value ?? 0;
              const min = +(ivaValue * 0.75 - 1.01).toFixed(2);
              const max = +(ivaValue + 1.01).toFixed(2);
              if (v < min || v > max) {
                return this.createError({
                  message: `El monto debe estar entre ${min} y ${max}`,
                });
              }

              return true;
            }
          ),
      })
      .when("paymentMethod", {
        is: (method: PaymentMethodInterface) =>
          method.paymentMethodID === PaymentMethodEnum.RETENCION_DE_ISRL,
        then: () =>
          Yup.number().test(
            "amount-validation",
            `El monto no puede superar el ${
              defaultPercentages.find((p) => p.id === 15)!.percentage * 100
            }% (${currencyExchangeText(
              subTotal *
                defaultPercentages.find((p) => p.id === 15)!.percentage,
              exchanges,
              "BS"
            )}) del total`,
            (value) => {
              return (
                (value ?? 0) <
                subTotal *
                  defaultPercentages.find((p) => p.id === 15)!.percentage +
                  0.01
              );
            }
          ),
      })
      .when(
        ["paymentMethod", "retentionPercent"],
        {
          is: (method: PaymentMethodInterface) =>
            method.paymentMethodID ===
            PaymentMethodEnum.RETENCION_DE_IMPUESTO_MUNICIPAL,
          then: () =>
            Yup.number().test(
              "amount-validation",
              "El delta no debe ser mayor a 1",
              function (value) {
                const { retentionPercent } = this.parent;
                const ratio = Math.abs(
                  (value ?? 0) - (subTotal * retentionPercent) / 100
                );
                if (ratio >= 1.01) {
                  const realValue = (subTotal * retentionPercent) / 100;
                  const min = (realValue - 1).toFixed(2);
                  const max = (realValue + 1).toFixed(2);
                  return this.createError({
                    message: `El monto debe estar entre ${min} y ${max}`,
                  });
                }

                return true;
              }
            ),
        }
        //[["paymentMethod", "retentionPercent"]]
      ),
    retentionPercent: Yup.string().when("paymentMethod", {
      is: (method: PaymentMethodInterface) =>
        method.paymentMethodID ===
        PaymentMethodEnum.RETENCION_DE_IMPUESTO_MUNICIPAL,
      then: () =>
        Yup.number()
          .required("El campo es obligatorio")
          .max(5, "Se permite máximo un 5%"),
      otherwise: () => Yup.string().notRequired(),
    }),
  });

  // Payment validations
  const paymentValidationSchema = Yup.object().shape({
    phone: Yup.string().when("paymentMethod", {
      is: (method: PaymentMethodInterface) =>
        method.paymentMethodID === PaymentMethodEnum.PAGO_MOVIL_C2P ||
        method.paymentMethodID === PaymentMethodEnum.PAGO_MOVIL_P2C,
      then: () =>
        Yup.string()
          .required("El campo es obligatorio")
          .test(
            "is-valid-phone-number",
            "El número de teléfono no es válido",
            (phone) => {
              const phoneNumber = parsePhoneNumberFromString(phone, "VE");
              return phoneNumber
                ? phoneNumber.isValid() && phone[1] !== "2"
                : false;
            }
          ),
    }),
    clientIdentifier: Yup.string().when("paymentMethod", {
      is: (method: PaymentMethodInterface) =>
        method.paymentMethodID !== PaymentMethodEnum.EFECTIVO,
      then: () => Yup.string().required("El campo es obligatorio"),
    }),
    reference: Yup.string().when("paymentMethod", {
      is: (method: PaymentMethodInterface) =>
        method.paymentMethodID === PaymentMethodEnum.PAGO_MOVIL_P2C ||
        method.paymentMethodID ===
          PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA ||
        method.paymentMethodID === PaymentMethodEnum.TRANSFERENCIA,
      then: () =>
        Yup.string()
          .required("El campo es obligatorio")
          .matches(
            /^[0-9-]{4,}$/,
            "El número debe tener 4 o más dígitos únicamente"
          ),
    }),
    otp: Yup.string().when("paymentMethod", {
      is: (method: PaymentMethodInterface) =>
        method.paymentMethodID === PaymentMethodEnum.PAGO_MOVIL_C2P,
      then: () => Yup.string().required("El campo es obligatorio"),
    }),
    bank: Yup.object().when("paymentMethod", {
      is: (method: PaymentMethodInterface) =>
        method.paymentMethodID === PaymentMethodEnum.PAGO_MOVIL_C2P,
      then: () =>
        Yup.object().test(
          "bank-validation",
          "El campo es obligatorio",
          (value) => Object.keys(value).length !== 0
        ),
    }),
    affiliateNumber: Yup.string().when("paymentMethod", {
      is: (method: PaymentMethodInterface) =>
        method.paymentMethodID === PaymentMethodEnum.PUNTO_DE_VENTA,
      then: () =>
        Yup.string()
          .required("El campo es obligatorio")
          .matches(
            /^[0-9]{8,10}$/,
            "El número de afiliado de tener entre 8 y 10 dígitos"
          ),
    }),
    batchNumber: Yup.string().when("paymentMethod", {
      is: (method: PaymentMethodInterface) =>
        method.paymentMethodID === PaymentMethodEnum.PUNTO_DE_VENTA,
      then: () =>
        Yup.string()
          .required("El campo es obligatorio")
          .matches(
            /^[0-9]{3,5}$/,
            "El número de lote de tener entre 3 y 5 dígitos"
          ),
    }),
    destBankID: Yup.number()
      .nullable()
      .when("paymentMethod", {
        is: (method: PaymentMethodInterface) =>
          [
            PaymentMethodEnum.PAGO_MOVIL_C2P,
            PaymentMethodEnum.PAGO_MOVIL_P2C,
            PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA,
          ].includes(method.paymentMethodID),
        then: () => Yup.number().required("El campo es obligatorio"),
      }),
  });

  // Change validations
  const changeValidationSchema = Yup.object().shape({
    clientIdentifier: Yup.string().when("paymentMethod", {
      is: (method: PaymentMethodInterface) =>
        method.paymentMethodID === PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO,
      then: () => Yup.string().required("El campo es obligatorio"),
    }),
    phone: Yup.string().when("paymentMethod", {
      is: (method: PaymentMethodInterface) =>
        method.paymentMethodID === PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO,
      then: () =>
        Yup.string()
          .required("El campo es obligatorio")
          .test(
            "is-valid-phone-number",
            "El número de teléfono no es válido",
            (phone) => {
              const phoneNumber = parsePhoneNumberFromString(phone, "VE");
              return phoneNumber
                ? phoneNumber.isValid() && phone[1] !== "2"
                : false;
            }
          ),
    }),

    bank: Yup.object().when("paymentMethod", {
      is: (method: PaymentMethodInterface) =>
        method.paymentMethodID === PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO,
      then: () =>
        Yup.object().test(
          "bank-validation",
          "El campo es obligatorio",
          (value) => Object.keys(value).length !== 0
        ),
    }),
    destBankID: Yup.object().when("paymentMethod", {
      is: (method: PaymentMethodInterface) =>
        method.paymentMethodID === PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO,
      then: () => Yup.string().required("El campo es obligatorio"),
    }),
  });

  // Get vtid
  useEffect(() => {
    const buCode = businessUnit?.code;
    if (!buCode) return;

    const saveVtid = async () => {
      const buConfigResponse = await getVtid(buCode);
      if (buConfigResponse.didError) return;

      const vtid = buConfigResponse.model?.vtid;
      if (!vtid) return;
      setVtid(vtid);
    };

    saveVtid();
  }, [businessUnit?.code]);

  useEffect(() => {
    const traffic = NetworkMonitor(setLogs, logs);
    return traffic;
  }, [payment, logs]);

  useEffect(() => {
    const buCode = user.businessUnit?.code;
    if (!buCode) return;

    // Get available change
    const getChange = async (buCode: string) => {
      const response = await getAvailableChange(buCode);
      if (!response.didError) {
        setAvailableChange(response.model ?? 0);
      }
    };

    getChange(buCode);
  }, [user.businessUnit?.code]);

  return (
    <div className="flex flex-col gap-4 bg-white rounded-lg border px-8 pb-6 pt-4">
      {payments.length > 0 ? (
        <div>
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="text-left px-4 py-2 font-bold text-gray-700 text-xs">
                  MÉTODO DE PAGO
                </th>
                <th className="text-left px-4 py-2 font-bold text-gray-700 text-xs">
                  COMPROBANTE
                </th>
                <th className="text-left px-4 py-2 font-bold text-gray-700 text-xs">
                  REFERENCIA
                </th>
                <th className="text-left px-4 py-2 font-bold text-gray-700 text-xs">
                  ESTADO
                </th>
                <th className="text-right px-4 py-2 font-bold text-gray-700 text-xs">
                  MONTO
                </th>
              </tr>
            </thead>
            <tbody>
              {validPayments.map((transaction, i) => (
                <PaymentItem
                  key={i}
                  index={i}
                  vtid={vtid}
                  document={document}
                  transaction={transaction}
                  last={i === validPayments.length - 1}
                  onCancelPayment={onCancelPayment}
                  onDeletePayment={() => {
                    setRetentionToCancel(transaction);
                    setOpenCancelModal(true);
                  }}
                  onAddProof={() => {
                    setRetention(transaction);
                    setOpenProofModal(true);
                  }}
                  onChangeProof={() => {
                    setRetention(transaction);
                    setOpenProofModal(true);
                    setIsModification(true);
                  }}
                  onDeleteProof={() => {
                    setRetentionToDeletePOP(transaction);
                    setOpenDeletePOPModal(true);
                  }}
                  onApproveReintegration={onApproveReintegration}
                />
              ))}

              <tr className={`${payments.length % 2 === 0 && "bg-gray-100"}`}>
                <td className="text-sm px-4 py-4 font-bold truncate">TOTAL</td>
                <td />
                <td />
                <td />
                <td className="text-sm text-right px-4 py-4 font-semibold w-auto text-gray-600 truncate">
                  <div className="flex flex-col items">
                    <p className="text-sm  font-semibold text-gray-600 truncate">
                      {currencyExchangeText(paymentsSum, exchanges, "USD")}
                    </p>
                    <p className="text-xs text-gray-400 ">
                      {currencyExchangeText(paymentsSum, exchanges, "BS")}
                    </p>
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  className={classNames(
                    "colspan-5 p-3 pt-6 font-semibold text-gray-800 text-sm",
                    notValidPayments.length === 0 && "hidden"
                  )}
                >
                  Pagos rechazados y anulados
                </td>
              </tr>

              {notValidPayments.map((transaction, i) => (
                <PaymentItem
                  key={i + validPayments.length}
                  index={i}
                  vtid={vtid}
                  document={document}
                  transaction={transaction}
                  onCancelPayment={onCancelPayment}
                  onDeletePayment={() => {
                    setRetentionToCancel(transaction);
                    setOpenCancelModal(true);
                  }}
                  onAddProof={() => {
                    setRetention(transaction);
                    setOpenProofModal(true);
                  }}
                  onChangeProof={() => {
                    setRetention(transaction);
                    setOpenProofModal(true);
                    setIsModification(true);
                  }}
                  onDeleteProof={() => {
                    setRetentionToDeletePOP(transaction);
                    setOpenDeletePOPModal(true);
                  }}
                  onApproveReintegration={onApproveReintegration}
                />
              ))}
            </tbody>
          </table>

          {!editable && (
            <>
              <div
                className={classNames(
                  "pb-2 pt-2",
                  user.user?.roleName !== "Superadministrador" &&
                    !canAddRetentions &&
                    "hidden"
                )}
              >
                <div hidden={user.user?.roleName === "Consulta Innovus"}>
                  <LinkText
                    className="pl-3"
                    disabled={
                      (document?.status === DocumentStatus.PAID ||
                        document?.status === DocumentStatus.ANULLED) &&
                      user.user?.roleName !== "Superadministrador"
                    }
                    onClick={() => setShowRetentionMethods((show) => !show)}
                    text={
                      showRetentionMethods
                        ? "Ocultar retenciones"
                        : "Agregar retenciones"
                    }
                  />
                </div>
              </div>
              <div className="pb-6 pt-2">
                <LinkText
                  className="pl-3"
                  text="Reportar un problema"
                  onClick={() => setOpenIncidenceModal(true)}
                />
              </div>
              <IncidenceTicketModal
                logs={logs}
                document={document!}
                openModal={openIncidenceModal}
                setOpenModal={setOpenIncidenceModal}
              />
              {/* Show tax withholdings */}
              {showRetentionMethods && (
                <Formik
                  initialValues={{
                    amount: 0,
                    retentionPercent: 0,
                    currency: "BS" as Currency,
                    paymentMethod: initialRetentionMethods[0],
                  }}
                  onSubmit={(values) => {
                    const payment: PaymentInterface = {
                      status: PaymentStatusEnum.PENDING,
                      isRetention: true,
                      amount: values.amount,
                      paymentMethod: values.paymentMethod,
                      igtfAmount: 0,
                      igtfPercentage: 0,
                    };
                    selectPayment(
                      payment,
                      values.currency,
                      PaymentType.RETENTION
                    );
                  }}
                  validationSchema={validationSchema}
                >
                  {(formik) => {
                    return (
                      <>
                        <RetentionsForm
                          iva={ivaValue}
                          subTotal={subTotal}
                          key="retention-methods"
                          formik={formik}
                          remaining={remaining}
                          payments={payments}
                          defaultPercentages={defaultPercentages}
                          handlePayAmountChange={handlePayAmountChange}
                        />
                        {!!payment && (
                          <PaymentConfirmModal
                            key="ret-modal"
                            payment={payment}
                            status={status}
                            remaining={remaining}
                            currentIGTF={document?.igtfAmount ?? 0}
                            error={error}
                            isRetention={true}
                            openModal={openRetModal}
                            pay={pay}
                            setOpenModal={setOpenRetModal}
                          />
                        )}
                      </>
                    );
                  }}
                </Formik>
              )}
            </>
          )}
        </div>
      ) : (
        <p className="text-gray-400">No se han agregado pagos</p>
      )}

      {/* Give change to customer */}
      {remaining < 0 && (
        <Formik
          initialValues={{
            amount: 0,
            clientIdentifier: "",
            paymentMethod: payMethods[0],
            bank: {} as Banco,
            phone: "",
            reference: "",
            currency: "BS" as Currency,
            destBankID: null as Nullable<number>,
          }}
          onSubmit={(values) => {
            const payment: PaymentInterface = {
              status: PaymentStatusEnum.APPROVE,
              isRetention: false,
              amount: values.amount,
              clientIdentifier: values.clientIdentifier,
              paymentMethod: values.paymentMethod,
              bank: values.bank.codigo ?? "",
              destBankID: values.destBankID ?? undefined,
              phone: values.phone,
              reference: values.reference,
              igtfAmount: 0,
              igtfPercentage: 0,
            };

            selectPayment(payment, values.currency, PaymentType.CHANGE);
          }}
          validationSchema={changeValidationSchema}
        >
          {(formik) => {
            return (
              <>
                <PaymentChange
                  onSubmit={(payment: PaymentInterface) => {
                    selectPayment(payment, "BS", PaymentType.CHANGE);
                  }}
                  formik={formik}
                  remaining={remaining}
                  owner={owner!}
                  payments={payments}
                  detailView={detailView}
                  availableChange={availableChange}
                  paymentConfirmationModalClose={paymentConfirmationModalClose}
                  setPaymentConfirmationModalClose={
                    setPaymentConfirmationModalClose
                  }
                />
                {!!payment && (
                  <PaymentConfirmModal
                    key="pay-modal"
                    payment={{
                      ...payment,
                      amount: Math.abs(payment.amount),
                    }}
                    status={status}
                    remaining={remaining}
                    currentIGTF={document?.igtfAmount ?? 0}
                    error={error}
                    isRetention={false}
                    openModal={openChangeModal}
                    pay={pay}
                    setOpenModal={setOpenChangeModal}
                  />
                )}
              </>
            );
          }}
        </Formik>
      )}

      {editable && (
        <>
          <Formik
            initialValues={
              {
                amount: 0,
                clientIdentifier: "",
                paymentMethod: filteredPayMethods[0],
                bank: {} as Banco,
                phone: "",
                reference: "",
                otp: "",
                currency: "BS" as Currency,
                dateOption: PaymentDateEnum.LAST_4_DAYS,
                date: undefined,
                destBankID: null as Nullable<number>,
                affiliateNumber: "",
                batchNumber: "",
                status: PaymentStatusEnum.APPROVE,
              } as PaymentFormValues
            }
            onSubmit={(values) => {
              const payment: PaymentInterface = {
                status: PaymentStatusEnum.APPROVE,
                isRetention: false,
                amount: values.amount,
                clientIdentifier: values.clientIdentifier,
                paymentMethod: values.paymentMethod,
                bank: values.bank.codigo,
                destBankID: values.destBankID ?? undefined,
                phone: values.phone,
                reference: values.reference,
                otp: values.otp,
                igtfAmount: 0,
                igtfPercentage: 0,
                paymentDate:
                  values.dateOption === PaymentDateEnum.SPECIFIC_DATE
                    ? values.date
                    : new Date().toISOString(),
                attempts:
                  values.dateOption === PaymentDateEnum.LAST_4_DAYS
                    ? 4
                    : values.dateOption === PaymentDateEnum.LAST_8_DAYS
                    ? 8
                    : 1,
                affiliateNumber: values.affiliateNumber,
                batchNumber: values.batchNumber,
              };

              selectPayment(payment, values.currency, PaymentType.PAY);
            }}
            validationSchema={paymentValidationSchema}
          >
            {(formik) => {
              return (
                <>
                  <PaymentForm
                    key="pay-methods"
                    formik={formik}
                    owner={owner}
                    error={error}
                    remaining={remaining}
                    payMethods={filteredPayMethods}
                    currentIGTF={document?.igtfAmount ?? 0}
                    setErrorCount={setErrorCount}
                    handlePayAmountChange={handlePayAmountChange}
                  />

                  {!!payment && (
                    <PaymentConfirmModal
                      key="pay-modal"
                      payment={payment}
                      status={status}
                      remaining={remaining}
                      currentIGTF={document?.igtfAmount ?? 0}
                      error={error}
                      isRetention={false}
                      openModal={openPayModal}
                      setPaymentConfirmationModalClose={
                        setPaymentConfirmationModalClose
                      }
                      pay={pay}
                      setOpenModal={setOpenPayModal}
                      onError={() => {
                        if (errorCount > 1) {
                          setOpenAddRejectedPayment(true);
                        }
                      }}
                      onRegisterMerchantPayment={() => {
                        setOpenRegisterMerchantPayment(true);
                        setOpenPayModal(false);
                      }}
                    />
                  )}
                </>
              );
            }}
          </Formik>

          <div
            className={classNames(
              "justify-items-start w-fit",
              !canAddRetentions && "hidden"
            )}
          >
            <LinkText
              className="flex"
              onClick={() => setShowRetentionMethods((show) => !show)}
              disabled={
                (document?.status === DocumentStatus.PAID ||
                  document?.status === DocumentStatus.ANULLED) &&
                user.user?.roleName !== "Superadministrador"
              }
              text={
                showRetentionMethods
                  ? "Ocultar retenciones"
                  : "Agregar retenciones"
              }
            />
          </div>

          <div className="flex">
            <LinkText
              className="flex"
              onClick={() => {
                setOpenIncidenceModal(true);
              }}
              text="Reportar un problema"
            />
          </div>

          {/* Show tax withholdings */}
          {showRetentionMethods && (
            <Formik
              enableReinitialize
              initialValues={{
                amount: 0,
                retentionPercent: 0,
                currency: "BS" as Currency,
                paymentMethod: initialRetentionMethods[0],
              }}
              onSubmit={(values) => {
                const payment: PaymentInterface = {
                  status: PaymentStatusEnum.PENDING,
                  isRetention: true,
                  amount: values.amount,
                  paymentMethod: values.paymentMethod,
                  igtfAmount: 0,
                  igtfPercentage: 0,
                };
                selectPayment(payment, values.currency, PaymentType.RETENTION);
              }}
              validationSchema={validationSchema}
            >
              {(formik) => {
                return (
                  <>
                    <RetentionsForm
                      iva={ivaValue}
                      subTotal={subTotal}
                      key="retention-methods"
                      formik={formik}
                      remaining={remaining}
                      payments={payments}
                      defaultPercentages={defaultPercentages}
                      handlePayAmountChange={handlePayAmountChange}
                    />
                    {!!payment && (
                      <PaymentConfirmModal
                        key="ret-modal"
                        payment={payment}
                        status={status}
                        remaining={remaining}
                        currentIGTF={document?.igtfAmount ?? 0}
                        error={error}
                        isRetention={true}
                        openModal={openRetModal}
                        pay={pay}
                        setOpenModal={setOpenRetModal}
                      />
                    )}
                  </>
                );
              }}
            </Formik>
          )}
        </>
      )}

      <ProofOfPaymentModal
        retention={retention}
        openModal={openProofModal}
        setOpenModal={setOpenProofModal}
        onSaveProofOfPayment={onSaveProofOfPayment}
        isModification={isModification}
        setIsModification={setIsModification}
      />

      <IncidenceTicketModal
        logs={logs}
        document={document!}
        openModal={openIncidenceModal}
        setOpenModal={setOpenIncidenceModal}
      />

      <Modal openModal={openCancelModal} setOpenModal={setOpenCancelModal}>
        <div className="flex flex-col items-center justify-center max-w-[20rem]">
          <div className="flex flex-col items-center justify-center w-full">
            <ExclamationTriangleIcon className="w-32 h-32" />
          </div>

          <p className="mt-2 text-center text-gray-900">
            ¿Está seguro que desea anular{" "}
            <span className="font-semibold">
              {retentionToCancel?.paymentMethod.paymentMethodName}
            </span>{" "}
            (
            {currencyExchangeText(
              retentionToCancel?.amount ?? 0,
              exchanges,
              "BS"
            )}
            )?
          </p>

          <div className="mt-4 flex w-full flex-row justify-between gap-4">
            <SecondaryButton
              className="px-4"
              onClick={() => setOpenCancelModal(false)}
            >
              Cancelar
            </SecondaryButton>

            <PrimaryButton
              className="px-4"
              disabled={loading}
              onClick={async () => {
                await onCancelRetention(retentionToCancel!);
                setOpenCancelModal(false);
              }}
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      {/* P2C Register payment*/}
      <Modal
        openModal={openAddRejectedPayment}
        setOpenModal={setOpenAddRejectedPayment}
      >
        <div className="flex flex-col gap-4 max-w-[20rem]">
          <p className="text-xl text-center">
            ¿Desea registrar el pago para conciliación manual en Casa Matriz?
          </p>

          <div className="flex w-full justify-between mt-6 gap-4">
            <SecondaryButton
              onClick={() => setOpenAddRejectedPayment(false)}
              className="w-40"
              type="button"
            >
              Cancelar
            </SecondaryButton>

            <PrimaryButton
              onClick={async () => {
                if (payment) {
                  const pendingPayment = {
                    ...payment,
                    status: PaymentStatusEnum.PENDING,
                  };
                  await onPay(pendingPayment, true);
                }
                setOpenAddRejectedPayment(false);
              }}
              className="w-40"
              type="button"
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      <Modal
        openModal={openDeletePOPModal}
        setOpenModal={setOpenDeletePOPModal}
      >
        <div className="flex flex-col items-center justify-center max-w-[20rem]">
          <div className="flex flex-col items-center justify-center w-full">
            <ExclamationTriangleIcon className="w-32 h-32" />
          </div>

          <p className="mt-2 text-center text-gray-900">
            ¿Está seguro que desea eliminar el comprobante?
          </p>

          <div className="mt-4 flex w-full flex-row justify-between gap-4">
            <SecondaryButton
              className="px-4"
              onClick={() => setOpenDeletePOPModal(false)}
            >
              Cancelar
            </SecondaryButton>

            <PrimaryButton
              className="px-4"
              disabled={loading}
              onClick={async () => {
                await onDeleteProofOfPayment(retentionToDeletePOP!);
                setOpenDeletePOPModal(false);
              }}
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      {/* Merchant Register payment */}
      <Modal
        openModal={openRegisterMerchantPayment}
        setOpenModal={setOpenRegisterMerchantPayment}
      >
        <div className="flex flex-col gap-4 max-w-[25rem]">
          <p className="text-xl text-center">Registrar pago de Merchant</p>

          <div>
            <FormTextInput
              name="apr"
              value={apr}
              error={aprError}
              onChange={(e) => {
                setAprError("");
                setApr(e.target.value);
              }}
              label="Número de aprobación (APR)"
              placeholder="Número de aprobación"
            />
          </div>

          <div>
            <FormTextInput
              name="seq"
              value={seq}
              error={seqError}
              onChange={(e) => {
                setSeqError("");
                setSeq(e.target.value);
              }}
              label="Secuencia"
              placeholder="Numero de secuencia"
            />
          </div>
          <div className="mt-6 w-full">
            <p className="text-sm">
              Puede conseguir el APR y la secuencia usando la siguiente imagen
              como referencia:
            </p>

            <div className="flex w-full items-center justify-center">
              <img
                src={voucherAPR}
                alt="Ejemplo de factura con APR"
                className="max-w-[20rem]"
              />
            </div>
          </div>

          <div className="flex w-full justify-between mt-6 gap-4">
            <SecondaryButton
              onClick={() => setOpenRegisterMerchantPayment(false)}
              className="w-40"
              type="button"
            >
              Cancelar
            </SecondaryButton>

            <PrimaryButton
              onClick={async () => {
                if (payment && apr.length > 5 && seq.length > 0) {
                  const pendingPayment: PaymentInterface = {
                    ...payment,
                    reference: `${seq}:${vtid}:${apr}`,
                    paymentMethod: {
                      ...payment.paymentMethod,
                      paymentMethodID:
                        PaymentMethodEnum.PAGO_MERCHANT_TARJETA_CONCILIACION_MANUAL,
                      paymentMethodName:
                        "Pago Merchant - Tarjeta Conciliación Manual",
                    },
                    status: PaymentStatusEnum.PENDING,
                  };
                  await onPay(pendingPayment, true);
                  setOpenRegisterMerchantPayment(false);
                } else if (apr.length <= 5) {
                  setAprError("El APR debe tener al menos 6 caracteres");
                } else if (seq.length < 1 || Number.isNaN(seq)) {
                  setSeqError("La secuencia debe tener por lo menos una cifra");
                }
              }}
              className="w-40"
              type="button"
            >
              Aceptar
            </PrimaryButton>
          </div>

          <p className="text-xs">
            Nota: Es necesario procesar el cierre de Merchant para que Casa
            Matriz pueda conciliar el pago
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default PaymentList;
