import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import { debounce } from "lodash";
import classNames from "classnames";
import { LinkText, PrimaryButton, SecondaryButton } from "../Buttons";
import LoadingIcon from "../LodingIcon";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useAppSelector } from "../../store/hooks";
import {
  alertService,
  loaderService,
  reserveBUSeller,
  saveAccount,
  updateAccount,
} from "../../services";
import {
  FormCheckbox,
  FormRadioGroup,
  FormSelect,
  FormTextInput,
} from "../FormFields";
import { TaxIdentificationTypeInterface } from "../../interfaces/TaxIdentificationTypeInterface";
import {
  AccountInterface,
  AccountRequestInterface,
  CountryInterface,
} from "../../interfaces";
import {
  validatePhone,
  validateFullname,
  extractFirstLetter,
  validateEmailRegex,
  validateEmailDomain,
  validateEmailDisposable,
  validateIdentityDocument,
} from "../../utils";

// Constants
const VEN_CODE = "VEN";
//const idOptions = ["V", "J", "E", "G", "P"];
export const emptyPhonePrefix = { value: "", name: "FIJO" };
export const phonePrefixOptions = [
  {
    value: "412",
    name: "412",
  },
  {
    value: "424",
    name: "424",
  },
  {
    value: "414",
    name: "414",
  },
  {
    value: "426",
    name: "426",
  },
  {
    value: "416",

    name: "416",
  },
];

export interface AccountFormValues {
  fullName: string;
  email: string;
  address: string;

  // First phone number
  country: CountryInterface;
  phonePrefix: {
    value: string;
    name: string;
  };
  phone: string;

  // Second phone number
  country2?: CountryInterface;
  phonePrefix2?: {
    value: string;
    name: string;
  };
  phone2?: string;
  idType: TaxIdentificationTypeInterface;
  idNumber: string;
  reserved: boolean;
}

interface FormProps {
  formik: FormikProps<AccountFormValues>;
  loader: boolean;
  isUpdate: boolean;
  domainError: string;
  ignoreTouched: boolean;
  showSecondPhone: boolean;
  showFixedPhone: boolean;
  disabledIdDocument?: boolean;
  setShowSecondPhone: (show: boolean) => void;
  disableAllInputs?: boolean;
  setOpenForm: (success: boolean) => void;
}
const Form: FC<FormProps> = ({
  formik,
  loader,
  isUpdate,
  domainError,
  ignoreTouched,
  showSecondPhone,
  showFixedPhone,
  disabledIdDocument,
  setShowSecondPhone,
  disableAllInputs,
  setOpenForm,
}) => {
  const user = useAppSelector((state) => state.user.user);
  const idOptions = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );
  const countries = useAppSelector((state) => state.inmutable.countries);

  // Preselect phone prefix only for Venezuela
  const handleCountrySelection = (
    country: CountryInterface,
    formik: FormikProps<AccountFormValues>,
    secondPhone: boolean = false
  ) => {
    const suffix = secondPhone ? "2" : "";

    formik.setFieldValue(`country${suffix}`, country);
    if (country.countryCodeIso === VEN_CODE) {
      formik.setFieldValue(`phonePrefix${suffix}`, phonePrefixOptions[0]);
    } else {
      formik.setFieldValue(`phonePrefix${suffix}`, "");
    }
  };

  const deleteSecondPhone = () => {
    formik.setValues({
      ...formik.values,
      country2: undefined,
      phonePrefix2: undefined,
      phone2: undefined,
    });
    setShowSecondPhone(false);
  };

  const phoneRealOptions = useMemo(() => {
    if (
      showFixedPhone ||
      formik.values.idType.abreviationName === "J-" ||
      formik.values.idType.abreviationName === "G-"
    ) {
      return [...phonePrefixOptions, emptyPhonePrefix];
    }

    return phonePrefixOptions;
  }, [showFixedPhone, formik.values.idType.abreviationName]);

  const isReservable = useMemo(() => {
    return (
      !isUpdate &&
      (user?.roleName === "Superadministrador" ||
        user?.roleName === "Administrador" ||
        user?.roleName === "Supervisor")
    );
  }, [isUpdate, user]);

  useEffect(() => {
    if (ignoreTouched) {
      // Set all touched
      formik.setTouched({
        fullName: true,
        email: true,
        address: true,
        phone: true,
        country2: true,
        phonePrefix2: true,
        phone2: true,
        idNumber: true,
      });
    }

    // Only the ignoreTouched is required to update the touched values, not
    // the entire formik object
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ignoreTouched]);

  return (
    <form
      className="flex h-full flex-col bg-white group"
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <div className="flex min-h-0 flex-1 flex-col">
        {/* Header */}
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <h3 className="text-lg font-semibold leading-6 text-gray-900">
              {isUpdate ? "Editar cliente" : "Crear cliente"}
            </h3>
          </div>
        </div>

        {/* Body */}
        <div className="relative flex gap-6 flex-1 flex-col px-4 mt-6 mb-6 sm:px-6">
          <div className="flex gap-6 flex-1 flex-col xl:flex-row">
            <div className="flex flex-col gap-3 flex-1">
              {/* Full name */}
              <div className="">
                <FormTextInput
                  label="Nombre completo"
                  isRequiredLabel
                  name="fullName"
                  type="text"
                  value={formik.values.fullName}
                  error={
                    formik.touched.fullName && formik.errors.fullName
                      ? formik.errors.fullName
                      : ""
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={disableAllInputs}
                />
              </div>

              {/* Email */}
              <div className="flex flex-col relative ">
                <FormTextInput
                  label="Correo electrónico"
                  isRequiredLabel
                  name="email"
                  type="email"
                  value={formik.values.email}
                  error={formik.errors.email || domainError}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="pr-10"
                  disabled={disableAllInputs}
                />

                <div
                  className={classNames(
                    "absolute right-0",
                    formik.errors.email || domainError ? "bottom-9" : "bottom-2"
                  )}
                >
                  {!!loader && <LoadingIcon size="1.35rem" />}
                </div>
              </div>

              {/* Address */}
              <div className="">
                <FormTextInput
                  name="address"
                  label="Dirección fiscal"
                  value={formik.values.address}
                  error={
                    formik.touched.address && formik.errors.address
                      ? formik.errors.address
                      : ""
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={disableAllInputs}
                />
              </div>
            </div>

            {/* Document */}
            <div className="flex-1">
              <fieldset className="rounded-md border border-gray-200 p-5">
                <legend className="text-m font-medium leading-6 text-gray-900">
                  Cédula / RIF
                </legend>
                <FormRadioGroup
                  disabled={disabledIdDocument || disableAllInputs || isUpdate}
                  label="Tipo de documento"
                  name="idType"
                  id="radiogroup-id"
                  options={idOptions}
                  selected={formik.values.idType}
                  optionString={(option) =>
                    extractFirstLetter(option.abreviationName)
                  }
                  onSelectOption={(val) => {
                    formik.setFieldValue("idType", val);
                    formik.setFieldValue("idNumber", "");
                  }}
                />
                <div className="mt-6">
                  <FormTextInput
                    label={"Número de cédula / RIF"}
                    isRequiredLabel
                    disabled={disabledIdDocument || disableAllInputs || isUpdate}
                    name="idNumber"
                    value={formik.values.idNumber}
                    error={
                      formik.touched.idNumber && formik.errors.idNumber
                        ? formik.errors.idNumber
                        : ""
                    }
                    onChange={(e) => {
                      let re: RegExp;
                      if (formik.values.idType.abreviationName !== "P-") {
                        // Only numbers
                        re = /^[0-9\b]{0,10}$/;
                      } else {
                        // Only numbers and letters
                        re = /^[a-zA-Z0-9\b]{0,10}$/;
                      }

                      if (e.target.value === "" || re.test(e.target.value)) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </fieldset>
            </div>
          </div>

          <div className="flex flex-1 gap-6 flex-col xl:flex-row">
            {/* Phone number */}
            <div className="flex-1">
              <fieldset className="rounded-md border border-gray-200 p-5">
                <legend className="text-m font-medium leading-6 text-gray-900">
                  Teléfono
                </legend>

                <div className="flex flex-col flex-1 gap-2">
                  <div>
                    <FormSelect
                      label="País"
                      selected={formik.values.country}
                      name="country"
                      onBlur={formik.handleBlur}
                      options={countries}
                      error={
                        formik.touched.country && formik.errors.country
                          ? formik.errors.country
                          : ""
                      }
                      optionString={(country) =>
                        country.countryName +
                        (country.countryCodeIso !== VEN_CODE &&
                        !!country.countryPhoneAccessCode
                          ? ` (${country.countryPhoneAccessCode})`
                          : "")
                      }
                      onSelectOption={(country) =>
                        handleCountrySelection(country, formik)
                      }
                      onSearch={(option, search) => {
                        return (
                          option.countryName
                            .toLowerCase()
                            .split(" ")
                            .some((s) => s.startsWith(search)) ||
                          !!option.countryPhoneAccessCode
                            ?.slice(1)
                            .startsWith(search)
                        );
                      }}
                      disabled={disableAllInputs}
                    />
                  </div>

                  <div>
                    <FormRadioGroup
                      label="Operadora"
                      name="phonePrefix"
                      id="radiogroup-phone"
                      options={phoneRealOptions}
                      selected={formik.values.phonePrefix}
                      optionString={(option) => option.name}
                      onSelectOption={(val) =>
                        formik.setFieldValue("phonePrefix", val)
                      }
                      className={classNames(
                        "p-2",
                        formik.values.country.countryCodeIso === VEN_CODE
                          ? "visible"
                          : "hidden"
                      )}
                      disabled={disableAllInputs}
                    />
                  </div>

                  <div>
                    <FormTextInput
                      label={"Número"}
                      isRequiredLabel
                      name="phone"
                      type="text"
                      value={formik.values.phone}
                      error={
                        formik.touched.phone && formik.errors.phone
                          ? formik.errors.phone
                          : ""
                      }
                      maxLength={
                        formik.values.country.countryCodeIso === VEN_CODE &&
                        !!formik.values.phonePrefix.value
                          ? 7
                          : 10
                      }
                      onChange={(e) => {
                        // Only numbers
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          formik.handleChange(e);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      disabled={disableAllInputs}
                    />
                  </div>
                </div>
              </fieldset>

              {/* Add second phone number */}
              <div
                className={classNames(
                  "flex  justify-end",
                  showSecondPhone && "hidden"
                )}
              >
                <LinkText
                  text="Añadir segundo teléfono"
                  onClick={() => setShowSecondPhone(true)}
                />
              </div>
            </div>

            {/* Second phone number */}
            <div className={classNames("flex-1", !showSecondPhone && "hidden")}>
              <fieldset className="rounded-md border border-gray-200 p-5">
                <legend className="text-m font-medium leading-6 text-gray-900">
                  Segundo Teléfono
                </legend>

                <div className="flex flex-col flex-1 gap-2">
                  <div>
                    <FormSelect
                      label="País"
                      selected={formik.values.country2}
                      name="country2"
                      onBlur={formik.handleBlur}
                      options={countries}
                      error={
                        formik.touched.country2 && formik.errors.country2
                          ? formik.errors.country2
                          : ""
                      }
                      optionString={(country) =>
                        country.countryName +
                        (country.countryCodeIso !== VEN_CODE &&
                        !!country.countryPhoneAccessCode
                          ? ` (${country.countryPhoneAccessCode})`
                          : "")
                      }
                      onSelectOption={(country) =>
                        handleCountrySelection(country, formik, true)
                      }
                      onSearch={(option, search) => {
                        return (
                          option.countryName
                            .toLowerCase()
                            .split(" ")
                            .some((s) => s.startsWith(search)) ||
                          !!option.countryPhoneAccessCode
                            ?.slice(1)
                            .startsWith(search)
                        );
                      }}
                      disabled={disableAllInputs}
                    />
                  </div>

                  <div>
                    <FormRadioGroup
                      label="Operadora"
                      name="phonePrefix2"
                      id="radiogroup-phone"
                      options={phoneRealOptions}
                      selected={formik.values.phonePrefix2}
                      optionString={(option) => option.name}
                      onSelectOption={(val) =>
                        formik.setFieldValue("phonePrefix2", val)
                      }
                      className={classNames(
                        "p-2",
                        formik.values.country2?.countryCodeIso === VEN_CODE
                          ? "visible"
                          : "hidden"
                      )}
                      disabled={disableAllInputs}
                    />
                  </div>

                  <div>
                    <FormTextInput
                      label={"Número"}
                      name="phone2"
                      type="text"
                      value={formik.values.phone2}
                      error={
                        formik.touched.phone2 && formik.errors.phone2
                          ? formik.errors.phone2
                          : ""
                      }
                      maxLength={
                        formik.values.country2?.countryCodeIso === VEN_CODE &&
                        !!formik.values.phonePrefix2?.value
                          ? 7
                          : 10
                      }
                      onChange={(e) => {
                        // Only numbers
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          formik.handleChange(e);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      disabled={disableAllInputs}
                    />
                  </div>
                </div>

                <div className="flex justify-end mt-4">
                  <PrimaryButton type="button" onClick={deleteSecondPhone} className="px-6">
                    Eliminar
                  </PrimaryButton>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>

      <div
        className={classNames(
          "flex items-center justify-end sm:px-6",
          !isReservable && "hidden"
        )}
      >
        <FormCheckbox
          id="reserved"
          name="reserved"
          label="Reservar el cliente para la comisión de venta"
          onChange={formik.handleChange}
          checked={formik.values.reserved}
        />
      </div>

      <div className="flex flex-shrink-0 justify-end px-4 py-4 gap-4 sm:px-6">
        <SecondaryButton onClick={() => setOpenForm(false)} className="px-6">
          Descartar
        </SecondaryButton>
        <PrimaryButton type="submit" className="px-6">
          Guardar
        </PrimaryButton>
      </div>
    </form>
  );
};

interface AccountFormProps {
  initialValues: AccountFormValues;
  accountItem?: AccountInterface;
  isUpdate?: boolean;
  ignoreTouched?: boolean;
  disabledIdDocument?: boolean;
  setSelectedItem: (account: AccountInterface) => void;
  disableAllInputs?: boolean;
  setOpenForm: (success: boolean) => void;
}
const AccountForm: FC<AccountFormProps> = ({
  initialValues,
  accountItem,
  isUpdate,
  ignoreTouched,
  disabledIdDocument = false,
  setSelectedItem,
  disableAllInputs,
  setOpenForm,
}) => {
  const user = useAppSelector((state) => state.user);
  const [lastEmailValidation, setLastEmailValidation] = useState("");
  const emailValidationRef = useRef(lastEmailValidation);
  const countries = useAppSelector((state) => state.inmutable.countries);
  const userLogged = useAppSelector((state) => state.user.user);
  const [loader, setLoader] = useState(false);
  const [domainError, setDomainError] = useState("");
  const [showSecondPhone, setShowSecondPhone] = useState(
    !!initialValues.phone2
  );

  const saveClient = async (
    values: AccountFormValues,
    helpers: FormikHelpers<AccountFormValues>
  ) => {
    const accountId = !!isUpdate && !!accountItem ? accountItem.id : null;
    const emailId =
      !!isUpdate && !!accountItem
        ? accountItem.listAccountEmail[0]?.emailID
        : null;

    const listAccountPhone = [
      {
        phoneID:
          isUpdate && !!accountItem
            ? accountItem.listAccountPhone[0]?.phoneID
            : null,
        accountID: accountId,
        countryID: values.country.id,
        phoneNumber: !!values.phonePrefix.value
          ? values.phonePrefix.value + values.phone
          : values.phone,
        countryPhoneAccessCode: countries.find(
          (c) => c.id === values.country.id
        )!.countryPhoneAccessCode,
      },
    ];
    if (!!values.phone2) {
      listAccountPhone.push({
        phoneID:
          isUpdate && !!accountItem && accountItem.listAccountPhone.length > 1
            ? accountItem.listAccountPhone[1].phoneID
            : null,
        accountID: accountId,
        countryID: values.country2!.id,
        phoneNumber: !!values.phonePrefix2?.value
          ? values.phonePrefix2.value + values.phone2
          : values.phone2,
        countryPhoneAccessCode: countries.find(
          (c) => c.id === values.country2!.id
        )!.countryPhoneAccessCode,
      });
    }

    const account: AccountRequestInterface = {
      id: accountId,
      accountTypeID: 3, // represent AccountType = 'Cliente'
      accountFullName: values.fullName,
      taxIdentificationTypeID: values.idType.taxIdentificationTypeId,
      taxIdentificationTypeCode: values.idType.taxIdentificationTypeCode,
      abreviationName: values.idType.abreviationName, // TODO add also taxIdTypeId needed in API service
      fiscalAddress: values.address,
      countryID: values.country.id,
      identificationNumber: values.idNumber,
      listAccountEmail: [
        {
          email: values.email,
          accountID: accountId,
          emailTypeID: 1,
          emailID: emailId,
        },
      ],
      listAccountPhone,
      creationUser: userLogged?.userLogin ?? null,
      creationDate: new Date().toISOString(),
      accountCode: null,
      listAuthorizingAccount: [],
    };

    loaderService.start();
    const response = isUpdate
      ? await updateAccount(account)
      : await saveAccount(account);
    loaderService.stop();

    if (response.didError || !response.model) {
      if (response.errorMessage === "Número de Identificación ya existe") {
        helpers.setFieldError("idNumber", response.errorMessage);
      } else {
        helpers.setFieldError("fullname", response.errorMessage);
      }
      return;
    }

    if (values.reserved) {
      if (!user.businessUnit) {
        alertService.error(
          "No se pudo reservar el cliente, el usuario no tiene una unidad de negocio asignada"
        );
        return;
      } else {
        const reserveResponse = await reserveBUSeller(
          user.businessUnit.code,
          response.model.id,
          user.user?.userLogin ?? ""
        );

        if (reserveResponse.didError) {
          alertService.error(
            "Hubo un error al reservar el cliente",
            response.errorMessage
          );
        } else {
          setSelectedItem({
            ...response.model,
            buSeller: reserveResponse.model ?? undefined,
          });
          setOpenForm(false);
          alertService.success("El cliente se guardó exitosamente");
          return;
        }
      }
    }

    setSelectedItem(response.model);
    setOpenForm(false);
    alertService.success("El cliente se guardó exitosamente");
  };

  const validateDomain = async (email: string) => {
    setLoader(true);
    const result = await validateEmailDomain(email);
    setLoader(false);

    if (!result && emailValidationRef.current === email) {
      const domain = email.split("@")[1];
      setDomainError(`El dominio "${domain}" del correo electrónico no existe`);
    } else {
      setDomainError("");
    }

    return result;
  };

  const validationDebounced = useMemo(() => debounce(validateDomain, 500), []);

  const validationCallback = useCallback(
    (email: string) => validationDebounced(email),
    [validationDebounced]
  );

  // Validations with Yup for Formik form
  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .required("El campo es obligatorio")
      .test(
        "fullname-format",
        "El nombre de usuario es inválido",
        function (fullname) {
          const result = validateFullname(fullname);

          if (result.error) {
            return this.createError({
              message: result.message,
            });
          }

          return true;
        }
      ),
    email: Yup.string()
      .required("El campo es obligatorio")
      .matches(
        /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
        "Favor ingresar un correo electrónico válido"
      )
      .test(
        "email-format",
        "Favor ingresar un correo electrónico válido",
        (email) => {
          return validateEmailRegex(email);
        }
      )
      .test(
        "email-desposable",
        "Favor no usar un correo electrónico desechable",
        (email) => {
          return validateEmailDisposable(email);
        }
      )
      .test(
        "email-domain-exists",
        "El dominio del correo electrónico no existe",
        function (email) {
          setLastEmailValidation(email);
          validationCallback(email);
          if (domainError !== "") {
            return this.createError({
              message: domainError,
            });
          }
          return true;
        }
      ),
    country: Yup.object().required("El campo es obligatorio"),
    phone: Yup.string()
      .required("El campo es obligatorio")
      .test(
        "phone-number",
        "El número de teléfono es inválido",
        function (phone) {
          const { country, phonePrefix } = this.parent as AccountFormValues;
          const result = validatePhone(
            phone,
            phonePrefix?.value ?? "",
            country
          );

          if (result.error) {
            return this.createError({
              message: result.message,
            });
          }

          return true;
        }
      ),
    phone2: Yup.string().test(
      "phone-number",
      "El número de teléfono es inválido",
      function (phone) {
        if (!phone) return true;

        const { country2, phonePrefix2 } = this.parent as AccountFormValues;
        const result = validatePhone(
          phone,
          phonePrefix2?.value ?? "",
          country2!
        );

        if (result.error) {
          return this.createError({
            message: result.message,
          });
        }

        return true;
      }
    ),
    idNumber: Yup.string()
      .required("El campo es obligatorio")
      .test(
        "id-number",
        "El número de cédula es inválido",
        function (idNumber) {
          const { idType } = this.parent as AccountFormValues;
          const result = validateIdentityDocument(
            idNumber!,
            idType.abreviationName
          );

          if (result.error) {
            return this.createError({
              message: result.message,
            });
          }

          return true;
        }
      ),
  });

  useEffect(() => {
    emailValidationRef.current = lastEmailValidation;
  }, [lastEmailValidation]);

  return (
    <Formik<AccountFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values, helpers) => {
        saveClient(values, helpers);
      }}
    >
      {(formik) => {
        return (
          <Form
            formik={formik}
            loader={loader}
            isUpdate={!!isUpdate}
            domainError={domainError}
            ignoreTouched={!!ignoreTouched}
            showSecondPhone={showSecondPhone}
            disabledIdDocument={disabledIdDocument}
            disableAllInputs={disableAllInputs}
            showFixedPhone={!initialValues.phonePrefix.value}
            setShowSecondPhone={setShowSecondPhone}
            setOpenForm={setOpenForm}
          />
        );
      }}
    </Formik>
  );
};

export default AccountForm;
