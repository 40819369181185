import { useMemo, useState } from "react";
import { ManifestHeaderInterface } from "../../interfaces/ManifestInterface";
import { FormTextInput } from "../../components/FormFields";
import moment from "moment";
import PaginationFooter from "../../components/PaginationFooter";

interface ManifestHeaderTableProps {
  manifests: ManifestHeaderInterface[];
  onManifestClick: (manifest: ManifestHeaderInterface) => void;
}

const ROW_PER_PAGES = 10;

const ManifestHeaderTable: React.FC<ManifestHeaderTableProps> = ({
  manifests,
  onManifestClick,
}) => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  const filteredManifests = useMemo(() => {
    const tokens = search.toLowerCase().split(" ");

    return manifests
      .filter((m) =>
        tokens.every(
          (t) =>
            m.masterShipmentHeaderCode?.toLowerCase().includes(t) ||
            m.transportModelName?.toLowerCase().includes(t) ||
            m.transportPlate?.toLowerCase().includes(t) ||
            m.drivers?.toLowerCase().includes(t)
        )
      )
      .sort((a, b) =>
        moment(a.creationDate) < moment(b.creationDate) ? 1 : -1
      );
  }, [manifests, search]);

  const pageCount = useMemo(() => {
    return Math.ceil(filteredManifests.length / ROW_PER_PAGES);
  }, [filteredManifests]);

  const offset = useMemo(() => currentPage * ROW_PER_PAGES, [currentPage]);

  const filteredManifestsPage = useMemo(
    () => filteredManifests.slice(offset, offset + ROW_PER_PAGES),
    [filteredManifests, offset]
  );

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  return (
    <div className="flex flex-col w-full gap-6">
      <div className="flex flex-col max-w-[40rem]">
        <FormTextInput
          label="Filtrar"
          placeholder="Por número de manifiesto, vehículo o conductor"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      {filteredManifests.length === 0 && (
        <p className="text-gray-400 text-center my-16">
          No hay manifiestos en esta lista
        </p>
      )}

      {filteredManifests.length > 0 && (
        <div className="overflow-x-auto pl-2">
          <table className="table-auto w-full">
            <thead>
              <tr className="text-center">
                <th className="px-4 py-2 font-semibold text-xs">ITEM</th>
                <th className="px-4 py-2 font-semibold text-xs">MANIFIESTO</th>
                <th className="px-4 py-2 font-semibold text-xs">ORIGEN</th>
                <th className="px-4 py-2 font-semibold text-xs">DESTINO</th>
                <th className="px-4 py-2 font-semibold text-xs">
                  FECHA CREACIÓN
                </th>
                <th className="px-4 py-2 font-semibold text-xs">
                  FECHA CIERRE
                </th>
                <th className="px-4 py-2 font-semibold text-xs">VEHÍCULO</th>
                <th className="px-4 py-2 font-semibold text-xs">CONDUCTOR</th>
                <th className="px-4 py-2 font-semibold text-xs">ESTATUS</th>
              </tr>
            </thead>

            <tbody>
              {filteredManifestsPage.map((m, index) => (
                <tr
                  key={m.masterShipmentHeaderID}
                  onClick={() => onManifestClick(m)}
                  className={`cursor-pointer hover:bg-gray-100 text-center ${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  }`}
                >
                  <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
                    {offset + index + 1}
                  </td>
                  <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
                    {m.masterShipmentHeaderCode}
                  </td>
                  <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
                    {m.buCodeSource}
                  </td>
                  <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
                    {m.buCodeDestination}
                  </td>
                  <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
                    {moment(m.creationDate).format("DD/MM/YYYY hh:mm:ss A")}
                  </td>
                  <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
                    {m.manifestClosedDate
                      ? moment(m.manifestClosedDate).format(
                          "DD/MM/YYYY hh:mm:ss A"
                        )
                      : ""}
                  </td>
                  <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
                    {m.transportModelName?.toUpperCase()}
                    {" - "}
                    {m.transportPlate?.toUpperCase()}
                  </td>
                  <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
                    {m.driver1IdentificationNumber}
                    {" - "}
                    {m.driver1Name?.toUpperCase()}
                  </td>
                  <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
                    {m.statusName?.toUpperCase()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <PaginationFooter
            rowCounts={ROW_PER_PAGES}
            itemsOffSet={offset}
            totalPages={pageCount}
            totalItems={filteredManifests.length}
            handlePageClick={handlePageClick}
          />
        </div>
      )}
    </div>
  );
};

export default ManifestHeaderTable;
