import api from "./api";
import {
  BusinessUnitInterface,
  Nullable,
  ResponseInterface,
} from "../interfaces";
import { UserInterface } from "../interfaces/UserInterface";
import { FileDocument } from "./shipmentServices";
import { base64ToFile } from "../utils";

const baseURLAPI = process.env.REACT_APP_API_HOST;

interface PODInsertResponse {
  podid: string;
}

interface PODInsertAttachmentResponse {
  podAttachmentID: string | null;
}

export const savePOD = async (
  shipmentId: string,
  accountID: string,
  deliveryPersonName: string,
  deliveryIdentification: string,
  observation: string,
  user: UserInterface,
  businessUnit: BusinessUnitInterface
): Promise<ResponseInterface<PODInsertResponse>> => {
  const uri = `${baseURLAPI}/POD`;
  const body = JSON.stringify({
    BUCodeDelivery: businessUnit.code,
    DeliveryAuthorization: false,
    DeliveryDate: new Date().toISOString(),
    AccountID: accountID,
    ShipmentID: shipmentId,
    DeliveryPersonName: deliveryPersonName,
    DeliveryIdentification: deliveryIdentification,
    DeliveryObservation: observation,
    CreationUser: user.userLogin,
    CreationDate: new Date().toISOString(),
  });
  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    return (error as any)?.response?.data;
  }
};

export const updatePOD = async (
  shipmentList: string[],
  fingerprintAttch: string | null,
  physicalSignature: boolean | null,
  updateUser: string,
  statusID: number = 1
): Promise<ResponseInterface<boolean>> => {
  const uri = `${baseURLAPI}/POD/Update`;
  const body = JSON.stringify({
    ShipmentNumber: shipmentList,
    ConsigneeFingerPrintAttch: fingerprintAttch,
    PhysicalPODConfirmed: physicalSignature,
    StatusID: statusID,
    UpdateUser: updateUser,
  });
  try {
    const response = await api.put(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    return (error as any)?.response?.data;
  }
};

export const getPODDocument = async (
  shipmentNumbers: string[]
): Promise<Nullable<FileDocument>> => {
  const uri = `${baseURLAPI}/v1/Shipment/DownloadDocumentDelivery`;

  const body = JSON.stringify({
    List: shipmentNumbers.map((shipmentNumber) => ({ shipmentNumber })),
    type: 0,
  });

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    });

    if (response.status === 200) {
      return {
        file: response.data,
        fileName: `PODDocument_${shipmentNumbers[0]}.pdf`,
      };
    }
  } catch (error) { }
  return null;
};

// Save POD attachment
export const savePODAttachment = async (
  fingerprint: string | undefined,
  photoAttachments: string[] | undefined,
  podID: string,
  creationUser: string,
  statusID: number = 1
): Promise<ResponseInterface<PODInsertAttachmentResponse>> => {
  const uri = `${baseURLAPI}/POD/InsertAttachment`;
  const formData = new FormData();
  if (fingerprint && fingerprint !== "") {
    const fingerprintPNG = base64ToFile(fingerprint, "image/png", "fingerprint.png");
    const fingerprintBlob = new Blob([fingerprintPNG], { type: "image/png" });
    formData.append("Fingerprint", fingerprintBlob, "fingerprint-POD.png");
  }
  if (photoAttachments && photoAttachments.length > 0) {
    for (let i = 0; i < photoAttachments.length; i++) {
      const attachmentPNG = base64ToFile(
        photoAttachments[i],
        "image/png",
        "attachment-POD.png"
      )
      formData.append(`PhotoAttachment${[i + 1]}`, attachmentPNG);
    }
  }

  formData.append("PODID", podID);
  formData.append("CreationUser", creationUser);
  formData.append("StatusID", statusID.toString());

  try {
    const response = await api.post(uri, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    return (error as any)?.response?.data;
  }
};
