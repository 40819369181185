import React, { useState } from "react";
import Modal from "../../components/Modal";
import { XMarkIcon } from "@heroicons/react/20/solid";
import {
  ManifestHeaderInterface,
  ManifestDetailInterface,
} from "../../interfaces/ManifestInterface";
import ManifestHeaderData from "./ManifestHeaderData";
import ManifestDetailsTable from "./ManifestDetailTable";
import { PrimaryButton } from "../../components/Buttons";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import DropdownMenu from "../../components/DropdownMenu";
import downloadManifest from "../../services/manifestServices";
import { useAppSelector } from "../../store/hooks";

interface ManifestModalProps {
  manifest: ManifestHeaderInterface;
  detailData: ManifestDetailInterface[] | null;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

enum ManifestReportOption {
  DETALLADO,
  AGRUPADO,
}
const reportOptions = [
  {
    title: "DETALLADO",
    value: ManifestReportOption.DETALLADO,
  },
  {
    title: "AGRUPADO",
    value: ManifestReportOption.AGRUPADO,
  },
];

const ManifestDetailModal: React.FC<ManifestModalProps> = ({
  manifest,
  detailData,
  openModal,
  setOpenModal,
}) => {
  const [openPdfPopover, setOpenPdfPopover] = useState(false);
  const [openExcelPopover, setOpenExcelPopover] = useState(false);
  const user = useAppSelector((state) => state.user);

  const handleDownloadClick = async (type: number) => {
    downloadManifest(
      manifest.masterShipmentHeaderCode as string,
      type, // 1=AGRUPADO. 2=DETALLADO
      user.user?.userLogin as string,
      openPdfPopover === true ? 1 : 2 // 1=PDF. 2=EXCEL
    );
  };

  const handleDropdownMenuSelect = (
    option: { value: ManifestReportOption },
    type: "pdf" | "excel"
  ) => {
    if (type === "pdf") {
      setOpenExcelPopover(false);
      setOpenPdfPopover(true);
    } else {
      setOpenPdfPopover(false);
      setOpenExcelPopover(true);
    }
    if (option.value === ManifestReportOption.AGRUPADO) {
      handleDownloadClick(1); // 1= AGRUPADO.
    } else {
      handleDownloadClick(2); //2= DETALLADO
    }
  };

  // Redux state

  return (
    <Modal
      openModal={openModal}
      setOpenModal={setOpenModal}
      className="bg-gray-200"
    >
      <div className="p-4 flex flex-1 flex-col min-w-[60rem] mb-4">
        {/* Header Title */}
        <div className="flex justify-between">
          <div>
            <span className="font-bold text-xl">Manifiesto: </span>
            <span className="text-indigo-600 font-bold text-xl">
              {manifest.masterShipmentHeaderCode}
            </span>
          </div>

          <div className="flex items-center justify-end">
            <button
              type="button"
              className="flex items-center justify-center relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onClick={() => setOpenModal(false)}
            >
              <span className="absolute -inset-2.5" />
              <span className="sr-only">Cerrar panel</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>

        <div className="w-full mb-1">
          <span className="text-gray-600">Fecha: </span>
          <span className="text-gray-800">
            {!!manifest?.manifestClosedDate &&
              new Date(manifest.manifestClosedDate)
                .toLocaleDateString("es-VE", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })
                .replace("a", "AM")
                .replace("p", "PM")
                .slice(0, -4)}
          </span>
        </div>
      </div>

      {/* linea separador */}
      <hr className="my-1 mx-2 mb-2" />

      {/* Header data */}
      <div className="flex flex-1 gap-4">
        {/* Shipper */}
        <ManifestHeaderData
          masterShipmentHeaderID={manifest.masterShipmentHeaderID}
          masterShipmentHeaderCode={manifest.masterShipmentHeaderCode}
          manifestTypeID={manifest.manifestTypeID}
          buCodeSource={manifest.buCodeSource}
          buCodeDestination={manifest.buCodeDestination}
          route={manifest.route?.toUpperCase()}
          creationDate={manifest.creationDate}
          transport={
            manifest.transportModelName?.toUpperCase() +
            " - " +
            manifest.transportPlate?.toUpperCase()
          }
          driver1Name={manifest.driver1Name?.toUpperCase()}
          statusName={manifest?.statusName?.toUpperCase() || ""}
        />
      </div>

      {/* Línea separadora */}
      <hr className="my-2 mx-2" />

      {/* Detalles del manifiesto */}
      <div className="gap-4 p-2">
        <ManifestDetailsTable
          data={detailData}
          showItem={false}
          showAllStatus={true}
        />
      </div>

      <div className="flex flex-1 flex-row">
        <div className="relative my-4 ml-2">
          <PrimaryButton
            onClick={() => setOpenPdfPopover(!openPdfPopover)}
            className="relative w-28"
          >
            PDF
            {openPdfPopover ? (
              <ChevronUpIcon
                className="absolute right-1 top-1/2 transform -translate-y-1/2 h-6 w-6 ml-2"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="absolute right-1 top-1/2 transform -translate-y-1/2 h-6 w-6 ml-2"
                aria-hidden="true"
              />
            )}
          </PrimaryButton>
          <DropdownMenu
            open={openPdfPopover}
            setOpen={setOpenPdfPopover}
            onSelect={(option) => handleDropdownMenuSelect(option, "pdf")}
            options={reportOptions}
            className="!mt-2 w-full transition ease-in-out duration-300"
          />
        </div>

        <div className="relative my-4 ml-2">
          <PrimaryButton
            onClick={() => setOpenExcelPopover(!openExcelPopover)}
            className="relative w-28"
          >
            EXCEL
            {openExcelPopover ? (
              <ChevronUpIcon
                className="absolute right-1 top-1/2 transform -translate-y-1/2 h-6 w-6 ml-2"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="absolute right-1 top-1/2 transform -translate-y-1/2 h-6 w-6 ml-2"
                aria-hidden="true"
              />
            )}
          </PrimaryButton>
          <DropdownMenu
            open={openExcelPopover}
            setOpen={setOpenExcelPopover}
            onSelect={(option) => handleDropdownMenuSelect(option, "excel")}
            options={reportOptions}
            className="!mt-2 w-full transition ease-in-out duration-300"
          />
        </div>
      </div>
    </Modal>
  );
};
export default ManifestDetailModal;
