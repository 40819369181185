export interface ManifestHeaderInterface {
  masterShipmentHeaderID?: string;
  masterShipmentHeaderCode?: string;
  manifestTypeID: number;
  buCodeSource: string;
  buSource: string;
  buCodeDestination: string;
  accountID?: string;
  accountSiteID?: number;
  shippingMethodID?: number;
  routeID?: number;
  route: string;
  transportID: string;
  shipperName?: string;
  consigneeName?: string;
  estimatedDepartureDate?: Date;
  estimatedArrivingDate?: Date;
  barcode?: string;
  isQRCode?: boolean | 0;
  masterShipmentBooking?: string;
  masterShipmentExternalNumber?: string;
  masterShipmentReference?: string;
  masterShipmentHeaderObservation?: string | "";

  driver1ID: string;
  driver1Name?: string;
  driver1IdentificationNumber?: string;
  driver1PhoneNumber?: string;
  driver2ID?: string;
  driver2Name?: string;
  driver2IdentificationNumber?: string;
  driver3ID?: string;
  driver3Name?: string;
  driver3IdentificationNumber?: string;

  seal1?: string | "";
  seal2?: string | "";
  seal3?: string | "";

  transport?: string;
  transportPlate?: string;
  transportModelName?: string;
  transportColor?: string;

  brandName?: string;
  transportTypeName?: string;
  driversPhoneNumber?: string;

  totalPieces?: number;
  totalWeight?: number;
  numberOfShipments?: number;

  initialScanDate?: Date;
  lastScanDate?: Date;
  manifestClosedDate?: Date;
  manifestCreationDate?: Date;
  masterShipmentAssociationList?: MasterShipmentAssociationInterface[];
  manifestDetail?: ManifestDetailInterface[];

  citySource?: string;
  cityDestination?: string;

  creationUser?: string;
  creationDate?: Date;
  updateUser?: string;
  updateDate?: string;
  statusID?: number;
  statusName?: string;
  status?: ManifestStatus;
  applicationID?: number | 10;

  sealXml?: string;
  driverAccountXml?: string;
  driversIdentificationNumber?: string;
  drivers?: string;
}

export interface ManifestHeaderUpdateInterface {
  masterShipmentHeaderID: string;
  transportID?: string;
  driver1ID?: string;
  seal1?: string;
  seal2?: string;
  seal3?: string;
  masterShipmentHeaderObservation?: string | "";
  updateUser: string;
}

export interface ManifestHeaderSingleInterface {
  masterShipmentHeaderID?: string;
  masterShipmentHeaderCode?: string;
  manifestTypeID: number;
  buCodeSource: string;
  buCodeDestination: string;
  route: string;
  driver1Name?: string;
  transport?: string;
  creationDate?: Date;
  status?: ManifestStatus;
  statusName: string;
}

export interface ManifestDetailInterface {
  masterShipmentHeaderID?: string;
  containerHeaderId?: string;
  containerDetailID?: string | null;
  shipmentDetailID?: string;
  serviceID?: number;
  serviceName?: string;
  serviceCode: string;
  buCodeSource: string;
  buCodeDestination?: string;
  locationDestinationCode: string;
  locationConsigneeID?: number;
  weightUnitID?: number;
  measureUnitTypeID?: number;
  lineSeqNumber?: number;
  difference: number;
  shipmentNumber: string;
  pieceNumber: number;
  totalPieces: number;
  heigh?: number;
  width?: number;
  lenght?: number;
  physicalWeight: number;
  dimensionalWeight?: number;
  barCode: string;
  buBase: string;
  creationUser: string;
  creationDate: string;
  updateUser?: string;
  updateDate?: string;
  statusID?: ManifestStatus;
  lastUpdate?: string;
  applicationID: number;
  daysCreation: number;
  incidence: ManifestIncidence;
  incidenceWarning: boolean;
  warnings: string;
  errors: string;
}

export interface ManifestDetailSingleInterface {
  containerDetailID?: string | null;
  shipmentDetailID?: string | null;
  serviceID?: number;
  serviceCode: string;
  serviceName?: string;
  buCodeSource: string;
  locationDestinationCode: string;
  shipmentNumber: string;
  pieceNumber: number;
  totalPieces: number;
  physicalWeight: number;
  barcode?: string;
  lineSeqNumber?: number;
  difference: number;
  manifestType?: ManifestType;
  creationDate: string;
  daysCreation: number;
  incidence: ManifestIncidence;
  incidenceWarning: boolean;
  statusID?: number;
  warnings: string;
  errors: string;

  // Aux fields
  _date?: string;
}

export interface ManifestViewModel {
  masterShipmentHeaderID?: string;
  masterShipmentHeaderCode: string;
  manifestTypeID: number;
  buCodeSource: string;
  buCodeDestination: string;
  routeID?: number;
  transportID?: string;
  vehicleID?: string;
  driver1ID: string;
  driver2ID?: string;
  driver3ID?: string;
  seal1: string;
  seal2: string;
  seal3: string;
  statusID?: number;
  totalShipments: number;
  totalPieces: number;
  totalWeigth: number;
  manifestDetails?: ManifestDetailInterface[];
  affectingManifests?: MasterShipmentAssociationInterface[];
  masterShipmentHeaderObservation?: string | "";
  masterShipmentHeaderObserDispatch?: string | "";
  // masterShipmentHeaderIDDispatch?: string | "";
  // masterShipmentHeaderCodeDispatch?: string | "";
}

export interface MasterShipmentAssociationInterface {
  masterShipmentHeaderID: string;
  masterShipmentHeaderCode: string;
  statusIDAssociate: number;
}

export interface CreateManifestRespInterface {
  masterShipmentHeaderID: string;
  masterShipmentHeaderCode: string;
}

export interface RequestManifestHeaderInterface {
  manifestCode?: string;
  buCodeSource?: string;
  buCodeDestination?: string;
  fromDate: Date;
  toDate: Date;
}

export enum ManifestDispatchPhase {
  DISPATCH_HOME,
  DISPATCH_NEW,
}

export enum ManifestReceiptPhase {
  RECEIPT_HOME,
  RECEIPT_NEW,
}

export enum ManifestType {
  DESPACHO = 1,
  RECEPCION,
}

export enum ManifestStatus {
  ACTIVO = 31,
  INACTIVO = 32,
  POSTERGADO = 33,
  ELIMINADO = 34,
  GENERADO = 35,
}

export enum ManifestShipmentIncidents {
  FALTANTES = 1,
  SOBRANTES = 2,
  ANULADAS = 35,
}
export enum ManifestIncidence {
  NOT_VALIDATED = 0,
  NO_ERROR = 1,
  WARNING = 2,
  ERROR = 3,
  CRITIC_ERROR = 4,
}

export interface DispatchManifestForm {
  masterShipmentHeaderID: string;
  masterShipmentHeaderCode: string;
  route: string;
  routeID?: number | null;
  consigneeName: string;
  buCodeConsignee: string;
  vehicle: string;
  vehicleID: string;
  transportID: string;
  transportPlate?: string;
  transportModelName?: string;
  driver: string;
  // driverID: string;
  driver1ID: string;
  driver1Name?: string;
  driver1IdentificationNumber?: string;
  seal1?: string | "";
  seal2?: string | "";
  seal3?: string | "";
  shipmentNumber: string;
  piece: number;
  manifestType: ManifestType;
  buCodeSource: string;
  buSource: string;
  masterShipmentHeaderObservation?: string | "";
  statusID?: number | null;
  manifestClosedDate?: Date;
  // props para el manejo de recepcion y almacenar la relacion con el despacho
  totalShipments: number;
  totalPieces: number;
  masterShipmentHeaderObserDispatch?: string | "";
  masterShipmentHeaderIDDispatch?: string | "";
  masterShipmentHeaderCodeDispatch?: string | "";
}

export interface Route {
  routeID?: number;
  // buCodeSource: string;
  // buSourceName: string;
  // buSourceID?: number;
  buConsigneeName: string;
  buCodeConsignee: string;
  // buConsigneeID?: number;
  // routeCode: string;
  routeName: string;
  // routeDescription: string;

  // creationUser: string;
  // creationDate: string;
  // updateUser?: string;
  // updateDate?: string;
  // statusID: number;
  // lastUpdate: string;
  // applicationID: number;
}

export interface Vehicle {
  transportID?: string;
  transportModelID: number;
  buCode: string;
  transportYear: number;
  transportPlate: string;
  transportPlateBarcode: string;
  transportColor: string;
  transportObservation: string;

  transportModel: string;
  businessUnit: string;
  transportTypeName: string;
  status: string;

  creationUser: string;
  creationDate: string;
  updateUser?: string;
  updateDate?: string;
  statusID: number;
  lastUpdate: string;
  applicationID: number;
}

export interface AccountDriver {
  accountID: string;
  accountCode: string;
  accountTypeID?: number;
  taxIdentificationTypeID?: number;
  taxIDentificationTypeCode: string;
  identificationNumber: string;
  accountFullName: string;
}

export interface ManifestPostponedHeaderRequest {
  sourceBUCode?: string | null;
  destinationBUCode?: string | null;
  manifestTypeID: number;
  statusID?: number | null;
  fromDate: Date;
  toDate: Date;
}

export interface InfoDataManifest {
  buSource: string;
  routeID: string;
  routeName: string;
  buCodeConsignee: string;
  buConsigneeName: string;
}
