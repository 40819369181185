import * as Yup from "yup";
import { FC, useState } from "react";
import {
  ManifestDetailInterface,
  ManifestHeaderInterface,
  ManifestType,
} from "../../interfaces/ManifestInterface";
import classNames from "classnames";
import ManifestHeaderTable from "./ManifestHeaderTable";
import { FormDatePicker, FormTextInput } from "../../components/FormFields";
import moment from "moment";
import { PrimaryButton } from "../../components/Buttons";
import { useAppSelector } from "../../store/hooks";
import { alertService, loaderService } from "../../services";
import {
  getManifestDetail,
  getManifestHeader,
} from "../../services/manifestServices";
import { Formik } from "formik";
import ManifestDetailModal from "./ManifestDetailModal";

interface ReceiptSearchFromValues {
  receiptNumber: string;
  toDate: Date | null;
  fromDate: Date | null;
}

const ReceiptGeneratedTable: FC = () => {
  const loading = loaderService.useIsLoading();
  const user = useAppSelector((state) => state.user);

  const [openModal, setOpenModal] = useState(false);
  const [receipt, setReceipt] = useState<ManifestHeaderInterface>();
  const [details, setDetails] = useState<ManifestDetailInterface[]>([]);
  const [generatedReceiptList, setGeneratedReceiptList] = useState<
    ManifestHeaderInterface[]
  >([]);

  const validationSchema = Yup.object().shape({
    toDate: Yup.date().required("La fecha inicial es requerida"),
    fromDate: Yup.date().required("La fecha final es requerida"),
  });

  const handleSearch = async (values: ReceiptSearchFromValues) => {
    const buCode = user.businessUnit?.code;

    if (!buCode) {
      alertService.error(
        "No hay ninguna tienda asociada a la sesión del usuario."
      );
      return;
    }

    loaderService.start();
    const receipts = await getManifestHeader(
      buCode,
      values.fromDate,
      moment(values.toDate).endOf("days").toDate(),
      ManifestType.RECEPCION,
      values.receiptNumber || null
    );
    loaderService.stop();

    if (receipts === null || receipts.length === 0) {
      setGeneratedReceiptList([]);
      return;
    }

    setGeneratedReceiptList(
      receipts.map((d) => {
        const driverNames = d.drivers?.split(",");
        const driverIds = d.driversIdentificationNumber?.split(",");
        return {
          ...d,
          driver1Name: driverNames?.[0] ?? "",
          driver1IdentificationNumber: driverIds?.[0] ?? "",
          driver2Name: driverNames?.[1] ?? "",
          driver2IdentificationNumber: driverIds?.[1] ?? "",
          driver3Name: driverNames?.[2] ?? "",
          driver3IdentificationNumber: driverIds?.[2] ?? "",
        };
      })
    );
  };

  const handleReceiptClick = async (receipt: ManifestHeaderInterface) => {
    const masterShipmentHeaderCode = receipt.masterShipmentHeaderCode;

    if (loading) {
      return;
    }
    if (!masterShipmentHeaderCode) {
      alertService.error("Parece que la recepción no tiene un código asociado");
      return;
    }

    loaderService.start();
    const receiptDetails = await getManifestDetail(
      masterShipmentHeaderCode,
      true
    );
    loaderService.stop();

    if (
      receiptDetails === null ||
      !receiptDetails[0] ||
      !receiptDetails[0].manifestDetail
    ) {
      alertService.error("Hubo un error obteniendo los detalles de la recepción");
      return;
    }

    const sorteredReceiptDetails = receiptDetails[0].manifestDetail.sort(
      (a, b) => {
        const aValue = a.lineSeqNumber ?? -Infinity;
        const bValue = b.lineSeqNumber ?? -Infinity;

        return bValue - aValue;
      }
    );

    setReceipt(receipt);
    setDetails(sorteredReceiptDetails);
    setOpenModal(true);
  };

  return (
    <div className="flex flex-1 flex-col bg-white gap-4 rounded-lg border px-6 py-6 w-full">
      <h1 className="text-2xl font-semibold text-gray-800 truncate mb-2">
        Recepciones Generadas
      </h1>
      <div
        className={classNames("flex flex-1 flex-col bg-white gap-4 rounded-lg")}
      >
        <Formik<ReceiptSearchFromValues>
          initialValues={{
            receiptNumber: "",
            toDate: new Date(),
            fromDate: moment().subtract(3, "days").toDate(),
          }}
          validationSchema={validationSchema}
          onSubmit={handleSearch}
        >
          {(formik) => (
            <form
              className="flex flex-row gap-4 mb-2 w-full"
              onSubmit={formik.handleSubmit}
            >
              <div className="w-full sm:w-1/3">
                <FormTextInput
                  id="receiptNumber"
                  name="receiptNumber"
                  value={formik.values.receiptNumber}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "receiptNumber",
                      e.target.value.toUpperCase()
                    )
                  }
                  label="Código de Manifiesto"
                  placeholder="Ingrese código de manifiesto"
                />
              </div>

              <div className="w-full sm:w-1/3">
                <FormDatePicker
                  id="fromDate"
                  name="fromDate"
                  label="Desde"
                  error={formik.errors.fromDate}
                  useRange={false}
                  maxDate={new Date()}
                  value={{
                    startDate: formik.values.fromDate,
                    endDate: formik.values.fromDate,
                  }}
                  toggleClassName={(oldClassname) =>
                    classNames(oldClassname, "text-indigo-600")
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "fromDate",
                      !!e?.startDate ? moment(e.startDate).toDate() : null
                    )
                  }
                  configs={{
                    shortcuts: {},
                  }}
                />
              </div>
              <div className="w-full sm:w-1/3">
                <FormDatePicker
                  id="toDate"
                  name="toDate"
                  label="Hasta"
                  error={formik.errors.toDate}
                  useRange={false}
                  maxDate={new Date()}
                  value={{
                    startDate: formik.values.toDate,
                    endDate: formik.values.toDate,
                  }}
                  toggleClassName={(oldClassname) =>
                    classNames(oldClassname, "text-indigo-600")
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "toDate",
                      !!e?.startDate ? moment(e.startDate).toDate() : null
                    )
                  }
                  configs={{
                    shortcuts: {},
                  }}
                />
              </div>

              <div className="w-1/12 mt-8">
                <PrimaryButton className="!px-6 !py-2" disabled={loading}>
                  Buscar
                </PrimaryButton>
              </div>
            </form>
          )}
        </Formik>

        <ManifestHeaderTable
          manifests={generatedReceiptList}
          onManifestClick={handleReceiptClick}
        />
      </div>

      {receipt && (
        <ManifestDetailModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          manifest={receipt}
          detailData={details}
        />
      )}
    </div>
  );
};

export default ReceiptGeneratedTable;
