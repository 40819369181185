import { FC, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { FormTextInput } from "../../components/FormFields";
import { PrimaryButton } from "../../components/Buttons";
import {
  ChevronUpIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import {
  createManifestHeader,
  getManifestDetail,
  updateManifestHeader,
} from "../../services/manifestServices";
import { alertService, loaderService } from "../../services";
import {
  ManifestHeaderUpdateInterface,
  ManifestStatus,
  ManifestType,
} from "../../interfaces/ManifestInterface";
import {
  setDispatchHeader,
  setReceiptHeader,
} from "../../store/slices/receipt";
import classNames from "classnames";
import moment from "moment";

const ReceiptManifestForm: FC = () => {
  const dispatch = useAppDispatch();
  const loading = loaderService.useIsLoading();
  const user = useAppSelector((state) => state.user);
  const receiptHeader = useAppSelector((state) => state.receipt.receiptHeader);
  const dispatchHeader = useAppSelector(
    (state) => state.receipt.dispatchHeader
  );

  const [editMode, setEditMode] = useState(true);
  const [observation, setObservation] = useState("");
  const [dispatchCodeError, setDispatchCodeError] = useState("");
  const [showDispatchData, setShowDispatchData] = useState(false);
  const [dispatchCode, setDispatchCode] = useState("");

  const totalDispatchShipments = useMemo(() => {
    if (!dispatchHeader) {
      return 0;
    }

    const uniqueShipments = new Set(
      dispatchHeader.manifestDetail?.map((item) => item.shipmentNumber)
    );
    return uniqueShipments.size;
  }, [dispatchHeader]);

  const handleDispatchSearch = async () => {
    const buCode = user.businessUnit?.code;

    if (!buCode) {
      alertService.error("No hay una tienda asociada a la sesión del usuario");
      return;
    }

    const dispatchList = await getManifestDetail(dispatchCode);

    if (dispatchList === null || !dispatchList[0]) {
      setDispatchCodeError(
        `El número de manifiesto ${dispatchCode} no se encuentra registrado`
      );
      return;
    }

    const dispatchManifest = dispatchList[0];

    if (dispatchManifest.buCodeDestination !== buCode) {
      setDispatchCodeError(
        `El número de manifiesto ${dispatchCode} no es un despacho a mi tienda`
      );
      return;
    }
    if (dispatchManifest.statusID !== ManifestStatus.GENERADO) {
      const status =
        ManifestStatus[dispatchManifest.statusID ?? ManifestStatus.POSTERGADO];
      setDispatchCodeError(
        `El manifiesto tiene status ${status}, el cual no es válido`
      );
      return;
    }
    if (
      dispatchManifest.masterShipmentAssociationList?.some(
        (m) => m.statusIDAssociate === ManifestStatus.GENERADO
      )
    ) {
      setDispatchCodeError(
        `El número de manifiesto ${dispatchCode} ya fue recepcionado`
      );
      return;
    }

    dispatch(setDispatchHeader(dispatchManifest));
    setShowDispatchData(true);
  };

  const handleReceiptSubmit = async () => {
    const userLogin = user.user?.userLogin;

    if (!userLogin) {
      alertService.error(
        "No hay un nombre de usuario asociado a la sesión actual"
      );
      return;
    }
    if (!dispatchHeader || !dispatchHeader.masterShipmentHeaderID) {
      alertService.error(
        "Debe seleccionar un despacho para crear la recepción"
      );
      return;
    }

    if (!receiptHeader) {
      const receipt = await createManifestHeader(
        {
          buCodeSource: dispatchHeader.buCodeSource,
          buSource: dispatchHeader.buSource,
          buCodeDestination: dispatchHeader.buCodeDestination,
          routeID: dispatchHeader.routeID,
          route: dispatchHeader.route,
          transportID: dispatchHeader.transportID,
          driver1ID: dispatchHeader.driver1ID,
          seal1: dispatchHeader.seal1,
          seal2: dispatchHeader.seal2,
          seal3: dispatchHeader.seal3,
          creationUser: userLogin,
          masterShipmentHeaderObservation:
            dispatchHeader.masterShipmentHeaderObservation,
          manifestTypeID: ManifestType.RECEPCION,
          statusID: ManifestStatus.POSTERGADO,
        },
        [dispatchHeader.masterShipmentHeaderID]
      );

      if (
        receipt.didError ||
        !receipt.model ||
        !receipt.model.masterShipmentHeaderID
      ) {
        alertService.error(
          "Hubo un error creando el recibo",
          receipt.errorMessage
        );
        return;
      }

      setEditMode(false);
      dispatch(setReceiptHeader(receipt.model));
    } else {
      const manifestHeader: ManifestHeaderUpdateInterface = {
        masterShipmentHeaderID: receiptHeader.masterShipmentHeaderID!,
        masterShipmentHeaderObservation: observation,
        updateUser: userLogin,
      };
      const updatedManifestHeader = await updateManifestHeader(manifestHeader);

      if (updatedManifestHeader.didError || !updatedManifestHeader.model) {
        alertService.error(
          "Hubo un error actualizando la recepción",
          updatedManifestHeader.errorMessage
        );
        return;
      }

      setEditMode(false);
      dispatch(
        setReceiptHeader({
          ...receiptHeader,
          masterShipmentHeaderObservation: observation,
        })
      );
    }
  };

  useEffect(() => {
    setDispatchCode(dispatchHeader?.masterShipmentHeaderCode ?? "");
  }, [dispatchHeader]);

  useEffect(() => {
    setEditMode(!receiptHeader);
  }, [receiptHeader]);

  return (
    <div className="flex flex-col bg-white rounded-lg border px-8 pb-6 pt-4">
      <div className="flex items-center justify-between gap-6">
        <div className="flex flex-1 gap-4 items-end">
          <div className="flex-1 max-w-[30rem]">
            <FormTextInput
              id="dispatchCode"
              name="dispatchCode"
              label="Código del Despacho"
              value={dispatchCode}
              error={dispatchCodeError}
              disabled={!!dispatchHeader}
              onChange={(e) => {
                setDispatchCode(e.target.value);
                setDispatchCodeError("");
              }}
              placeholder="Introduzca el código del despacho a recepcionar"
              maxLength={25}
            />
          </div>

          <div>
            <PrimaryButton
              disabled={loading || !!dispatchHeader}
              onClick={async () => {
                loaderService.start();
                await handleDispatchSearch();
                loaderService.stop();
              }}
              className="px-3 py-2"
            >
              <MagnifyingGlassIcon
                className="h-5 w-5 flex-none text-gray-200 mr-1"
                aria-hidden="true"
              />
              Buscar despacho
            </PrimaryButton>
          </div>
        </div>

        <div
          className={classNames(
            "flex items-center mt-6",
            !dispatchHeader && "hidden"
          )}
        >
          <p className="font-medium text-gray-700">
            Detalles del despacho{" "}
            <span className="text-indigo-500">
              {dispatchHeader?.masterShipmentHeaderCode}
            </span>
          </p>

          <button
            onClick={() => setShowDispatchData((s) => !s)}
            className="ml-4 p-3 rounded-full border bg-white hover:bg-gray-200 transition-colors"
          >
            <ChevronUpIcon
              className={classNames(
                "h-6 w-6 text-gray-400 transition-transform duration-700",
                showDispatchData ? "rotate-0" : "rotate-180"
              )}
            />
          </button>
        </div>
      </div>

      <div
        className={classNames(
          "overflow-hidden transition-[max-height,opacity] duration-700 ease-in-out",
          showDispatchData && dispatchHeader
            ? "max-h-screen opacity-100"
            : "max-h-0 opacity-0"
        )}
      >
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
          <div>
            <p className="block text-m leading-6 text-gray-800 truncate">
              Ruta:
            </p>
            <div className="text-gray-700 text-sm font-semibold">
              {dispatchHeader?.route}
            </div>
          </div>
          <div>
            <p className="block text-m leading-6 text-gray-800 truncate">
              Plataforma:
            </p>
            <div className="text-gray-700 text-sm font-semibold">
              {dispatchHeader?.buSource?.toUpperCase()}
            </div>
          </div>
          <div>
            <p className="block text-m leading-6 text-gray-800 truncate">
              Vehículo:
            </p>
            <div className="text-gray-700 text-sm font-semibold">
              {dispatchHeader?.transportPlate}
              {" - "}
              {dispatchHeader?.transportModelName}
            </div>
          </div>
          <div>
            <p className="block text-m leading-6 text-gray-800 truncate">
              Conductor:
            </p>
            <div className="text-gray-700 text-sm font-semibold">
              {dispatchHeader?.driver1IdentificationNumber}
              {" - "}
              {dispatchHeader?.driver1Name}
            </div>
          </div>
          <div className="col-span-1 md:col-span-2">
            <p className="block text-m leading-6 text-gray-800 truncate">
              Observación:
            </p>
            <div className="text-gray-700 text-sm font-semibold">
              {dispatchHeader?.masterShipmentHeaderObservation}
            </div>
          </div>
          <div>
            <p className="block text-m leading-6 text-gray-800 truncate">
              Precinto 1:
            </p>
            <div className="text-gray-700 text-sm font-semibold">
              {dispatchHeader?.seal1}
            </div>
          </div>
          <div>
            <p className="block text-m leading-6 text-gray-800 truncate">
              Precinto 2:
            </p>
            <div className="text-gray-700 text-sm font-semibold">
              {dispatchHeader?.seal2}
            </div>
          </div>
          <div>
            <p className="block text-m leading-6 text-gray-800 truncate">
              Precinto 3:
            </p>
            <div className="text-gray-700 text-sm font-semibold">
              {dispatchHeader?.seal3}
            </div>
          </div>
          <div>
            <p className="block text-m leading-6 text-gray-800 truncate">
              Total de guías / Total piezas:
            </p>
            <div className="text-gray-700 text-sm font-semibold">
              {totalDispatchShipments} /{" "}
              {dispatchHeader?.manifestDetail?.length ?? 0}
            </div>
          </div>
          <div>
            <p className="block text-m leading-6 text-gray-800 truncate">
              Total peso kg.:
            </p>
            <div className="text-gray-700 text-sm font-semibold">
              {(dispatchHeader?.manifestDetail?.reduce(
                (acumulador, objeto) => acumulador + objeto.physicalWeight,
                0
              ) ?? 0).toFixed(2)}
            </div>
          </div>
          <div>
            <p className="block text-m leading-6 text-gray-800 truncate">
              Fecha:
            </p>
            <div className="text-gray-700 text-sm font-semibold">
              {dispatchHeader?.manifestClosedDate
                ? moment(dispatchHeader.manifestClosedDate).format(
                    "DD-MM-YYYY hh:mm A"
                  )
                : ""}
            </div>
          </div>
        </div>

        <div className="flex flex-1 items-end justify-between mt-8">
          <div className="flex-1 max-w-[30rem]">
            {editMode && (
              <FormTextInput
                id="observation"
                name="observation"
                label="Observación"
                value={observation}
                onChange={(e) => setObservation(e.target.value)}
                placeholder="Introduzca la observación"
                maxLength={150}
              />
            )}

            {!editMode && (
              <>
                <p className="font-medium leading-6 text-gray-900">
                  Observación
                </p>
                <p className="mt-1 text-sm">{observation}</p>
              </>
            )}
          </div>

          <PrimaryButton
            disabled={loading}
            className={classNames("!px-6", !editMode && "hidden")}
            onClick={async () => {
              loaderService.start();
              await handleReceiptSubmit();
              loaderService.stop();
            }}
          >
            {receiptHeader ? "Guardar observaciones" : "Comenzar recepción"}
          </PrimaryButton>

          <PrimaryButton
            type="button"
            className={classNames("!px-6", editMode && "hidden")}
            onClick={() => setEditMode(true)}
          >
            Editar observaciones
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default ReceiptManifestForm;
