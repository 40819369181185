import { FC, useCallback, useEffect, useMemo, useState } from "react";
import HorizontalPadding from "../components/HorizontalPadding";
import DispatchManifestForm from "./Manifest/DispatchManifestForm";
import DispatchDetailForm from "./Manifest/DispatchDetailForm";
import DispatchDetailTable from "./Manifest/DispatchDetailTable";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { PrimaryButton, SecondaryButton } from "../components/Buttons";
import {
  addPendingDetails,
  addProcessedDetails,
  clearDispatchValues,
  removePendingDetails,
  removeProcessedDetails,
  setDispatchLoadFromDB,
} from "../store/slices/dispatch";
import {
  ManifestDetailSingleInterface,
  ManifestIncidence,
  ManifestStatus,
  ManifestType,
} from "../interfaces/ManifestInterface";
import {
  createManifestDetail,
  createObjectManifestDetail,
  generateManifest,
  updateManifestStatus,
  validationManifestDetail,
} from "../services/manifestServices";
import { alertService, loaderService } from "../services";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import ManifestInvalidDetailTable from "./Manifest/ManifestInvalidDetailTable";

const DispatchCreate: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = loaderService.useIsLoading();
  const user = useAppSelector((state) => state.user);
  const loadFromDB = useAppSelector((state) => state.dispatch.loadFromDB);
  const dispatchHeader = useAppSelector(
    (state) => state.dispatch.dispatchHeader
  );
  const pendingDetails = useAppSelector(
    (state) => state.dispatch.pendingDetails
  );
  const processedDetails = useAppSelector(
    (state) => state.dispatch.processedDetails
  );

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openFinishModal, setOpenFinishModal] = useState(false);
  const [openCleanWarning, setOpenCleanWarning] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openFinishWarningModal, setOpenFinishWarningModal] = useState(false);
  const [detailsBeingValidated, setDetailsBeingValidated] = useState<string[]>(
    []
  );
  const [openContinueLaterWarning, setOpenContinueLaterWarning] =
    useState(false);

  const processedDetailList = useMemo(() => {
    return Object.keys(processedDetails).map((id) => processedDetails[id]);
  }, [processedDetails]);

  const pendingDetailList = useMemo(() => {
    return Object.keys(pendingDetails).map((id) => pendingDetails[id]);
  }, [pendingDetails]);

  const incompleteShipments = useMemo(() => {
    const incompleteShipments: { [shipmentNumber: string]: number } = {};

    processedDetailList.forEach((detail) => {
      const shipmentNumber = detail.shipmentNumber;
      const totalPieces = detail.totalPieces;
      const totalPiecesSum = processedDetailList
        .concat(pendingDetailList)
        .reduce(
          (acc, d) => acc + (d.shipmentNumber === shipmentNumber ? 1 : 0),
          0
        );
      const diff = totalPieces - totalPiecesSum;

      if (diff !== 0) {
        incompleteShipments[shipmentNumber] = diff;
      }
    });

    return incompleteShipments;
  }, [pendingDetailList, processedDetailList]);

  const addDetailsBeingValidated = (
    details: ManifestDetailSingleInterface[]
  ) => {
    setDetailsBeingValidated((oldDetails) =>
      oldDetails.concat(
        details.map((d) => `${d.shipmentNumber}-${d.pieceNumber}`)
      )
    );
  };

  const removeDetailsBeingValidated = (
    details: ManifestDetailSingleInterface[]
  ) => {
    setDetailsBeingValidated((oldDetails) =>
      oldDetails.filter(
        (o) =>
          !details
            .map((d) => `${d.shipmentNumber}-${d.pieceNumber}`)
            .some((d) => d === o)
      )
    );
  };

  const processDetails = useCallback(
    async (details: ManifestDetailSingleInterface[]) => {
      const userLogin = user.user?.userLogin;
      const buCode = user.businessUnit?.code;
      const masterShipmentHeaderID = dispatchHeader?.masterShipmentHeaderID;

      if (!userLogin) {
        alertService.error(
          "No hay un nombre de usuario asociado a la sesión actual"
        );
        return;
      }
      if (!buCode) {
        alertService.error(
          "No hay una tienda asociada a la sesión del usuario"
        );
        return;
      }
      if (!masterShipmentHeaderID) {
        alertService.error(
          "Se necesita crear el manifiesto del despacho antes de agregar piezas"
        );
        return;
      }

      // Validate those details again
      addDetailsBeingValidated(details);
      const validations = await validationManifestDetail(
        details,
        buCode,
        ManifestType.DESPACHO
      );

      if (
        validations.didError ||
        !validations.model ||
        validations.model.length === 0
      ) {
        alertService.error(
          "Hubo un error validando la pieza",
          validations.errorMessage
        );
        removeDetailsBeingValidated(details);
        return;
      }

      const validatedDetails = details.map((d, i) => ({
        ...d,
        incidence: validations.model![i].incidence,
        errors: validations.model![i].errors,
      }));

      // Re-add invalid details
      const newInvalidDetails = validatedDetails.filter(
        (d) => d.incidence !== ManifestIncidence.NO_ERROR
      );

      dispatch(removePendingDetails(newInvalidDetails));
      dispatch(addProcessedDetails(newInvalidDetails));

      // Try to add the successful details to the manifest
      const newValidDetails = validatedDetails.filter(
        (d) => d.incidence === ManifestIncidence.NO_ERROR
      );
      if (newValidDetails.length === 0) {
        removeDetailsBeingValidated(details);
        return;
      }

      const manifestDetails = createObjectManifestDetail(
        newValidDetails,
        masterShipmentHeaderID,
        userLogin,
        buCode,
        false
      );
      const filteredManifestDetails = manifestDetails.filter(
        (d) => !d.containerDetailID
      );
      const insertedDetail = await createManifestDetail(
        filteredManifestDetails
      );

      if (insertedDetail.didError || insertedDetail.model === null) {
        alertService.error(
          "Hubo un error insertando las piezas al manifiesto",
          insertedDetail.errorMessage
        );
        const newInvalidDetails = newValidDetails.map((d) => ({
          ...d,
          incidence: ManifestIncidence.ERROR,
          errors: insertedDetail.errorMessage,
        }));
        removeDetailsBeingValidated(details);
        dispatch(removePendingDetails(newInvalidDetails));
        dispatch(addProcessedDetails(newInvalidDetails));
        return;
      }

      let index = 0;
      const completeDetails = newValidDetails.map((d) => ({
        ...d,
        containerDetailID:
          d.containerDetailID ??
          insertedDetail.model![index++].containerDetailID,
      }));
      removeDetailsBeingValidated(details);
      dispatch(removePendingDetails(completeDetails));
      dispatch(addProcessedDetails(completeDetails));

      return completeDetails;
    },
    [dispatch, dispatchHeader, user]
  );

  const reProcessDetails = async (
    detailList: ManifestDetailSingleInterface[]
  ) => {
    const invalidDetails = detailList.filter(
      (d) =>
        d.incidence === ManifestIncidence.CRITIC_ERROR ||
        d.incidence === ManifestIncidence.NOT_VALIDATED
    );

    if (invalidDetails.length === 0) {
      return;
    }

    // Pass invalid details from processed to pending
    dispatch(removeProcessedDetails(invalidDetails));
    dispatch(
      addPendingDetails(
        invalidDetails.map((d) => ({
          ...d,
          incidence: ManifestIncidence.NOT_VALIDATED,
          errors: "",
        }))
      )
    );

    return await processDetails(invalidDetails);
  };

  const reProcessAllDetails = async () => {
    await reProcessDetails(
      processedDetailList
        .concat(pendingDetailList)
        .filter(
          (d) =>
            !detailsBeingValidated.some(
              (v) => v === `${d.shipmentNumber}-${d.pieceNumber}`
            )
        )
    );
  };

  const handleContinueLater = async () => {
    if (pendingDetailList.length !== 0) {
      alertService.error(
        "Todavía hay piezas procesándose, espere un momento..."
      );
      return;
    }

    const invalidDetails = processedDetailList.filter(
      (d) => d.incidence !== ManifestIncidence.NO_ERROR
    );
    if (invalidDetails.length === 0) {
      navigate("/dispatch");
      return;
    }

    const newProcessedDetails = await reProcessDetails(invalidDetails);

    if (!newProcessedDetails) {
      setOpenContinueLaterWarning(true);
      return;
    }

    const newInvalidDetails = newProcessedDetails.filter(
      (d) => d.incidence !== ManifestIncidence.NO_ERROR
    );
    if (newInvalidDetails.length === 0) {
      navigate("/dispatch");
      return;
    }

    setOpenContinueLaterWarning(true);
  };

  const handleOnFinish = () => {
    if (pendingDetailList.length !== 0) {
      alertService.error(
        "Todavía hay piezas procesándose, espere un momento..."
      );
      return;
    }

    if (processedDetailList.length === 0) {
      alertService.error("No se puede guardar un manifiesto sin detalles");
      return;
    }

    const invalidDetails = processedDetailList.filter(
      (d) => d.incidence !== ManifestIncidence.NO_ERROR
    );
    if (invalidDetails.length !== 0) {
      setOpenErrorModal(true);
      return;
    }

    if (Object.keys(incompleteShipments).length > 0) {
      setOpenFinishWarningModal(true);
      return;
    }

    setOpenFinishModal(true);
  };

  const handleClean = async () => {
    const userLogin = user.user?.userLogin;
    const masterShipmentHeaderID = dispatchHeader?.masterShipmentHeaderID;

    if (!masterShipmentHeaderID) {
      dispatch(clearDispatchValues());
      return;
    }
    if (!userLogin) {
      alertService.error(
        "No hay un nombre de usuario asociado a la sesión actual"
      );
      return;
    }

    const response = await updateManifestStatus(
      masterShipmentHeaderID,
      ManifestStatus.INACTIVO,
      userLogin
    );

    if (response.didError) {
      alertService.error(
        "Hubo un error eliminando el manifiesto",
        response.errorMessage
      );
      return;
    }

    dispatch(clearDispatchValues());
  };

  const finish = async () => {
    const userLogin = user.user?.userLogin;
    const masterShipmentHeaderID = dispatchHeader?.masterShipmentHeaderID;

    if (!userLogin) {
      alertService.error(
        "No hay un nombre de usuario asociado a la sesión actual"
      );
      return;
    }
    if (!masterShipmentHeaderID) {
      alertService.error(
        "Parece que no hay ningún manifiesto de despacho cargado"
      );
      return;
    }

    const result = await generateManifest(
      masterShipmentHeaderID,
      userLogin,
      dispatchHeader.masterShipmentHeaderObservation ?? ""
    );
    if (result.didError) {
      alertService.error(
        "Hubo un error cerrando el manifiesto",
        result.errorMessage
      );
      return;
    }

    setOpenSuccessModal(true);
  };

  useEffect(() => {
    if (loadFromDB) {
      dispatch(setDispatchLoadFromDB(false));
      if (pendingDetailList.length > 0) {
        processDetails(pendingDetailList);
      }
    }
  }, [loadFromDB, pendingDetailList, processDetails, dispatch]);

  return (
    <main className="lg:pl-72 pb-32">
      <div className="py-6 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32">
        <header className="ml-4 text-2xl font-bold text-gray-700 ">
          Crear Despacho
        </header>
      </div>

      <HorizontalPadding paddingTop>
        <div className="flex flex-col gap-6">
          <DispatchManifestForm />

          {dispatchHeader && (
            <>
              <DispatchDetailForm
                addDetailsBeingValidated={addDetailsBeingValidated}
                removeDetailsBeingValidated={removeDetailsBeingValidated}
              />

              <DispatchDetailTable
                detailsBeingValidated={detailsBeingValidated}
                addDetailsBeingValidated={addDetailsBeingValidated}
                removeDetailsBeingValidated={removeDetailsBeingValidated}
                onRetry={reProcessAllDetails}
                onRetryDetail={async (detail) => {
                  await reProcessDetails([detail]);
                }}
              />
            </>
          )}

          <div className="flex justify-between">
            <SecondaryButton
              disabled={loading || detailsBeingValidated.length > 0}
              onClick={() => setOpenCleanWarning(true)}
            >
              Limpiar todo
            </SecondaryButton>

            <div className="flex gap-6 items-center">
              <SecondaryButton
                disabled={loading || detailsBeingValidated.length > 0}
                onClick={handleContinueLater}
              >
                Continuar luego
              </SecondaryButton>

              <PrimaryButton
                disabled={loading || detailsBeingValidated.length > 0}
                onClick={handleOnFinish}
              >
                Finalizar y cerrar
              </PrimaryButton>
            </div>
          </div>
        </div>
      </HorizontalPadding>

      <Modal
        openModal={openContinueLaterWarning}
        setOpenModal={setOpenContinueLaterWarning}
      >
        <div className="flex flex-col gap-4 items-center">
          <div className="flex flex-col items-center">
            <ExclamationTriangleIcon className="text-orange-500 h-28 w-28 mx-auto opacity-90" />
            <h2 className="text-xl font-semibold leading-7 text-gray-700 text-center mt-2">
              Continuar luego
            </h2>
          </div>

          <div className="flex flex-col gap-1 max-w-[32rem]">
            <p className="text-gray-600 text-center whitespace-pre-line">
              Las siguientes piezas presentan un error de validación y si
              quieres guardar para continuar luego, estas no serán incluidas en
              el borrador:
            </p>
          </div>

          <ManifestInvalidDetailTable details={processedDetailList} />

          <div className="flex w-full flex-row gap-3 items-center justify-between mt-4">
            <SecondaryButton
              onClick={() => setOpenContinueLaterWarning(false)}
              className="px-4 py-2"
            >
              Cancelar
            </SecondaryButton>

            <PrimaryButton
              onClick={() => navigate("/dispatch")}
              className="px-4 py-2"
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      <Modal openModal={openFinishModal} setOpenModal={setOpenFinishModal}>
        <div className="flex flex-col gap-4 max-w-md mx-auto items-center">
          <div className="flex flex-col items-center">
            <QuestionMarkCircleIcon className="text-blue-500 h-28 w-28 mx-auto opacity-90" />
            <h2 className="text-xl font-semibold leading-7 text-gray-700 text-center mt-2">
              Creación de despacho
            </h2>
          </div>

          <div className="flex flex-col gap-1">
            <p className="text-gray-600 text-center whitespace-pre-line">
              ¿Está seguro de finalizar y cerrar el manifiesto?
            </p>
          </div>

          <div className="flex w-full flex-row gap-3 items-center justify-between mt-4">
            <SecondaryButton
              onClick={() => setOpenFinishModal(false)}
              className="px-4 py-2"
            >
              Cancelar
            </SecondaryButton>

            <PrimaryButton
              disabled={loading}
              onClick={async () => {
                loaderService.start();
                await finish();
                loaderService.stop();
                setOpenFinishModal(false);
              }}
              className="px-4 py-2"
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      <Modal
        openModal={openSuccessModal}
        setOpenModal={(open) => {
          dispatch(clearDispatchValues());
          setOpenSuccessModal(open);
          navigate("/dispatch");
        }}
      >
        <div className="flex flex-col gap-4 max-w-md mx-auto items-center">
          <div className="flex flex-col items-center">
            <CheckCircleIcon className="text-green-500 h-28 w-28 mx-auto opacity-90" />
            <h2 className="text-xl font-semibold leading-7 text-gray-700 text-center mt-2">
              Creación de despacho
            </h2>
          </div>

          <div className="flex flex-col gap-1">
            <p className="text-gray-600 text-center whitespace-pre-line">
              El manifiesto '{dispatchHeader?.masterShipmentHeaderCode}' ha sido
              generado exitosamente
            </p>
          </div>

          <div className="flex w-full flex-row gap-3 items-center justify-center mt-4">
            <PrimaryButton
              onClick={() => {
                dispatch(clearDispatchValues());
                setOpenSuccessModal(false);
                navigate("/dispatch");
              }}
              className="px-4 py-2"
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      <Modal openModal={openCleanWarning} setOpenModal={setOpenCleanWarning}>
        <div className="flex flex-col gap-4 max-w-md mx-auto items-center">
          <div className="flex flex-col items-center">
            <QuestionMarkCircleIcon className="text-blue-500 h-28 w-28 mx-auto opacity-90" />
            <h2 className="text-xl font-semibold leading-7 text-gray-700 text-center mt-2">
              Borrar datos del despacho
            </h2>
          </div>

          <div className="flex flex-col gap-1">
            <p className="text-gray-600 text-center whitespace-pre-line">
              ¿Estás seguro de querer borrar los datos del manifiesto? Esta
              acción es irreversible
            </p>
          </div>

          <div className="flex w-full flex-row gap-3 items-center justify-between mt-4">
            <SecondaryButton
              onClick={() => setOpenCleanWarning(false)}
              className="px-4 py-2"
            >
              Cancelar
            </SecondaryButton>

            <PrimaryButton
              onClick={async () => {
                await handleClean();
                setOpenCleanWarning(false);
              }}
              className="px-4 py-2"
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      <Modal
        openModal={openFinishWarningModal}
        setOpenModal={setOpenFinishWarningModal}
      >
        <div className="flex flex-col gap-4 items-center">
          <div className="flex flex-col items-center">
            <ExclamationTriangleIcon className="text-orange-500 h-28 w-28 mx-auto opacity-90" />
            <h2 className="text-xl font-semibold leading-7 text-gray-700 text-center mt-2">
              Creación de despacho
            </h2>
          </div>

          <div className="flex flex-col gap-1 max-w-[28rem]">
            <p className="text-gray-600 text-center whitespace-pre-line">
              Las siguientes guías no tienen todas sus piezas completas. ¿Está
              seguro que desea continuar con el cierre del manifiesto?
            </p>
          </div>

          <div className="flex flex-col text-gray-700">
            {Object.keys(incompleteShipments).map((shipmentNumber) => (
              <p key={shipmentNumber} className="">
                Guía <span className="font-semibold">{shipmentNumber}</span>:
                Piezas
                {incompleteShipments[shipmentNumber] > 0
                  ? " faltantes "
                  : " sobrantes "}
                <span className="font-semibold">
                  {incompleteShipments[shipmentNumber]}
                </span>
              </p>
            ))}
          </div>

          <div className="flex w-full flex-row gap-3 items-center justify-between mt-4">
            <SecondaryButton
              onClick={() => setOpenFinishWarningModal(false)}
              className="px-4 py-2"
            >
              Cancelar
            </SecondaryButton>

            <PrimaryButton
              disabled={loading}
              onClick={async () => {
                loaderService.start();
                await finish();
                loaderService.stop();
                setOpenFinishWarningModal(false);
                setOpenFinishModal(false);
              }}
              className="px-4 py-2"
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      <Modal openModal={openErrorModal} setOpenModal={setOpenErrorModal}>
        <div className="flex flex-col gap-4 items-center">
          <div className="flex flex-col items-center">
            <ExclamationTriangleIcon className="text-orange-500 h-28 w-28 mx-auto opacity-90" />
            <h2 className="text-xl font-semibold leading-7 text-gray-700 text-center mt-2">
              Creación de despacho
            </h2>
          </div>

          <div className="flex flex-col gap-1 max-w-[20rem]">
            <p className="text-gray-600 text-center whitespace-pre-line">
              Aún hay piezas inválidas. Retírelas antes de finalizar el
              manifiesto
            </p>
          </div>

          <ManifestInvalidDetailTable details={processedDetailList} />

          <div className="flex w-full flex-row gap-3 items-center justify-center mt-4">
            <PrimaryButton
              onClick={() => setOpenErrorModal(false)}
              className="px-4 py-2"
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </main>
  );
};

export default DispatchCreate;
