import { FC } from "react";
import Modal from "./Modal";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { PrimaryButton, SecondaryButton } from "./Buttons";

interface WarningModalProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
  secondaryButtonText?: string;
  primaryButtonText?: string;
  onSecondaryButton?: () => void;
  onPrimaryButton: () => void;
}

const WarningModal: FC<WarningModalProps> = ({
  openModal,
  setOpenModal,
  message,
  secondaryButtonText = "Cancelar",
  primaryButtonText = "Aceptar",
  onSecondaryButton = () => setOpenModal(false),
  onPrimaryButton,
}) => {
  return (
    <Modal openModal={openModal} setOpenModal={setOpenModal}>
      <div
        className="flex flex-col items-center justify-center"
        style={{ maxWidth: "20rem" }}
      >
        <div className="flex flex-col items-center justify-center w-full">
          <ExclamationTriangleIcon className="w-32 h-32" />
        </div>
        <p className="mt-2 text-lg text-center text-gray-700">{message}</p>
        <div className="mt-4 flex flex-row justify-center gap-12">
          <SecondaryButton className="px-4" onClick={onSecondaryButton}>
            {secondaryButtonText}
          </SecondaryButton>

          <PrimaryButton className="px-4" onClick={onPrimaryButton}>
            {primaryButtonText}
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default WarningModal;
