import api from "./api";
import { ResponseInterface } from "../interfaces";
import {
  LiabilityWaiverDTO,
  LiabilityWaiverGet,
  LiabilityWaiverGetResponse,
  LiabilityWaiverUpdateRequest,
} from "../interfaces/Dtos/liabilityWaiverDTO";
import { base64ToFile } from "../utils";

const baseURLAPI = `${process.env.REACT_APP_API_HOST}/LiabilityWaiver`;

// Add AM to service
export const saveLiabilityWaver = async (
  fingerprint: string | undefined,
  photoAttachments: string[] | undefined,
  shipmentHeaderIDList: string[],
  isPhysicalLiabilityWaiver: boolean,
  signatoryName: string,
  signatoryIdentification: string,
  creationUser: string,
  statusID: number,
  observation?: string
): Promise<ResponseInterface<boolean>> => {
  const formData = new FormData();
  if (fingerprint && fingerprint !== "") {
    const fingerprintPNG =
      base64ToFile(
        fingerprint,
        "image/png",
        `fingerprint.png`
      );
    const fingerprintBlob = new Blob([fingerprintPNG], { type: "image/png" });
    formData.append("Fingerprint", fingerprintBlob, "fingerprint-LW.png");
  }
  if (photoAttachments && photoAttachments.length > 0) {
    for (let i = 0; i < photoAttachments.length; i++) {
      const attachmentPNG = base64ToFile(
        photoAttachments[i],
        "image/png",
        "attachment-LW.png"
      )
      formData.append(`PhotoAttachment${[i + 1]}`, attachmentPNG);
    }
  }
  formData.append("ShipmentHeaderIDList", shipmentHeaderIDList.join(","));
  formData.append(
    "IsPhysicalLiabilityWaiver",
    isPhysicalLiabilityWaiver.toString()
  );
  formData.append("SignatoryName", signatoryName);
  formData.append("SignatoryIdentification", signatoryIdentification);
  formData.append("CreationUser", creationUser);
  formData.append("StatusID", statusID.toString());
  if (observation) formData.append("Observation", observation);

  try {
    const response = await api.post(baseURLAPI, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    return (error as any)?.response?.data;
  }
};

export const getLiabilityWaiver = async (
  shipmentHeaderID: string
): Promise<ResponseInterface<LiabilityWaiverGetResponse>> => {
  try {
    const response = await api.get(baseURLAPI, {
      params: {
        shipmentHeaderID,
      },
    });

    const data = response.data as ResponseInterface<LiabilityWaiverGet>;

    let attachmentList: string[] = [];
    if (data.model?.shipperAttachmentIDs !== null)
      attachmentList = data.model?.shipperAttachmentIDs.split(",") || [];

    let shipperFingerprintAttchID = data.model?.shipperFingerprintAttchID;

    if (shipperFingerprintAttchID === "00000000-0000-0000-0000-000000000000") {
      shipperFingerprintAttchID = null;
    }

    const newResponse =
      data as unknown as ResponseInterface<LiabilityWaiverGetResponse>;

    if (newResponse.model) {
      newResponse.model.shipperAttachmentIDs = attachmentList;
      newResponse.model.shipperFingerprintAttchID = shipperFingerprintAttchID!;
    }

    return newResponse;
  } catch (error) {
    return (error as any)?.response?.data;
  }
};

export const updateLiabilityWaiver = async (
  request: LiabilityWaiverUpdateRequest
): Promise<ResponseInterface<boolean>> => {
  const formData = new FormData();
  formData.append("LiabilityWaiverID", request.liabilityWaiverID);
  formData.append("UpdateUser", request.updateUser);
  if (request.shipperFingerPrintAttchID)
    formData.append(
      "ShipperFingerPrintAttchID",
      request.shipperFingerPrintAttchID
    );
  if (request.isPhysicalLiabilityWaiver !== undefined)
    formData.append(
      "IsPhysicalLiabilityWaiver",
      request.isPhysicalLiabilityWaiver.toString()
    );
  if (request.signatoryName)
    formData.append("SignatoryName", request.signatoryName);
  if (request.signatoryIdentification)
    formData.append("SignatoryIdentification", request.signatoryIdentification);
  if (request.observation) formData.append("Observation", request.observation);
  if (request.fingerprintToInsert)
    formData.append("FingerprintToInsert", request.fingerprintToInsert);
  if (request.attachmentToInsert1)
    formData.append("AttachmentToInsert1", request.attachmentToInsert1);
  if (request.attachmentToInsert2)
    formData.append("AttachmentToInsert2", request.attachmentToInsert2);
  if (request.attachmentToInsert3)
    formData.append("AttachmentToInsert3", request.attachmentToInsert3);
  if (request.attachmentToInsert4)
    formData.append("AttachmentToInsert4", request.attachmentToInsert4);
  if (request.attachmentToInsert5)
    formData.append("AttachmentToInsert5", request.attachmentToInsert5);
  if (request.attachmentToUpdate1)
    formData.append("AttachmentToUpdate1", request.attachmentToUpdate1);
  if (request.attachmentToUpdate2)
    formData.append("AttachmentToUpdate2", request.attachmentToUpdate2);
  if (request.attachmentToUpdate3)
    formData.append("AttachmentToUpdate3", request.attachmentToUpdate3);
  if (request.attachmentToUpdate4)
    formData.append("AttachmentToUpdate4", request.attachmentToUpdate4);
  if (request.attachmentToUpdate5)
    formData.append("AttachmentToUpdate5", request.attachmentToUpdate5);

  try {
    const response = await api.put(baseURLAPI, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    return (error as any)?.response?.data;
  }
};

export const searchLiabilityWaiver = async ({
  liabilityWaverID = "",
  shipmentHeaderID = "",
  shipperFingerPrintAttchID = "",
}: {
  liabilityWaverID?: string;
  shipmentHeaderID?: string;
  shipperFingerPrintAttchID?: string;
}): Promise<ResponseInterface<Array<LiabilityWaiverDTO>>> => {
  const uri = `${baseURLAPI}/Search`;
  try {
    const response = await api.get(uri, {
      params: { liabilityWaverID, shipmentHeaderID, shipperFingerPrintAttchID },
    });
    return response.data;
  } catch (error) {
    return (error as any)?.response?.data;
  }
};
