import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import { debounce } from "lodash";
import classNames from "classnames";
import { LinkText } from "../Buttons";
import LoadingIcon from "../LodingIcon";
import { Dialog } from "@headlessui/react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useAppSelector } from "../../store/hooks";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { loaderService, saveAccount, updateAccount } from "../../services";
import { FormRadioGroup, FormSelect, FormTextInput } from "../FormFields";
import { TaxIdentificationTypeInterface } from "../../interfaces/TaxIdentificationTypeInterface";
import {
  AccountInterface,
  AccountRequestInterface,
  CountryInterface,
} from "../../interfaces";
import {
  validatePhone,
  validateFullname,
  extractFirstLetter,
  validateEmailRegex,
  validateEmailDomain,
  validateEmailDisposable,
  validateIdentityDocument,
} from "../../utils";

// Constants
const VEN_CODE = "VEN";
//const idOptions = ["V", "J", "E", "G", "P"];
export const emptyPhonePrefix = { value: "", name: "FIJO" };
export const phonePrefixOptions = [
  {
    value: "412",
    name: "412",
  },
  {
    value: "424",
    name: "424",
  },
  {
    value: "414",
    name: "414",
  },
  {
    value: "426",
    name: "426",
  },
  {
    value: "416",

    name: "416",
  },
];

export interface AccountFormValues {
  fullName: string;
  email: string;
  address: string;

  // First phone number
  country: CountryInterface;
  phonePrefix: {
    value: string;
    name: string;
  };
  phone: string;

  // Second phone number
  country2?: CountryInterface;
  phonePrefix2?: {
    value: string;
    name: string;
  };
  phone2?: string;
  idType: TaxIdentificationTypeInterface;
  idNumber: string;
}

interface FormProps {
  formik: FormikProps<AccountFormValues>;
  loader: boolean;
  isUpdate: boolean;
  domainError: string;
  phoneOptions: { value: string; name: string }[];
  ignoreTouched: boolean;
  showSecondPhone: boolean;
  showFixedPhone: boolean;
  disabledIdDocument?: boolean;
  setShowSecondPhone: (show: boolean) => void;
  handleCloseForm: () => void;
}
const Form: FC<FormProps> = ({
  formik,
  loader,
  isUpdate,
  domainError,
  phoneOptions,
  ignoreTouched,
  showSecondPhone,
  showFixedPhone,
  disabledIdDocument,
  setShowSecondPhone,
  handleCloseForm,
}) => {
  const idOptions = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );
  const countries = useAppSelector((state) => state.inmutable.countries);

  // Preselect phone prefix only for Venezuela
  const handleCountrySelection = (
    country: CountryInterface,
    formik: FormikProps<AccountFormValues>,
    secondPhone: boolean = false
  ) => {
    const suffix = secondPhone ? "2" : "";

    formik.setFieldValue(`country${suffix}`, country);
    if (country.countryCodeIso === VEN_CODE) {
      formik.setFieldValue(`phonePrefix${suffix}`, phonePrefixOptions[0]);
    } else {
      formik.setFieldValue(`phonePrefix${suffix}`, "");
    }
  };

  const phoneRealOptions = useMemo(() => {
    if (
      showFixedPhone ||
      formik.values.idType.abreviationName === "J-" ||
      formik.values.idType.abreviationName === "G-"
    ) {
      return [...phonePrefixOptions, emptyPhonePrefix];
    }

    return phonePrefixOptions;
  }, [showFixedPhone, formik.values.idType.abreviationName]);

  useEffect(() => {
    if (ignoreTouched) {
      // Set all touched
      formik.setTouched({
        fullName: true,
        email: true,
        address: true,
        phone: true,
        country2: true,
        phonePrefix2: true,
        phone2: true,
        idNumber: true,
      });
    }

    // Only the ignoreTouched is required to update the touched values, not
    // the entire formik object
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ignoreTouched]);

  return (
    <form
      className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl group"
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
        {/* Header */}
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <Dialog.Title className="text-lg font-semibold leading-6 text-gray-900">
              {isUpdate ? "Editar cliente" : "Crear cliente"}
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={handleCloseForm}
              >
                <span className="absolute -inset-2.5" />
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>

        {/* Body */}
        <div className="relative mt-6 flex-1 px-4 sm:px-6">
          <div className="space-y-12">
            <div className="pb-12">
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {/* Full name */}
                <div className="col-span-full">
                  <FormTextInput
                    label="Nombre completo"
                    isRequiredLabel
                    name="fullName"
                    type="text"
                    value={formik.values.fullName}
                    error={
                      formik.touched.fullName && formik.errors.fullName
                        ? formik.errors.fullName
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>

                {/* Email */}
                <div className="flex flex-col relative col-span-full">
                  <FormTextInput
                    label="Correo electrónico"
                    isRequiredLabel
                    name="email"
                    type="email"
                    value={formik.values.email}
                    error={formik.errors.email || domainError}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="pr-10"
                  />

                  <div
                    className={classNames(
                      "absolute right-0",
                      formik.errors.email || domainError
                        ? "bottom-9"
                        : "bottom-2"
                    )}
                  >
                    {!!loader && <LoadingIcon size="1.35rem" />}
                  </div>
                </div>

                {/* Address */}
                <div className="col-span-full">
                  <FormTextInput
                    name="address"
                    label="Dirección fiscal"
                    value={formik.values.address}
                    error={
                      formik.touched.address && formik.errors.address
                        ? formik.errors.address
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>

                {/* Document */}
                <div className="col-span-full ">
                  <fieldset className="rounded-md border border-gray-200 p-5">
                    <legend className="text-m font-medium leading-6 text-gray-900">
                      Cédula / RIF
                    </legend>
                    <FormRadioGroup
                      n={3}
                      disabled={disabledIdDocument || isUpdate}
                      label="Tipo de documento"
                      name="idType"
                      id="radiogroup-id"
                      options={idOptions}
                      selected={formik.values.idType}
                      optionString={(option) =>
                        extractFirstLetter(option.abreviationName)
                      }
                      onSelectOption={(val) => {
                        formik.setFieldValue("idType", val);
                        formik.setFieldValue("idNumber", "");
                      }}
                    />
                    <div className="col-span-full m-1">
                      <FormTextInput
                        label={"Número de cédula / RIF"}
                        isRequiredLabel
                        disabled={disabledIdDocument || isUpdate}
                        name="idNumber"
                        value={formik.values.idNumber}
                        error={
                          formik.touched.idNumber && formik.errors.idNumber
                            ? formik.errors.idNumber
                            : ""
                        }
                        onChange={(e) => {
                          let re: RegExp;
                          if (formik.values.idType.abreviationName !== "P-") {
                            // Only numbers
                            re = /^[0-9\b]{0,10}$/;
                          } else {
                            // Only numbers and letters
                            re = /^[a-zA-Z0-9\b]{0,10}$/;
                          }

                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            formik.handleChange(e);
                          }
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </fieldset>
                </div>

                {/* Phone number */}
                <div className="col-span-full ">
                  <fieldset className="rounded-md border border-gray-200 p-5">
                    <legend className="text-m font-medium leading-6 text-gray-900">
                      Teléfono
                    </legend>

                    <div className="flex flex-col flex-1 gap-2">
                      <div>
                        <FormSelect
                          label="País"
                          selected={formik.values.country}
                          name="country"
                          onBlur={formik.handleBlur}
                          options={countries}
                          error={
                            formik.touched.country && formik.errors.country
                              ? formik.errors.country
                              : ""
                          }
                          optionString={(country) =>
                            country.countryName +
                            (country.countryCodeIso !== VEN_CODE &&
                            !!country.countryPhoneAccessCode
                              ? ` (${country.countryPhoneAccessCode})`
                              : "")
                          }
                          onSelectOption={(country) =>
                            handleCountrySelection(country, formik)
                          }
                          onSearch={(option, search) => {
                            return (
                              option.countryName
                                .toLowerCase()
                                .split(" ")
                                .some((s) => s.startsWith(search)) ||
                              !!option.countryPhoneAccessCode
                                ?.slice(1)
                                .startsWith(search)
                            );
                          }}
                        />
                      </div>

                      <div>
                        <FormRadioGroup
                          n={3}
                          label="Operadora"
                          name="phonePrefix"
                          id="radiogroup-phone"
                          options={phoneRealOptions}
                          selected={formik.values.phonePrefix}
                          optionString={(option) => option.name}
                          onSelectOption={(val) =>
                            formik.setFieldValue("phonePrefix", val)
                          }
                          className={classNames(
                            "p-2",
                            formik.values.country.countryCodeIso === VEN_CODE
                              ? "visible"
                              : "hidden"
                          )}
                        />
                      </div>

                      <div>
                        <FormTextInput
                          label={"Número"}
                          isRequiredLabel
                          name="phone"
                          type="text"
                          value={formik.values.phone}
                          error={
                            formik.touched.phone && formik.errors.phone
                              ? formik.errors.phone
                              : ""
                          }
                          maxLength={
                            formik.values.country.countryCodeIso === VEN_CODE &&
                            !!formik.values.phonePrefix.value
                              ? 7
                              : 10
                          }
                          onChange={(e) => {
                            // Only numbers
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              formik.handleChange(e);
                            }
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>
                  </fieldset>

                  {/* Add second phone number */}
                  <div
                    className={classNames(
                      "flex col-span-full justify-end",
                      showSecondPhone && "hidden"
                    )}
                  >
                    <LinkText
                      text="Añadir segundo teléfono"
                      onClick={() => setShowSecondPhone(true)}
                    />
                  </div>
                </div>

                {/* Second phone number */}
                <div
                  className={classNames(
                    "col-span-full",
                    !showSecondPhone && "hidden"
                  )}
                >
                  <fieldset className="rounded-md border border-gray-200 p-5">
                    <legend className="text-m font-medium leading-6 text-gray-900">
                      Segundo Teléfono
                    </legend>

                    <div className="flex flex-col flex-1 gap-2">
                      <div>
                        <FormSelect
                          label="País"
                          selected={formik.values.country2}
                          name="country2"
                          onBlur={formik.handleBlur}
                          options={countries}
                          error={
                            formik.touched.country2 && formik.errors.country2
                              ? formik.errors.country2
                              : ""
                          }
                          optionString={(country) =>
                            country.countryName +
                            (country.countryCodeIso !== VEN_CODE &&
                            !!country.countryPhoneAccessCode
                              ? ` (${country.countryPhoneAccessCode})`
                              : "")
                          }
                          onSelectOption={(country) =>
                            handleCountrySelection(country, formik, true)
                          }
                          onSearch={(option, search) => {
                            return (
                              option.countryName
                                .toLowerCase()
                                .split(" ")
                                .some((s) => s.startsWith(search)) ||
                              !!option.countryPhoneAccessCode
                                ?.slice(1)
                                .startsWith(search)
                            );
                          }}
                        />
                      </div>

                      <div>
                        <FormRadioGroup
                          n={3}
                          label="Operadora"
                          name="phonePrefix2"
                          id="radiogroup-phone"
                          options={phoneRealOptions}
                          selected={formik.values.phonePrefix2}
                          optionString={(option) => option.name}
                          onSelectOption={(val) =>
                            formik.setFieldValue("phonePrefix2", val)
                          }
                          className={classNames(
                            "p-2",
                            formik.values.country2?.countryCodeIso === VEN_CODE
                              ? "visible"
                              : "hidden"
                          )}
                        />
                      </div>

                      <div>
                        <FormTextInput
                          label={"Número"}
                          name="phone2"
                          type="text"
                          value={formik.values.phone2}
                          error={
                            formik.touched.phone2 && formik.errors.phone2
                              ? formik.errors.phone2
                              : ""
                          }
                          maxLength={
                            formik.values.country2?.countryCodeIso ===
                              VEN_CODE && !!formik.values.phonePrefix2?.value
                              ? 7
                              : 10
                          }
                          onChange={(e) => {
                            // Only numbers
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              formik.handleChange(e);
                            }
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          onClick={handleCloseForm}
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
        >
          Guardar
        </button>
      </div>
    </form>
  );
};

interface AccountFormProps {
  initialValues: AccountFormValues;
  accountItem?: AccountInterface;
  isUpdate?: boolean;
  ignoreTouched?: boolean;
  disabledIdDocument?: boolean;
  setOpenForm: (open: boolean) => void;
  setSelectedItem: (account: AccountInterface) => void;
}
const AccountForm: FC<AccountFormProps> = ({
  initialValues,
  accountItem,
  isUpdate,
  ignoreTouched,
  disabledIdDocument = false,
  setOpenForm,
  setSelectedItem,
}) => {
  const [lastEmailValidation, setLastEmailValidation] = useState("");
  const emailValidationRef = useRef(lastEmailValidation);
  const countries = useAppSelector((state) => state.inmutable.countries);
  const userLogged = useAppSelector((state) => state.user.user);
  const [loader, setLoader] = useState(false);
  const [domainError, setDomainError] = useState("");
  const [showSecondPhone, setShowSecondPhone] = useState(
    !!initialValues.phone2
  );

  const saveClient = async (
    values: AccountFormValues,
    helpers: FormikHelpers<AccountFormValues>
  ) => {
    const accountId = !!isUpdate && !!accountItem ? accountItem.id : null;
    const emailId =
      !!isUpdate && !!accountItem
        ? accountItem.listAccountEmail[0]?.emailID
        : null;

    const listAccountPhone = [
      {
        phoneID:
          isUpdate && !!accountItem
            ? accountItem.listAccountPhone[0]?.phoneID
            : null,
        accountID: accountId,
        countryID: values.country.id,
        phoneNumber: !!values.phonePrefix.value
          ? values.phonePrefix.value + values.phone
          : values.phone,
        countryPhoneAccessCode: countries.find(
          (c) => c.id === values.country.id
        )!.countryPhoneAccessCode,
      },
    ];
    if (!!values.phone2) {
      listAccountPhone.push({
        phoneID:
          isUpdate && !!accountItem && accountItem.listAccountPhone.length > 1
            ? accountItem.listAccountPhone[1].phoneID
            : null,
        accountID: accountId,
        countryID: values.country2!.id,
        phoneNumber: !!values.phonePrefix2?.value
          ? values.phonePrefix2.value + values.phone2
          : values.phone2,
        countryPhoneAccessCode: countries.find(
          (c) => c.id === values.country2!.id
        )!.countryPhoneAccessCode,
      });
    }

    const account: AccountRequestInterface = {
      id: accountId,
      accountTypeID: 3, // represent AccountType = 'Cliente'
      accountFullName: values.fullName,
      taxIdentificationTypeID: values.idType.taxIdentificationTypeId,
      taxIdentificationTypeCode: values.idType.taxIdentificationTypeCode,
      abreviationName: values.idType.abreviationName, // TODO add also taxIdTypeId needed in API service
      fiscalAddress: values.address,
      countryID: values.country.id,
      identificationNumber: values.idNumber,
      listAccountEmail: [
        {
          email: values.email,
          accountID: accountId,
          emailTypeID: 1,
          emailID: emailId,
        },
      ],
      listAccountPhone,
      creationUser: userLogged?.userLogin ?? null,
      creationDate: new Date().toISOString(),
      accountCode: null,
      listAuthorizingAccount: [],
    };

    loaderService.start();
    const response = isUpdate
      ? await updateAccount(account)
      : await saveAccount(account);
    loaderService.stop();

    if (!response.didError && !!response.model) {
      setSelectedItem(response.model);
      setOpenForm(false);
    } else if (response.errorMessage === "Número de Identificación ya existe") {
      helpers.setFieldError("idNumber", response.errorMessage);
    } else {
      helpers.setFieldError("fullname", response.errorMessage);
    }
  };

  // Set close form status
  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const validateDomain = async (email: string) => {
    setLoader(true);
    const result = await validateEmailDomain(email);
    setLoader(false);

    if (!result && emailValidationRef.current === email) {
      const domain = email.split("@")[1];
      setDomainError(`El dominio "${domain}" del correo electrónico no existe`);
    } else {
      setDomainError("");
    }

    return result;
  };

  const validationDebounced = useMemo(() => debounce(validateDomain, 500), []);

  const validationCallback = useCallback(
    (email: string) => validationDebounced(email),
    [validationDebounced]
  );

  // Validations with Yup for Formik form
  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .required("El campo es obligatorio")
      .test(
        "fullname-format",
        "El nombre de usuario es inválido",
        function (fullname) {
          const result = validateFullname(fullname);

          if (result.error) {
            return this.createError({
              message: result.message,
            });
          }

          return true;
        }
      ),
    email: Yup.string()
      .required("El campo es obligatorio")
      .matches(
        /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
        "Favor ingresar un correo electrónico válido"
      )
      .test(
        "email-format",
        "Favor ingresar un correo electrónico válido",
        (email) => {
          return validateEmailRegex(email);
        }
      )
      .test(
        "email-desposable",
        "Favor no usar un correo electrónico desechable",
        (email) => {
          return validateEmailDisposable(email);
        }
      )
      .test(
        "email-domain-exists",
        "El dominio del correo electrónico no existe",
        function (email) {
          setLastEmailValidation(email);
          validationCallback(email);
          if (domainError !== "") {
            return this.createError({
              message: domainError,
            });
          }
          return true;
        }
      ),
    country: Yup.object().required("El campo es obligatorio"),
    phone: Yup.string()
      .required("El campo es obligatorio")
      .test(
        "phone-number",
        "El número de teléfono es inválido",
        function (phone) {
          const { country, phonePrefix } = this.parent as AccountFormValues;
          const result = validatePhone(
            phone,
            phonePrefix?.value ?? "",
            country
          );

          if (result.error) {
            return this.createError({
              message: result.message,
            });
          }

          return true;
        }
      ),
    phone2: Yup.string().test(
      "phone-number",
      "El número de teléfono es inválido",
      function (phone) {
        if (!phone) return true;

        const { country2, phonePrefix2 } = this.parent as AccountFormValues;
        const result = validatePhone(
          phone,
          phonePrefix2?.value ?? "",
          country2!
        );

        if (result.error) {
          return this.createError({
            message: result.message,
          });
        }

        return true;
      }
    ),
    idNumber: Yup.string()
      .required("El campo es obligatorio")
      .test(
        "id-number",
        "El número de cédula es inválido",
        function (idNumber) {
          const { idType } = this.parent as AccountFormValues;
          const result = validateIdentityDocument(
            idNumber!,
            idType.abreviationName
          );

          if (result.error) {
            return this.createError({
              message: result.message,
            });
          }

          return true;
        }
      ),
  });

  useEffect(() => {
    emailValidationRef.current = lastEmailValidation;
  }, [lastEmailValidation]);

  return (
    <Formik<AccountFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, helpers) => {
        saveClient(values, helpers);
      }}
    >
      {(formik) => {
        return (
          <Form
            formik={formik}
            loader={loader}
            isUpdate={!!isUpdate}
            domainError={domainError}
            ignoreTouched={!!ignoreTouched}
            showSecondPhone={showSecondPhone}
            phoneOptions={phonePrefixOptions}
            disabledIdDocument={disabledIdDocument}
            showFixedPhone={!initialValues.phonePrefix.value}
            setShowSecondPhone={setShowSecondPhone}
            handleCloseForm={handleCloseForm}
          />
        );
      }}
    </Formik>
  );
};

export default AccountForm;
