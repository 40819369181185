import { useState, useEffect, useRef, useCallback, FC } from "react";
import { PrimaryButton, SecondaryButton } from "./Buttons";
import Webcam from "react-webcam";

interface WebcamCaptureProps {
  photo?: string;
  setPhoto: (photo?: string) => void;
  setCheck?: (check: boolean) => void;
}

const WebcamCapture: FC<WebcamCaptureProps> = ({
  photo,
  setPhoto,
  setCheck,
}) => {
  const webcamRef = useRef<Webcam>(null);
  const [deviceId, setDeviceId] = useState({});
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [permissionGranted, setPermissionGranted] = useState<boolean>(false);

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "environment",
  };

  const handleDevices = useCallback(
    (mediaDevices: MediaDeviceInfo[]) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  const capture = useCallback(() => {
    const screenshot = webcamRef.current!.getScreenshot({
      width: 1280,
      height: 720,
    });

    setPhoto(screenshot ?? "");
    setCheck && setCheck(false);
  }, [webcamRef, setPhoto, setCheck]);

  useEffect(() => {
    if (navigator.mediaDevices) {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }
  }, [handleDevices, setPermissionGranted]);

  useEffect(() => {
    const checkPermission = async () => {
      try {
        const permission = await navigator.permissions.query({
          name: "camera" as PermissionName,
        });
        if (permission.state === "prompt" || permission.state === "denied") {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          stream.getTracks().forEach((track) => track.stop());
        }
        setPermissionGranted(permission.state === "granted");
      } catch (error) {
        setPermissionGranted(false);
      }
    };
    checkPermission();
  }, []);

  return (
    <div className="flex flex-col items-center">
      {permissionGranted ? (
        <>
          {devices.length > 1 && (
            <>
              <label className="block items-start ml-4 text-sm/6 font-medium text-gray-900">
                Seleccionar cámara
              </label>
              <select
                defaultValue={devices[0]?.deviceId}
                onChange={(e) => setDeviceId({ deviceId: e.target.value })}
                className="m-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm/6"
              >
                {devices.map((device, key) => (
                  <option key={key} value={device.deviceId}>
                    {device.label || `Device ${key + 1}`}
                  </option>
                ))}
              </select>
            </>
          )}
          {devices.length === 0 ? (
            <div
              className="text-gray-900 border border-gray-300 rounded-xl bg-gray-100"
              style={{
                width: "540px",
                height: "360px",
                border: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="text-center m-4">
                No se encontraron dispositivos de cámara conectados, por favor,
                conecte el dispositivo o revise la conexión del mismo
              </p>
            </div>
          ) : (
            <div className="flex flex-col items-center m-2">
              {!photo ? (
                <>
                  <Webcam
                    audio={false}
                    videoConstraints={{ ...videoConstraints, ...deviceId }}
                    ref={webcamRef}
                    screenshotFormat="image/png"
                  />
                  <PrimaryButton className="mt-2" onClick={capture}>
                    Tomar foto
                  </PrimaryButton>
                </>
              ) : (
                <>
                  <img src={photo} alt="Webcam screenshot" />
                  <SecondaryButton className="mt-2" onClick={() => setPhoto("")}>
                    Volver a tomar foto
                  </SecondaryButton>
                </>
              )}
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            width: "540px",
            height: "360px",
            border: "1px solid black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p> Se necesita permitir el acceso a la cámara.</p>
        </div>
      )}
    </div>
  );
};
export default WebcamCapture;
