import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { v4 } from "uuid";
import Modal from "../components/Modal";
import { useNavigate } from "react-router-dom";
import { formatFloat, formatName, paymentModeFormat } from "../utils";
import PieceForm from "../components/Shipment/PieceForm";
import PriceDetails from "./PriceCalculator/PriceDetails";
import PieceDetails from "./PriceCalculator/PiecesDetails";
import LocationSelect from "./PriceCalculator/LocationSelect";
import { useAppSelector, useAppDispatch } from "../store/hooks";
import { FormSimpleRadioGroup, FormTextInput } from "../components/FormFields";
import {
  getAppLockedServices,
  getDeliveryDistance,
  getLockedServicesByTier,
  getService,
  getServicesFromDeliveryType,
  getServicesFromPaymentMode,
  getShipmentItems,
  getShipmentRates,
  getTier,
  getWeights,
} from "../services";
import { AccountSearch } from "../components/Account/AccountSearch";
import DestinationDetails from "./PriceCalculator/DestinationDetails";
import { ClientDetails } from "../components/Shipment/ShipmentHeader";
import CreateClientModal from "../components/Account/CreateClientModal";
import ShipmentItemTable from "../components/Shipment/ShipmentItemTable";
import {
  LinkText,
  NextButton,
  PrimaryButton,
  SecondaryButton,
} from "../components/Buttons";
import {
  clearShipmentCreate,
  clearPriceCalculator,
  setShipmentCreateDrafts,
  setPriceCalculatorDraft,
} from "../store/slices";
import {
  PaymentMode,
  DeliveryType,
  ItemInterface,
  ShipmentService,
  ShipmentInterface,
  AccountAuthorization,
  discountItem,
} from "../interfaces";
import {
  XMarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { RangeDeclareDto, TierDto } from "../interfaces/Dtos";
import { BusinessUnitSearch } from "../components/LocationSearch";
import { ServiceDTO } from "../interfaces/Dtos/ServiceDTO";

const INITIAL_VALUES = {
  category: "",
  weight: "",
  value: "",
  height: "",
  width: "",
  length: "",
  amount: "1",
};

const PriceCalculator = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Redux state
  const user = useAppSelector((state) => state.user);
  const businessUnits = useAppSelector(
    (state) => state.inmutable.businessUnits
  );
  const applicationID = useAppSelector(
    (state) => state.inmutable.appData.applicationID
  );
  const pieceCategories = useAppSelector(
    (state) => state.inmutable.pieceCategories
  );
  const shipmentDraft = useAppSelector(
    (state) => state.priceCalculator.shipmentDraft
  );
  const userBU = useAppSelector((state) => state.user.businessUnit);
  const rangeMinDeclareList = useAppSelector(
    (state) => state.inmutable.rangeDeclareList
  );

  // Form data
  const itemsKey = useRef<string>("");
  const rateKey = useRef<string>("");
  const [tier, setTier] = useState<TierDto>();
  const [quoted, setQuoted] = useState(false);
  const [cleaned, setCleaned] = useState(true);
  const [isLoading, setIsLoading] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [buSource, setBuSource] = useState(userBU);
  const [service, setService] = useState<ServiceDTO>();
  const [errors, setErrors] = useState({ declaredValue: "" });
  const [openTypeModal, setOpenTypeModal] = useState(false);
  const [openCleanModal, setOpenCleanModal] = useState(false);
  const [createShipment, setCreateShipment] = useState(false);
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
  const [auxService, setAuxService] = useState(shipmentDraft.service);
  const [openShipperCreation, setOpenShipperCreation] = useState(false);
  const [shipmentItems, setShipmentItems] = useState<ItemInterface[]>([]);
  const [openPaymentModeModal, setOpenPaymentModeModal] = useState(false);
  const [allowedServices, setAllowedServices] = useState<ServiceDTO[]>([]);
  const [openConsigneeCreation, setOpenConsigneeCreation] = useState(false);
  const lockedBUServices = useAppSelector((state) => state.user.lockedServices);
  const [declaredValue, setDeclaredValue] = useState(
    shipmentDraft.declaredValue
  );
  const [auxPaymentMode, setAuxPaymentMode] = useState<PaymentMode>();
  const [baseShipmentItems, setBaseShipmentItems] = useState<ItemInterface[]>(
    []
  );
  const [deliveryTypeServices, setDeliveryTypeServices] = useState<
    ServiceDTO[]
  >([]);
  const [tierBlockedServices, setTierBlockedServices] = useState<ServiceDTO[]>(
    []
  );
  const [paymentModeServices, setPaymentModeServices] = useState<ServiceDTO[]>(
    []
  );
  const [appBlockedServices, setAppBlockedServices] = useState<ServiceDTO[]>(
    []
  );

  const saveDraft = useCallback(
    (draft: Partial<ShipmentInterface>) => {
      dispatch(setPriceCalculatorDraft(draft));
    },
    [dispatch]
  );

  const availableServiceTypes = useMemo(() => {
    return allowedServices.map((service) => {
      return {
        name: service.serviceShortName,
        value: service.serviceID,
      };
    });
  }, [allowedServices]);

  const isQuotable = useMemo(() => {
    return (
      isLoading === 0 &&
      buSource &&
      shipmentDraft.buConsignee &&
      shipmentDraft.consigneeAddress &&
      (shipmentDraft.pieces?.length ?? 0) > 0 &&
      (shipmentDraft.totalChargedWeight ?? 0) > 0 &&
      (+(shipmentDraft.declaredValue ?? 0) > 0 ||
        (shipmentDraft.service === ShipmentService.DOCUMENT &&
          shipmentDraft.paymentMode === PaymentMode.CONTADO))
    );
  }, [
    isLoading,
    buSource,
    shipmentDraft.buConsignee,
    shipmentDraft.consigneeAddress,
    shipmentDraft.pieces,
    shipmentDraft.totalChargedWeight,
    shipmentDraft.declaredValue,
    shipmentDraft.service,
    shipmentDraft.paymentMode,
  ]);

  const handleShipment = () => {
    const shipment: ShipmentInterface = {
      ...shipmentDraft,
      shipperAuth: AccountAuthorization.NO_AUTHORIZED,
      shipperAuthAccount: undefined,
      consigneeAuth: AccountAuthorization.NO_AUTHORIZED,
      buSource: user.businessUnit!,
      boxAccount: undefined,
    } as ShipmentInterface;

    dispatch(clearPriceCalculator());
    dispatch(clearShipmentCreate());
    dispatch(setShipmentCreateDrafts([shipment]));

    navigate("/shipment-create");
  };

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 1) {
      event.target.value = event.target.value.replace(/^0+/, "");
    }
    const setValue = (value: string) => {
      event.target.value = value;
    };

    if (!formatFloat(event.target.value, setValue)) {
      return false;
    }
    return true;
  };

  const minDeclaredValue = (weight: number, rangeList: RangeDeclareDto[]) => {
    return (
      rangeList.find((r) => r.minWeight < weight && weight <= r.maxWeight)
        ?.minDeclareValue ?? 0
    );
  };

  const verifyDeclaredValue = useCallback(
    (value: string) => {
      if (
        shipmentDraft.boxAccount ||
        shipmentDraft.service === ShipmentService.DOCUMENT ||
        shipmentDraft.paymentMode === PaymentMode.CREDIT
      ) {
        return true;
      }

      const weight = shipmentDraft.totalChargedWeight ?? 0;
      const minVal = minDeclaredValue(weight, rangeMinDeclareList);

      if (parseFloat(value ?? "0") < minVal) {
        setErrors((errors) => ({
          ...errors,
          declaredValue: `El valor declarado no puede ser menor a ${minVal.toFixed(
            2
          )}$`,
        }));
        return false;
      } else {
        setErrors((errors) => ({
          ...errors,
          declaredValue: "",
        }));
        return true;
      }
    },
    [shipmentDraft, rangeMinDeclareList]
  );

  const handleDeclaredValueChange = () => {
    const value = parseFloat(declaredValue ?? "0");

    if (!verifyDeclaredValue(declaredValue ?? "0")) {
      return;
    }

    setQuoted(false);
    saveDraft({
      declaredValue,
      pieces: shipmentDraft.pieces!.map((piece) => ({
        ...piece,
        value: value / shipmentDraft.pieces!.length,
      })),
    });
  };

  const handlePaymentModeChange = (option?: PaymentMode) => {
    if (!option) return;

    if (option === PaymentMode.COD) {
      saveDraft({
        accountBillTo: shipmentDraft.consignee,
        paymentMode: option,
        accountSiteID: undefined,
        service: ShipmentService.STANDARD,
      });
    } else if (option === PaymentMode.CONTADO) {
      saveDraft({
        accountBillTo: shipmentDraft.shipper,
        paymentMode: option,
        accountSiteID: undefined,
      });
    }
  };

  const getAllShipmentItemRates = async () => {
    const { pieces, consigneeAddress } = shipmentDraft;
    if (
      !pieces ||
      !consigneeAddress ||
      !buSource ||
      pieces.length === 0 ||
      baseShipmentItems.length === 0
    ) {
      return;
    }

    const key = v4();
    rateKey.current = key;

    setIsLoading((p) => p + 1);
    let response = await getShipmentRates(
      shipmentDraft.service ?? ShipmentService.STANDARD,
      shipmentDraft.paymentMode ?? PaymentMode.COD,
      shipmentDraft.deliveryType ?? DeliveryType.AT_OFFICE,
      buSource?.code ?? "",
      shipmentDraft.buConsignee?.code ?? "",
      shipmentDraft.shipper?.id,
      shipmentDraft.consignee?.id,
      shipmentDraft.accountBillTo?.id,
      buSource.location,
      consigneeAddress,
      baseShipmentItems,
      pieces,
      pieces.reduce((acc, p) => acc + p.value, 0) > 0,
      new Date().toISOString(),
      applicationID
    );
    setIsLoading((p) => p - 1);

    if (!response || key !== rateKey.current) {
      return;
    }
    if (response.didError || !response.model) {
      return;
    }
    rateKey.current = "";

    setQuoted(true);
    setShipmentItems(response.model.items);
    saveDraft({
      deliveryDistance: response.model.distance,
      totalChargedWeight: response.model.chargedWeight,
      totalPhysicalWeight: response.model.physicalWeight,
      totalDimensionalWeight: response.model.dimensionalWeight,
      items: response.model.items
        .filter(
          (item) =>
            item.mandatory || shipmentDraft.items?.some((s) => s.id === item.id)
        )
        .sort((a, b) => a.order - b.order),
    });
  };

  useEffect(() => {
    if (!shipmentDraft.shipper) {
      saveDraft({ consignee: undefined });
    }
  }, [shipmentDraft.shipper, saveDraft]);

  useEffect(() => {
    const deliveryType = shipmentDraft.deliveryType;
    if (deliveryType === undefined) {
      setDeliveryTypeServices([]);
      return;
    }

    const getDeliveryTypeServices = async () => {
      const services = await getServicesFromDeliveryType(deliveryType, false);

      if (!services.didError && services.model !== null) {
        setDeliveryTypeServices(services.model);
      }
    };

    getDeliveryTypeServices();
  }, [shipmentDraft.deliveryType]);

  useEffect(() => {
    const buSource = businessUnits.filter(
      (bu) => bu.code === user.businessUnit?.code
    )[0];
    const accounBillToID = shipmentDraft.accountBillTo?.id;
    const shipperLatitude = buSource?.location.coordinates.lat;
    const shipperLongitude = buSource?.location.coordinates.lng;
    const consigneeLatitude =
      shipmentDraft.buConsignee?.location.coordinates.lat;
    const consigneeLongitude =
      shipmentDraft.buConsignee?.location.coordinates.lng;
    const serviceID = shipmentDraft.service;
    const paymentModeID = shipmentDraft.paymentMode;
    if (
      !shipperLatitude ||
      !shipperLongitude ||
      !consigneeLatitude ||
      !consigneeLongitude ||
      !accounBillToID ||
      !serviceID ||
      !paymentModeID
    ) {
      setTier(undefined);
      return;
    }

    const getShipmentTier = async () => {
      const tier = await getTier({
        AccountBillToID: accounBillToID,
        LatitudeShipperAddress: shipperLatitude,
        LongitudeShipperAddress: shipperLongitude,
        LatitudeConsigneeAddress: consigneeLatitude,
        LongitudeConsigneeAddress: consigneeLongitude,
        ServiceID: serviceID,
        PaymentModeID: paymentModeID,
      });

      if (tier.didError || !tier.model) {
        return;
      }

      const blockedServices = await getLockedServicesByTier(tier.model.tierID);

      if (!blockedServices.didError && blockedServices.model) {
        setTierBlockedServices(blockedServices.model);
      }
      setTier(tier.model);
    };

    getShipmentTier();
  }, [
    user,
    businessUnits,
    shipmentDraft.accountBillTo?.id,
    shipmentDraft.buSource,
    shipmentDraft.buConsignee,
    shipmentDraft.consigneeAddress,
    shipmentDraft.service,
    shipmentDraft.paymentMode,
  ]);

  useEffect(() => {
    const paymentMode = shipmentDraft.paymentMode;
    if (paymentMode === undefined) {
      setPaymentModeServices([]);
      return;
    }

    const getPaymentModeServices = async () => {
      const services = await getServicesFromPaymentMode(paymentMode);

      if (!services.didError && services.model !== null) {
        setPaymentModeServices(services.model);
      }
    };

    getPaymentModeServices();
  }, [shipmentDraft.paymentMode]);

  useEffect(() => {
    if (shipmentDraft.deliveryType === DeliveryType.INTERNATIONAL) {
      saveDraft({ service: ShipmentService.STANDARD_INTERNATIONAL });
    } else {
      saveDraft({ service: ShipmentService.STANDARD });
    }
  }, [shipmentDraft.deliveryType, saveDraft]);

  useEffect(() => {
    if (
      !shipmentDraft.consigneeAddress ||
      !shipmentDraft.pieces ||
      !shipmentDraft.pieces.length
    ) {
      saveDraft({
        shipper: undefined,
        consignee: undefined,
      });
    }
  }, [shipmentDraft.consigneeAddress, shipmentDraft.pieces, saveDraft]);

  useEffect(() => {
    const { totalChargedWeight, declaredValue, deliveryDistance } =
      shipmentDraft;
    if (
      !totalChargedWeight ||
      deliveryDistance === undefined ||
      (shipmentDraft.service !== ShipmentService.DOCUMENT &&
        shipmentDraft.paymentMode !== PaymentMode.CREDIT &&
        (!declaredValue || isNaN(Number(declaredValue)))) ||
      !buSource?.location?.coordinates.lat ||
      !buSource?.location?.coordinates.lng ||
      !shipmentDraft.consigneeAddress?.coordinates.lat ||
      !shipmentDraft.consigneeAddress?.coordinates.lng ||
      !shipmentDraft.service ||
      !shipmentDraft.paymentMode
    ) {
      return;
    }

    const getAllShipmentItems = async () => {
      const key = v4();
      itemsKey.current = key;
      setIsLoading((p) => p + 1);
      const tier = await getTier({
        AccountBillToID: shipmentDraft.accountBillTo?.id,
        LatitudeShipperAddress: buSource?.location.coordinates.lat,
        LongitudeShipperAddress: buSource?.location.coordinates.lng,
        LatitudeConsigneeAddress:
          shipmentDraft.consigneeAddress?.coordinates.lat,
        LongitudeConsigneeAddress:
          shipmentDraft.consigneeAddress?.coordinates.lng,
        ServiceID: shipmentDraft.service!,
        PaymentModeID: shipmentDraft.paymentMode!,
      });
      const response = await getShipmentItems({
        CurrencyID: 2,
        CountryID: 236,
        Weight: totalChargedWeight,
        DeclaratedValue: declaredValue ? Number(declaredValue) : 0,
        Distance: deliveryDistance,
        ApplicationID: applicationID,
        ShipperAccountID: shipmentDraft.shipper?.id,
        ConsigneeAccountID: shipmentDraft.consignee?.id,
        AccountBillToID: shipmentDraft.accountBillTo?.id,
        AccountSiteID: shipmentDraft.accountBillTo?.accountSiteID ?? undefined,
        ServiceID: shipmentDraft.service,
        PaymentModeID: shipmentDraft.paymentMode,
        DeliveryTypeID: shipmentDraft.deliveryType,
        SRM: !!shipmentDraft.pieces?.reduce((acc, p) => acc + p.value, 0),
        TierID: tier.model?.tierID,
        ShippingMethodID: 10,
        PackageTypeID: 10,
        SalesDate: new Date().toISOString(),
        BUCodeSource: buSource?.code,
        BUCodeConsignee: shipmentDraft.buConsignee?.code,
        IsToPickup: shipmentDraft.isToPickup,
      });
      setIsLoading((p) => p - 1);

      if (!response || key !== itemsKey.current) return;
      if (response.didError || !response.model) {
        return;
      }
      itemsKey.current = "";

      // Filter ipostel
      setBaseShipmentItems(
        response.model.filter(
          (item) =>
            !item.name.toLowerCase().includes("ipostel") &&
            item.id !== discountItem.id
        )
      );
    };

    getAllShipmentItems();
  }, [
    applicationID,
    buSource,
    shipmentDraft.service,
    shipmentDraft.paymentMode,
    shipmentDraft.deliveryType,
    shipmentDraft.pieces,
  ]);

  useEffect(() => {
    setBuSource(userBU);
  }, [userBU]);

  useEffect(() => {
    if (!shipmentDraft.pieces || shipmentDraft.pieces.length === 0) {
      saveDraft({
        totalChargedWeight: 0,
        totalPhysicalWeight: 0,
        totalDimensionalWeight: 0,
      });
      return;
    }

    const getShipmentWeight = async () => {
      const weights = await getWeights(
        236,
        10,
        undefined,
        shipmentDraft.service,
        shipmentDraft.paymentMode,
        shipmentDraft.pieces ?? []
      );

      if (!weights || weights.didError) return;

      saveDraft({
        totalChargedWeight: weights.model?.chargedWeight,
        totalPhysicalWeight: weights.model?.physicalWeight,
        totalDimensionalWeight: weights.model?.dimensionalWeight,
      });
    };

    getShipmentWeight();
  }, [shipmentDraft.pieces, saveDraft]);

  useEffect(() => {
    if (applicationID === undefined) {
      setAppBlockedServices([]);
      return;
    }

    const getLockedServices = async () => {
      const services = await getAppLockedServices(applicationID);

      if (!services.didError && services.model !== null) {
        setAppBlockedServices(services.model);
      }
    };

    getLockedServices();
  }, [applicationID]);

  useEffect(() => {
    setAllowedServices(
      paymentModeServices.filter(
        (service) =>
          deliveryTypeServices.some((s) => s.serviceID === service.serviceID) &&
          !tierBlockedServices.some((s) => s.serviceID === service.serviceID) &&
          (service.receptionInBU ||
            shipmentDraft.deliveryType !== DeliveryType.AT_OFFICE) &&
          !lockedBUServices.some((s) => s.serviceID === service.serviceID) &&
          !appBlockedServices.some((s) => s.serviceID === service.serviceID)
      )
    );
  }, [
    appBlockedServices,
    paymentModeServices,
    deliveryTypeServices,
    tierBlockedServices,
    shipmentDraft.deliveryType,
    lockedBUServices,
  ]);

  useEffect(() => {
    if (
      !buSource?.location?.coordinates.lat ||
      !buSource?.location?.coordinates.lng ||
      !shipmentDraft.consigneeAddress?.coordinates.lat ||
      !shipmentDraft.consigneeAddress?.coordinates.lng
    ) {
      saveDraft({
        deliveryDistance: 0,
      });
      return;
    }

    const getShipmentDeliveryDistance = async () => {
      const distance = await getDeliveryDistance(
        shipmentDraft.buConsignee?.location?.coordinates.lat!,
        shipmentDraft.buConsignee?.location?.coordinates.lng!,
        shipmentDraft.consigneeAddress?.coordinates.lat!,
        shipmentDraft.consigneeAddress?.coordinates.lng!
      );

      if (!distance || distance.didError) return;

      saveDraft({ deliveryDistance: distance.model?.deliveryDistance });
    };

    getShipmentDeliveryDistance();
  }, [
    shipmentDraft.buSource,
    shipmentDraft.consigneeAddress,
    buSource,
    saveDraft,
  ]);

  useEffect(() => {
    const serviceID = shipmentDraft.service;
    if (serviceID === undefined) {
      return;
    }

    const afunction = async () => {
      const service = await getService(serviceID);

      if (!service.didError && service.model) {
        setService(service.model);
      }
    }

    afunction()
  }, [shipmentDraft.service])

  return (
    <main className="lg:pl-72">
      {/* Header */}
      <div className="py-8 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32">
        <header className="ml-4 text-2xl font-bold text-gray-700 ">
          Cotizador de envíos
        </header>
      </div>

      <div className="bg-gray-50">
        <div className="mx-auto max-w-2xl px-4 pb-24 pt-8 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
            {/* Selections (Left column) */}
            <div>
              {/* BU Source selection */}
              <h2 className="pl-1 text-xl pb-3 font-medium text-gray-900">
                Elegir tienda de origen
              </h2>
              <div className="flex flex-col bg-white rounded-lg border px-8 pb-6 pt-4">
                <BusinessUnitSearch
                  selected={buSource}
                  buSource={buSource}
                  setSelectedLocation={(businessUnit) =>
                    setBuSource(businessUnit)
                  }
                />
              </div>

              {/* Destination selection */}
              <div className="mt-10 border-t border-gray-200 pt-5">
                <h2 className="pl-1 text-xl pb-3 font-medium text-gray-900">
                  Elegir destino
                </h2>
                <LocationSelect
                  buSource={buSource}
                  businessUnits={businessUnits}
                  deliveryType={shipmentDraft.deliveryType}
                  selectedBusinessUnit={shipmentDraft.buConsignee}
                  selectedLocation={shipmentDraft.consigneeAddress}
                  setLocationType={(deliveryType) =>
                    saveDraft({
                      consigneeAddress: undefined,
                      buConsignee: undefined,
                      deliveryType,
                    })
                  }
                  setLocation={(location, buConsignee) => {
                    setQuoted(false);
                    saveDraft({ consigneeAddress: location, buConsignee });
                  }}
                />
              </div>

              {/* Payment method selection */}
              <div className="mt-10 border-t border-gray-200 pt-5">
                <h2 className="pl-1 text-xl pb-3 font-medium text-gray-900">
                  Seleccionar modalidad de pago
                </h2>
                <div className="flex flex-col bg-white rounded-lg border px-8 pb-6 pt-4">
                  <FormSimpleRadioGroup
                    name="paymentMode"
                    selected={shipmentDraft.paymentMode}
                    options={[
                      {
                        name: paymentModeFormat(PaymentMode.COD),
                        value: PaymentMode.COD,
                      },
                      {
                        name: paymentModeFormat(PaymentMode.CONTADO),
                        value: PaymentMode.CONTADO,
                      },
                    ]}
                    onSelectOption={(paymentMode) => {
                      setQuoted(false);

                      if (
                        paymentMode !== PaymentMode.CONTADO &&
                        shipmentDraft.service === ShipmentService.DOCUMENT &&
                        shipmentDraft.pieces!.length > 0
                      ) {
                        setAuxPaymentMode(paymentMode);
                        setOpenPaymentModeModal(true);
                        return;
                      }

                      handlePaymentModeChange(paymentMode);
                    }}
                  />
                </div>
              </div>

              {/* Pieces selection */}
              <div className="mt-10 border-t border-gray-200 pt-5">
                <h2 className="pl-1 text-xl pb-3 font-medium text-gray-900">
                  Añadir piezas
                </h2>
                <div className="flex flex-col bg-white rounded-lg border px-8 pb-6 pt-4 gap-8">
                  <FormSimpleRadioGroup
                    horizontal
                    name="piecesType"
                    selected={shipmentDraft.service}
                    options={availableServiceTypes}
                    label="Servicio"
                    className={classNames(
                      (!shipmentDraft.paymentMode ||
                        availableServiceTypes.length === 0) &&
                        "hidden"
                    )}
                    onSelectOption={(service) => {
                      if (!!shipmentDraft.pieces?.length) {
                        setAuxService(service);
                        setOpenTypeModal(true);
                      } else {
                        setQuoted(false);
                        saveDraft({ service });
                      }
                    }}
                  />

                  <PieceForm
                    editMode={editMode}
                    cleaned={cleaned}
                    setCleaned={setCleaned}
                    type={shipmentDraft.service ?? ShipmentService.STANDARD}
                    initialValues={initialValues}
                    pieceCategories={pieceCategories}
                    pieces={shipmentDraft.pieces}
                    minSumWeight={service?.minWeight}
                    maxSumWeight={service?.maxWeight ?? Infinity}
                    applyMinValue={
                      !shipmentDraft.boxAccount &&
                      shipmentDraft.service === ShipmentService.STANDARD &&
                      (shipmentDraft.paymentMode === PaymentMode.COD ||
                        shipmentDraft.paymentMode === PaymentMode.CONTADO)
                    }
                    onSubmit={(values, amount) => {
                      const newPieces = [...shipmentDraft.pieces!];
                      setQuoted(false);

                      if (editMode) {
                        newPieces[editIndex] = values;
                        setEditMode(false);
                      } else {
                        for (let i = 0; i < amount; i++) {
                          newPieces.push(values);
                        }
                      }

                      const value =
                        parseFloat(
                          shipmentDraft.declaredValue
                            ? shipmentDraft.declaredValue
                            : "0"
                        ) / newPieces.length;

                      saveDraft({
                        pieces: newPieces!.map((piece) => ({
                          ...piece,
                          value,
                        })),
                      });
                    }}
                    setEditMode={(v) => {
                      setEditMode(v);
                      if (!v) setInitialValues(INITIAL_VALUES);
                    }}
                  />

                  <div className="flex gap-4">
                    <div className="w-full">
                      <FormTextInput
                        value={declaredValue}
                        error={errors.declaredValue}
                        onChange={(event) => {
                          if (handleNumberChange(event)) {
                            verifyDeclaredValue(event.target.value);
                            setDeclaredValue(event.target.value);
                          }
                        }}
                        onClick={handleDeclaredValueChange}
                        name="declaredValue"
                        label="Valor Declarado en Dólares"
                      />
                    </div>

                    <div>
                      <PrimaryButton
                        className="w-32 mt-8"
                        disabled={declaredValue === shipmentDraft.declaredValue}
                        onClick={handleDeclaredValueChange}
                      >
                        Aplicar
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Document Summary (Right column) */}
            <div className="mt-10 lg:mt-0">
              <div className="flex flex-1 justify-between items-center">
                <h2 className="text-2xl font-medium text-gray-900">
                  Resumen de envío
                </h2>

                <LinkText
                  text="Limpiar"
                  onClick={() => setOpenCleanModal(true)}
                />
              </div>
              <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
                {/* Destination summary */}
                <DestinationDetails
                  businessUnit={shipmentDraft.buConsignee}
                  location={shipmentDraft.consigneeAddress}
                  deliveryType={
                    shipmentDraft.deliveryType ?? DeliveryType.AT_OFFICE
                  }
                />

                {/* Pieces summary */}
                <PieceDetails
                  editMode={editMode}
                  editIndex={editIndex}
                  pieces={shipmentDraft.pieces ?? []}
                  setPieces={(pieces) => {
                    const value =
                      parseFloat(
                        shipmentDraft.declaredValue
                          ? shipmentDraft.declaredValue
                          : "0"
                      ) / pieces.length;

                    saveDraft({
                      pieces: pieces!.map((piece) => ({
                        ...piece,
                        value,
                      })),
                    });
                  }}
                  setEditMode={setEditMode}
                  setEditIndex={setEditIndex}
                  setInitialValues={setInitialValues}
                />

                <div className="flex flex-col w-full items-end text-gray-800 px-4 pb-5 sm:px-6">
                  <p className="">
                    Peso a facturar:{" "}
                    <span className="font-semibold">
                      {shipmentDraft.totalChargedWeight?.toFixed(2) ?? "0.00"} Kg
                    </span>
                  </p>
                  <p className="text-xs mt-2">
                    Peso balanza:{" "}
                    <span className="font-semibold">
                      {shipmentDraft.totalPhysicalWeight?.toFixed(2) ?? "0.00"} Kg
                    </span>
                  </p>
                  <p className="text-xs">
                    Peso volumétrico:{" "}
                    <span className="font-semibold">
                      {shipmentDraft.totalDimensionalWeight?.toFixed(2) ?? "0.00"} Kg
                    </span>
                  </p>
                </div>

                {/* Items summary */}
                <div className="relative flex flex-col border-t border-gray-200 px-8 pb-6 pt-4">
                  <div className="flex items-center justify-between mb-4">
                    <h2 className="mb-4 text-2xl font-semibold leading-7 text-gray-900">
                      Servicios
                    </h2>

                    <PrimaryButton
                      onClick={getAllShipmentItemRates}
                      disabled={!isQuotable}
                    >
                      COTIZAR
                    </PrimaryButton>
                  </div>

                  <div className="overflow-x-auto">
                    <ShipmentItemTable
                      items={shipmentDraft.items ?? []}
                      distance={shipmentDraft.deliveryDistance}
                      declaredValue={
                        +(!!shipmentDraft.declaredValue
                          ? shipmentDraft.declaredValue
                          : 0)
                      }
                      availableItems={shipmentItems.filter(
                        (item) =>
                          !item.mandatory &&
                          !(shipmentDraft.items ?? []).some(
                            (i) => i.id === item.id
                          )
                      )}
                      setItems={(items) => {
                        saveDraft({
                          items: items.sort((a, b) => a.order - b.order),
                        });
                      }}
                    />
                  </div>
                </div>

                {/* Price summary */}
                {!!shipmentDraft.consigneeAddress &&
                  !!shipmentDraft.pieces?.length &&
                  !!shipmentDraft.paymentMode && (
                    <PriceDetails
                      items={shipmentDraft.items ?? []}
                      paymentMode={shipmentDraft.paymentMode}
                    />
                  )}

                {/* Show selection of Shipper or Consignee */}
                {!createShipment ? (
                  <div className="flex flex-1 justify-end p-8">
                    <div className="flex flex-col items-end">
                      <div hidden={user.user?.roleName === "Consulta Innovus"}>
                        <PrimaryButton
                          disabled={
                            !quoted ||
                            !shipmentDraft.consigneeAddress ||
                            !shipmentDraft.pieces ||
                            !shipmentDraft.pieces.length ||
                            buSource?.id !== userBU?.id
                          }
                          onClick={() => {
                            if (!verifyDeclaredValue(declaredValue ?? "0")) {
                              return;
                            }
                            setCreateShipment(true);
                          }}
                        >
                          Crear Guía
                        </PrimaryButton>
                      </div>
                      {!quoted &&
                        user.user?.roleName !== "Consulta Innovus" && (
                          <p className="text-xs text-gray-500">
                            Debe cotizar el envío antes de crear la guía
                          </p>
                        )}
                      {buSource?.id !== userBU?.id && (
                        <p className="text-xs text-gray-500">
                          No puedes crear una guía con un origen distinto al de
                          tu tienda
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col gap-4 border-t border-gray-200 px-4 py-6 sm:px-6">
                    {!!shipmentDraft.shipper ? (
                      <div className="relative p-4 pr-0 border border-gray-400 border-1 shadow-md rounded-lg overflow-hidden">
                        <div className="flex flex-col mr-16">
                          <div className="flex gap-2">
                            <p className="font-semibold">Remitente:</p>
                            <p className="truncate">
                              {formatName(
                                shipmentDraft.shipper?.accountFullName
                              )}
                            </p>
                          </div>
                          <ClientDetails client={shipmentDraft.shipper} />
                        </div>

                        <div className="absolute right-2 top-2 cursor-pointer px-1 ">
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                              onClick={() =>
                                saveDraft({
                                  shipper: undefined,
                                  consignee: undefined,
                                })
                              }
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6 text-gray-800"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <AccountSearch
                        title={"Remitente"}
                        onSelectClient={(shipper) => {
                          if (shipmentDraft.paymentMode === PaymentMode.COD) {
                            saveDraft({
                              shipper,
                              consignee: undefined,
                              accountBillTo: shipper,
                            });
                          } else {
                            saveDraft({ shipper, consignee: undefined });
                          }
                        }}
                        openCreationModal={setOpenShipperCreation}
                      />
                    )}

                    {!!shipmentDraft.shipper && !!shipmentDraft.consignee ? (
                      <div className="relative p-4 pr-0 border border-gray-400 border-1 shadow-md rounded-lg overflow-hidden">
                        <div className="flex flex-col mr-16">
                          <div className="flex gap-2">
                            <p className="font-semibold">Destinatario:</p>
                            <p className="truncate">
                              {formatName(
                                shipmentDraft.consignee?.accountFullName
                              )}
                            </p>
                          </div>
                          <ClientDetails client={shipmentDraft.consignee} />
                        </div>

                        <div className="absolute right-2 top-2 cursor-pointer px-1 ">
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                              onClick={() =>
                                saveDraft({
                                  consignee: undefined,
                                })
                              }
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6 text-gray-800"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : !!shipmentDraft.shipper ? (
                      <div className="mt-6">
                        <AccountSearch
                          title={"Destinatario"}
                          onSelectClient={(consignee) => {
                            if (
                              shipmentDraft.paymentMode === PaymentMode.CONTADO
                            ) {
                              saveDraft({
                                consignee,
                                accountBillTo: consignee,
                              });
                            } else {
                              saveDraft({ consignee });
                            }
                          }}
                          openCreationModal={setOpenConsigneeCreation}
                        />
                      </div>
                    ) : null}

                    {!!shipmentDraft.shipper &&
                      !!shipmentDraft.consignee &&
                      !!shipmentDraft.consigneeAddress &&
                      !!shipmentDraft.pieces &&
                      !!shipmentDraft.pieces.length && (
                        <div className="flex justify-end items-end flex-1">
                          <NextButton
                            type="submit"
                            onClick={() => handleShipment()}
                          />
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateClientModal
        openModal={openShipperCreation}
        setOpenModal={setOpenShipperCreation}
        setSelectedItem={(shipper) =>
          saveDraft({ shipper, consignee: undefined })
        }
      />

      <CreateClientModal
        openModal={openConsigneeCreation}
        setOpenModal={setOpenConsigneeCreation}
        setSelectedItem={(shipper) =>
          saveDraft({ shipper, consignee: undefined })
        }
      />

      <Modal openModal={openTypeModal} setOpenModal={setOpenTypeModal}>
        <div
          className="flex flex-col items-center justify-center"
          style={{ maxWidth: "20rem" }}
        >
          <div className="flex flex-col items-center justify-center w-full">
            <ExclamationTriangleIcon className="w-32 h-32" />
          </div>

          <p className="mt-2 text-center text-gray-900">
            ¿Está seguro que desea cambiar el tipo de envio? Esta acción
            eliminará las piezas creadas
          </p>

          <div className="mt-4 flex w-full flex-row justify-between gap-4">
            <SecondaryButton
              className="px-4"
              onClick={() => setOpenTypeModal(false)}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              className="px-4"
              onClick={() => {
                setQuoted(false);
                saveDraft({ pieces: [], service: auxService });
                setOpenTypeModal(false);
              }}
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      <Modal openModal={openCleanModal} setOpenModal={setOpenCleanModal}>
        <div
          className="flex flex-col items-center justify-center"
          style={{ maxWidth: "20rem" }}
        >
          <div className="flex flex-col items-center justify-center w-full">
            <ExclamationTriangleIcon className="w-32 h-32" />
          </div>

          <p className="mt-2 text-center text-gray-900">
            ¿Está seguro que desea limpiar todos los campos?
          </p>

          <div className="mt-4 flex w-full flex-row justify-between gap-4">
            <SecondaryButton
              className="px-4"
              onClick={() => setOpenCleanModal(false)}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              className="px-4"
              onClick={() => {
                dispatch(clearPriceCalculator());
                setOpenCleanModal(false);
                setCleaned(true);
              }}
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      <Modal
        openModal={openPaymentModeModal}
        setOpenModal={setOpenPaymentModeModal}
      >
        <div
          className="flex flex-col items-center justify-center"
          style={{ maxWidth: "20rem" }}
        >
          <div className="flex flex-col items-center justify-center w-full">
            <ExclamationTriangleIcon className="w-32 h-32" />
          </div>

          <p className="mt-2 text-center text-gray-900">
            Esta acción eliminará las piezas seleccionadas. ¿Desea continuar?
          </p>

          <div className="mt-4 flex w-full flex-row justify-between gap-4">
            <SecondaryButton
              className="px-4"
              onClick={() => setOpenPaymentModeModal(false)}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              className="px-4"
              onClick={() => {
                saveDraft({ pieces: [] });
                handlePaymentModeChange(auxPaymentMode);
                setOpenPaymentModeModal(false);
              }}
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </main>
  );
};

export default PriceCalculator;
