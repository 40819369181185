import { FC, useMemo, useState } from "react";
import {
  ManifestDetailSingleInterface,
  ManifestIncidence,
} from "../../interfaces/ManifestInterface";
import classNames from "classnames";
import LoadingIcon from "../../components/LodingIcon";
import {
  ArrowPathIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Modal from "../../components/Modal";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";
import {
  removePendingDetails,
  removeProcessedDetails,
} from "../../store/slices/dispatch";
import { alertService } from "../../services";
import {
  updateDetailStatusDeleted,
  updateSingleDetailStatusDeleted,
} from "../../services/manifestServices";

interface DispatchDetailItemProps {
  detail: ManifestDetailSingleInterface;
  index: number;
  detailsBeingValidated: string[];
  onRetry: () => Promise<void>;
}
const DispatchDetailItem: FC<DispatchDetailItemProps> = ({
  detail,
  index,
  detailsBeingValidated,
  onRetry,
}) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const pendingDetails = useAppSelector(
    (state) => state.dispatch.pendingDetails
  );
  const processedDetails = useAppSelector(
    (state) => state.dispatch.processedDetails
  );
  const dispatchHeader = useAppSelector(
    (state) => state.dispatch.dispatchHeader
  );

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const areMissingDetailsWarning = useMemo(() => {
    const shipmentNumber = detail.shipmentNumber;
    const totalPieces = detail.totalPieces;
    const totalPiecesSum = Object.keys(pendingDetails)
      .map((id) => pendingDetails[id])
      .concat(Object.keys(processedDetails).map((id) => processedDetails[id]))
      .reduce(
        (acc, d) => acc + (d.shipmentNumber === shipmentNumber ? 1 : 0),
        0
      );
    const diff = Math.abs(totalPieces - totalPiecesSum);

    if (diff > 0) {
      return `La guía ${shipmentNumber} tiene ${diff} ${
        totalPieces > totalPiecesSum ? `pieza faltante.` : `piezas sobrantes.`
      }`;
    } else {
      return "";
    }
  }, [detail, pendingDetails, processedDetails]);

  const reloadable = useMemo(() => {
    return (
      (detail.incidence === ManifestIncidence.CRITIC_ERROR ||
        detail.incidence === ManifestIncidence.NOT_VALIDATED) &&
      !detailsBeingValidated.some(
        (d) => d === `${detail.shipmentNumber}-${detail.pieceNumber}`
      )
    );
  }, [detail, detailsBeingValidated]);

  const handleShipmentDelete = async () => {
    const userLogin = user.user?.userLogin;
    const masterShipmentHeaderID = dispatchHeader?.masterShipmentHeaderID;

    if (!userLogin) {
      alertService.error(
        "No hay un nombre de usuario asociado a la sesión actual"
      );
      return;
    }
    if (!masterShipmentHeaderID) {
      alertService.error(
        "Parece que no hay ningún manifiesto de despacho cargado"
      );
      return;
    }

    const detailsToDelete = Object.keys(processedDetails)
      .map((id) => processedDetails[id])
      .concat(Object.keys(pendingDetails).map((id) => pendingDetails[id]))
      .filter((d) => d.shipmentNumber === detail.shipmentNumber);
    const containerDetailID = detailsToDelete.find(
      (d) => d.containerDetailID
    )?.containerDetailID;

    if (containerDetailID) {
      const deleteStatus = await updateDetailStatusDeleted(
        masterShipmentHeaderID,
        containerDetailID,
        userLogin,
        detail.shipmentNumber
      );

      if (deleteStatus.didError) {
        alertService.error(
          `Hubo un error eliminando la guía ${detail.shipmentNumber}`,
          deleteStatus.errorMessage
        );
        return;
      }
    }

    dispatch(removeProcessedDetails(detailsToDelete));
    dispatch(removePendingDetails(detailsToDelete));
  };

  const handleDetailDelete = async () => {
    const userLogin = user.user?.userLogin;
    const containerDetailID = detail.containerDetailID;

    if (!userLogin) {
      alertService.error(
        "No hay un nombre de usuario asociado a la sesión actual"
      );
      return;
    }
    if (!containerDetailID) {
      dispatch(removeProcessedDetails([detail]));
      dispatch(removePendingDetails([detail]));
      return;
    }

    const deleteStatus = await updateSingleDetailStatusDeleted(
      containerDetailID,
      userLogin
    );

    if (deleteStatus.didError) {
      alertService.error(
        "Hubo un error eliminando la pieza",
        deleteStatus.errorMessage
      );
      return;
    }

    dispatch(removeProcessedDetails([detail]));
    dispatch(removePendingDetails([detail]));
  };

  return (
    <tr
      key={`${detail.shipmentNumber}-${detail.pieceNumber}`}
      className={classNames(
        "hover:bg-gray-100 text-center transition-opacity duration-500",
        index % 2 === 0 ? "bg-gray-50" : "bg-white"
      )}
      title={[detail.errors, areMissingDetailsWarning]
        .filter((message) => message)
        .join("\n")}
    >
      <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
        {detail.shipmentNumber}
      </td>

      <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
        {detail.buCodeSource.toUpperCase()}
      </td>

      <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
        {detail.locationDestinationCode.toUpperCase()}
      </td>

      <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
        {detail.serviceName?.toUpperCase()}
      </td>

      <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
        {detail.pieceNumber}
      </td>

      <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
        {detail.totalPieces}
      </td>

      <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
        {detail.physicalWeight.toFixed(2)}
      </td>

      <td className="px-4 py-2 text-xs text-gray-700 font-semibold">
        <div className="flex justify-center items-center space-x-1">
          <div className="w-7 h-7">
            {detail.incidence === ManifestIncidence.NOT_VALIDATED ? (
              <LoadingIcon size="18px" />
            ) : detail.incidence === ManifestIncidence.NO_ERROR ? (
              <CheckCircleIcon className="text-green-600 w-7 h-7" />
            ) : detail.incidence === ManifestIncidence.WARNING ? (
              <ExclamationTriangleIcon className="text-orange-600 w-7 h-7" />
            ) : detail.incidence === ManifestIncidence.ERROR ||
              detail.incidence === ManifestIncidence.CRITIC_ERROR ? (
              <ExclamationCircleIcon className="text-red-600 w-7 h-7" />
            ) : (
              <QuestionMarkCircleIcon className="text-red-600 w-7 h-7" />
            )}
          </div>
          <div className="w-7 h-7">
            {areMissingDetailsWarning && (
              <ExclamationTriangleIcon className="text-orange-600 w-7 h-7" />
            )}
          </div>
        </div>
      </td>

      <td className="px-4 py-2 text-xs text-gray-700 font-semibold">
        <div className="flex justify-center items-center gap-2=">
          <ArrowPathIcon
            className={classNames(
              "text-blue-600 w-7 h-7",
              !reloadable && "hidden",
              reloadable && "cursor-pointer hover:animate-spin"
            )}
            title="Reintentar validación"
            onClick={onRetry}
          />

          <TrashIcon
            onClick={() => setOpenDeleteModal(true)}
            className="h-7 w-7 text-red-500 cursor-pointer hover:text-red-700"
          />
        </div>
      </td>

      <Modal openModal={openDeleteModal} setOpenModal={setOpenDeleteModal}>
        <div className="flex flex-col gap-4 mx-auto items-center">
          <div className="flex flex-col items-center">
            <QuestionMarkCircleIcon className="text-blue-500 h-28 w-28 mx-auto opacity-90" />
            <h2 className="text-xl font-semibold leading-7 text-gray-700 text-center mt-2">
              Eliminar pieza de la guía {detail.shipmentNumber}
            </h2>
          </div>

          <div className="flex flex-col gap-1">
            <p className="text-gray-600 text-center whitespace-pre-line">
              ¿Está seguro que desea eliminar esta pieza o todas las piezas de
              la guía?
            </p>
          </div>

          <div className="flex w-full flex-row gap-8 items-center justify-between mt-4">
            <SecondaryButton
              onClick={() => setOpenDeleteModal(false)}
              className="px-4 py-2"
            >
              Cancelar
            </SecondaryButton>

            <div className="flex items-center gap-4">
              <PrimaryButton
                onClick={async () => {
                  await handleDetailDelete();
                  setOpenDeleteModal(false);
                }}
                className="px-4 py-2 truncate"
              >
                Eliminar esta pieza
              </PrimaryButton>

              <PrimaryButton
                onClick={async () => {
                  await handleShipmentDelete();
                  setOpenDeleteModal(false);
                }}
                className="px-4 py-2 truncate"
              >
                Eliminar todas las piezas
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Modal>
    </tr>
  );
};

export default DispatchDetailItem;
