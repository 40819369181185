import api from "./api";
import {
  Nullable,
  ResponseInterface,
  ShipmentDetailValidate,
  ShipmentHeaderManifest,
} from "../interfaces";
import {
  ManifestHeaderInterface,
  ManifestDetailInterface,
  ManifestType,
  Route,
  Vehicle,
  AccountDriver,
  DispatchManifestForm,
  ManifestDetailSingleInterface,
  ManifestViewModel,
  ManifestPostponedHeaderRequest,
  ManifestStatus,
  ManifestHeaderUpdateInterface,
  ManifestIncidence,
} from "../interfaces/ManifestInterface";
import { BusinessUnitDTO } from "../interfaces/Dtos";
import axios from "axios";
import { alertService } from "./alertService";

const baseURLAPI = process.env.REACT_APP_API_HOST;
//const baseURLAPI = "https://localhost:44360";

export const getOfficeShipment = async (
  shipmentNumber: string
): Promise<ShipmentHeaderManifest | undefined> => {
  const uri = `${baseURLAPI}/v2/Shipment/SearchShipmentLastBU?shipmentNumber=${shipmentNumber}`;
  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      return response.data.model;
      //  {
      //   model: response.data.model,
      //   didError: false,
      //   message: "",
      //   errorMessage: "",
      // };
    }
  } catch (error) {
    return (error as any)?.response?.data;
  }

  // return {
  //   model: null,
  //   didError: true,
  //   message: "Hubo un error en la búsqueda de la guía.",
  //   errorMessage: "Hubo un error en la búsqueda de la guía.",
  // };
};

export const getManifestHeader = async (
  receiverBUID: string,
  fromDate: Date | null,
  toDate: Date | null,
  manifestTypeID: ManifestType,
  manifestCode?: string | null
): Promise<ManifestHeaderInterface[] | null> => {
  let uri = `${baseURLAPI}/Manifiest/GetAllManifest`;

  const body = JSON.stringify({
    manifestCode: manifestCode,
    receiverBUID: receiverBUID,
    dateFrom: fromDate,
    dateTo: toDate,
    manifestTypeID: manifestTypeID,
  });

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      // cancelToken: source.token,
    });

    if (response.status === 200) {
      return response.data.model;
    }
  } catch (error) {}

  return null;
};

export const getManifestPostponed = async (
  manifestTypeID: ManifestType,
  fromDate: Date,
  toDate: Date,
  sourceBUCode?: string | null,
  destinationBUCode?: string | null,
  statusID?: number | null
): Promise<ResponseInterface<ManifestHeaderInterface[]>> => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let uri = `${baseURLAPI}/Manifiest/GetPostponed`;

  const manifestRequest: ManifestPostponedHeaderRequest = {
    sourceBUCode,
    destinationBUCode,
    manifestTypeID,
    statusID,
    fromDate,
    toDate,
  };

  const body = JSON.stringify(manifestRequest);

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }

  return {
    message: "",
    model: null,
    didError: true,
    errorMessage: "Hubo un error obteniendo el manifiesto postergado",
  };
};

export const getManifestPostponedByCode = async (
  manifestCode: string,
  userLogin: string
): Promise<ResponseInterface<ManifestViewModel>> => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let uri = `${baseURLAPI}/Manifiest/GetPostponedByCode?manifestCode=${manifestCode}&userLogin=${userLogin}`;

  try {
    const response = await api.get(uri, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }

  return {
    message: "",
    model: null,
    didError: true,
    errorMessage: "Hubo un error obteniendo el manifiesto postergado",
  };
};

export const getManifestDetail = async (
  manifestCode: string,
  returnAllDetails?: boolean | false
): Promise<Nullable<ManifestHeaderInterface[]>> => {
  returnAllDetails = returnAllDetails || false;
  const uri = `${baseURLAPI}/Manifiest/GetManifest?manifestCode=${manifestCode}&returnAllDetails=${returnAllDetails}`;

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      return response.data.model;
    }
  } catch (error) {}

  return null;
};

export const getManifestDetails = async (
  masterShipmentHeaderID: string
): Promise<ResponseInterface<ManifestDetailInterface[]>> => {
  const uri = `${baseURLAPI}/Manifiest/GetManifestDetails?masterShipmentHeaderID=${masterShipmentHeaderID}`;

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return {
    message: "",
    model: null,
    didError: true,
    errorMessage: "Hubo un error obteniendo los detalles del manifiesto",
  };
};

export const getVehicles = async (): Promise<Vehicle[] | null> => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let uri = `${baseURLAPI}/Transports`;

  try {
    const response = await api.get(uri, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return null;
};

export const getDrivers = async (): Promise<AccountDriver[] | null> => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let uri = `${baseURLAPI}/Account/GetAll`;

  const body = JSON.stringify({
    AccountTypeID: 9,
  });

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return response.data.model;
    }
  } catch (error) {}

  return null;
};

export const getPlatforms = async (): Promise<BusinessUnitDTO[] | null> => {
  let uri = `${baseURLAPI}/BusinessUnit?logisticTrack=true&typeID=40`;

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return null;
};

export const getRoute = async (bucode: string): Promise<Route[] | null> => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let uri = `${baseURLAPI}/Routes/GetAll?BUCodeSource=${bucode}`;

  try {
    const response = await api.post(uri, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return response.data.model;
    }
  } catch (error) {}

  return null;
};

export function createObjectManifestHeader(
  form: DispatchManifestForm,
  userLogin: string
): ManifestHeaderInterface {
  const manifestHeader: ManifestHeaderInterface = {
    buCodeSource: form.buCodeSource,
    buSource: form.buSource,
    buCodeDestination: form.buCodeConsignee,
    manifestTypeID: form.manifestType,
    routeID: form.routeID as number,
    route: form.route,
    transportID: form.vehicleID,
    driver1ID: form.driver1ID,
    seal1: form.seal1,
    seal2: form.seal2,
    seal3: form.seal3,
    creationUser: userLogin,
    masterShipmentHeaderObservation: form.masterShipmentHeaderObservation,
    statusID: ManifestStatus.POSTERGADO,
  };

  return manifestHeader;
}

export function createObjectManifestDetail(
  rowDetail: ManifestDetailSingleInterface[],
  masterShipmentHeaderID: string,
  user: string,
  buCode: string,
  applyFilter: boolean = true
): ManifestDetailInterface[] {
  return rowDetail
    .filter((item) => !applyFilter || !item.containerDetailID)
    .map((item) => ({
      masterShipmentHeaderID: masterShipmentHeaderID,
      barCode: item.barcode ?? "",
      shipmentNumber: item.shipmentNumber,
      containerDetailID: item.containerDetailID,
      pieceNumber: item.pieceNumber,
      totalPieces: item.totalPieces,
      physicalWeight: item.physicalWeight,

      buCodeSource: item.buCodeSource,
      locationDestinationCode: item.locationDestinationCode,
      lineSeqNumber: item.lineSeqNumber,
      serviceID: item.serviceID,
      serviceCode: item.serviceCode,
      serviceName: item.serviceName,
      creationUser: user,
      creationDate: item.creationDate || Date(),
      applicationID: 10,
      buBase: buCode,
      manifestTypeID: item.manifestType,
      statusID: item.statusID,

      daysCreation: item.daysCreation,
      difference: item.difference,
      incidence: item.incidence,
      incidenceWarning: item.incidenceWarning,
      warnings: item.warnings,
      errors: item.errors,
    }));
}

export function createObjectManifestDetailSingle(
  rowDetail: ManifestDetailInterface[],
  manifestType: ManifestType
): ManifestDetailSingleInterface[] {
  return rowDetail.map((item) => ({
    containerDetailID: item.containerDetailID,
    shipmentDetailID: item.shipmentDetailID,
    serviceID: item.serviceID,
    serviceCode: item.serviceCode,
    serviceName: item.serviceName,
    buCodeSource: item.buCodeSource,
    // buCodeDestination: string;
    locationDestinationCode: item.locationDestinationCode,
    shipmentNumber: item.shipmentNumber,
    pieceNumber: item.pieceNumber,
    totalPieces: item.totalPieces,
    physicalWeight: item.physicalWeight,
    barcode: item.barCode,
    lineSeqNumber: item.lineSeqNumber,
    difference: item.difference,
    statusID: item.statusID,
    manifestType: manifestType,
    creationDate: item.creationDate,
    daysCreation: item.daysCreation,
    incidence: item.incidence,
    incidenceWarning: item.incidenceWarning,
    warnings: item.warnings,
    errors: item.errors,
  }));
}

export function createObjectDispatchManifestForm(
  manifest: ManifestViewModel
): DispatchManifestForm {
  const dispatchManifestForm: DispatchManifestForm = {
    masterShipmentHeaderID: manifest.masterShipmentHeaderID ?? "",
    masterShipmentHeaderCode: manifest.masterShipmentHeaderCode,
    route: "",
    routeID: manifest.routeID,
    consigneeName: "",
    buCodeConsignee: manifest.buCodeDestination,
    vehicle: "",
    vehicleID: manifest.transportID ?? "",
    transportID: manifest.transportID ?? "",
    driver: "",
    driver1ID: manifest.driver1ID,
    seal1: manifest.seal1 ?? "",
    seal2: manifest.seal2 ?? "",
    seal3: manifest.seal3 ?? "",
    shipmentNumber: "",
    piece: 0,
    manifestType: manifest.manifestTypeID,
    buCodeSource: manifest.buCodeSource,
    buSource: "",
    masterShipmentHeaderObservation: manifest.masterShipmentHeaderObservation,
    statusID: manifest.statusID,
    totalShipments: manifest.totalShipments,
    totalPieces: manifest.totalPieces,
    masterShipmentHeaderIDDispatch: manifest.affectingManifests
      ? manifest.affectingManifests[0].masterShipmentHeaderID
      : "",
    masterShipmentHeaderCodeDispatch: manifest.affectingManifests
      ? manifest.affectingManifests[0].masterShipmentHeaderCode
      : "",
  };

  return dispatchManifestForm;
}

export function createObjectDispManiFromManifestHeader(
  manifest: ManifestHeaderInterface
): DispatchManifestForm {
  // Obtener guías únicas
  let uniqueShipments = new Set(
    manifest.manifestDetail?.map((item) => item.shipmentNumber)
  );

  // Calcular totalShipments (cantidad de guías únicas)
  let totalShipments = uniqueShipments.size;

  // total de piezas
  let totalPiecesCount = manifest?.manifestDetail?.length ?? 0;

  const dispatchManifestForm: DispatchManifestForm = {
    masterShipmentHeaderID: manifest.masterShipmentHeaderID ?? "",
    masterShipmentHeaderCode: manifest.masterShipmentHeaderCode ?? "",
    // route: "",
    route: manifest.route,
    routeID: manifest.routeID,
    consigneeName: manifest.consigneeName ?? "",
    buCodeConsignee: manifest.buCodeDestination,
    vehicle: manifest.transportPlate + " - " + manifest.transportModelName, // se usa la propiedad solo en recepcion
    vehicleID: manifest.transportID ?? "",
    transportID: manifest.transportID ?? "",
    transportPlate: manifest.transportPlate ?? "",
    transportModelName: manifest.transportModelName ?? "",
    driver: manifest.driver1IdentificationNumber + " - " + manifest.driver1Name, // se usa la propiedad solo en recepcion
    driver1IdentificationNumber: manifest.driver1IdentificationNumber ?? "",
    driver1Name: manifest.driver1Name ?? "",
    driver1ID: manifest.driver1ID,
    seal1: manifest.seal1 ?? "",
    seal2: manifest.seal2 ?? "",
    seal3: manifest.seal3 ?? "",
    shipmentNumber: "",
    piece: 0,
    manifestType: manifest.manifestTypeID,
    buCodeSource: manifest.buCodeSource,
    buSource: manifest.buSource, // se usa la propiedad solo en recepcion
    masterShipmentHeaderObservation:
      manifest.masterShipmentHeaderObservation ?? "",
    statusID: manifest.statusID,
    manifestClosedDate: manifest.manifestClosedDate,
    totalShipments: totalShipments ?? 0,
    totalPieces: totalPiecesCount ?? 0,
    masterShipmentHeaderIDDispatch:
      manifest.masterShipmentAssociationList &&
      manifest.masterShipmentAssociationList.length > 0
        ? manifest.masterShipmentAssociationList[0].masterShipmentHeaderID
        : "",
    masterShipmentHeaderCodeDispatch:
      manifest.masterShipmentAssociationList &&
      manifest.masterShipmentAssociationList.length > 0
        ? manifest.masterShipmentAssociationList[0].masterShipmentHeaderCode
        : "",
  };

  return dispatchManifestForm;
}

export const createManifestHeader = async (
  manifestHeader: ManifestHeaderInterface,
  affectingManifests: [string] | [] = []
): Promise<ResponseInterface<ManifestHeaderInterface>> => {
  const body = JSON.stringify({
    manifestHeader,
    affectingManifests,
  });

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/Manifiest/Create`;

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }

  return {
    message: "",
    model: null,
    didError: true,
    errorMessage: "Hubo un error al actualizar la guías",
  };
};

export const createManifestDetail = async (
  manifestDetail: ManifestDetailInterface[]
): Promise<ResponseInterface<ManifestDetailInterface[]>> => {
  const body = JSON.stringify(manifestDetail);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/Manifiest/InsertDetailList`;

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }

  return {
    message: "",
    model: null,
    didError: true,
    errorMessage: "Hubo un error insertando registros del manifiesto",
  };
};

export const updateDetailStatusDeleted = async (
  masterShipmentHeaderID: string,
  containerDetailID: string,
  userLogin: string,
  shipmentNumber: string
): Promise<ResponseInterface<boolean>> => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const uri = `${baseURLAPI}/Manifiest/UpdateDetailStatusDeleted?manifestID=${masterShipmentHeaderID}&itemDetailID=${containerDetailID}&userLogin=${userLogin}&shipmentNumber=${shipmentNumber}`;

  try {
    const response = await api.post(uri, null, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }

  return {
    message: "",
    model: null,
    didError: true,
    errorMessage: "Hubo un error eliminando la guía del manifiesto",
  };
};

export const updateSingleDetailStatusDeleted = async (
  containerDetailID: string,
  userLogin: string,
): Promise<ResponseInterface<boolean>> => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const uri = `${baseURLAPI}/Manifiest/UpdateSingleDetailStatusDeleted?itemDetailID=${containerDetailID}&userLogin=${userLogin}`;

  try {
    const response = await api.post(uri, null, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }

  return {
    message: "",
    model: null,
    didError: true,
    errorMessage: "Hubo un error eliminando la guía del manifiesto",
  };
};

export const updateManifestHeader = async (
  manifestHeader: ManifestHeaderUpdateInterface
): Promise<ResponseInterface<boolean>> => {
  const body = JSON.stringify(manifestHeader);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/Manifiest/UpdateHeader`;

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    return response.data;
  } catch (error) {
    return {
      message: "",
      model: null,
      didError: true,
      errorMessage: "Error actualizando los datos del manifiesto",
    };
  }
};

export const postponeManifest = async (
  masterShipmentHeaderID: string,
  userLogin: string
): Promise<ResponseInterface<boolean>> => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/Manifiest/Postpone?manifestID=${masterShipmentHeaderID}&userLogin=${userLogin}`;

  try {
    const response = await api.post(uri, null, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }

  return {
    message: "",
    model: null,
    didError: true,
    errorMessage: "Hubo un error postergando el manifiesto",
  };
};

export const generateManifest = async (
  masterShipmentHeaderID: string,
  userLogin: string,
  observation: string
): Promise<ResponseInterface<ManifestDetailInterface[]>> => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/Manifiest/Generate?masterShipmentHeaderID=${masterShipmentHeaderID}&userLogin=${userLogin}&observation=${observation}`;

  try {
    const response = await api.post(uri, null, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }

  return {
    message: "",
    model: null,
    didError: true,
    errorMessage: "Hubo un error generando el manifiesto",
  };
};

export const validationManifestDetail = async (
  detailPendingValidation: ManifestDetailSingleInterface[],
  buCode: string,
  manifestType: ManifestType
): Promise<ResponseInterface<ManifestDetailSingleInterface[]>> => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/v2/Shipment/ValidateShipmentsManifest`;

  const shipmentsNumbers: ShipmentDetailValidate[] =
    detailPendingValidation.map((detail) => ({
      shipmentHeaderID: null,
      shipmentNumber: detail.shipmentNumber,
      shipmentDetailID: null,
      pieceNumber: detail.pieceNumber,
      incidence: ManifestIncidence.NOT_VALIDATED,
      warnings: "",
      errors: "",
    }));

  try {
    const response = await axios.post(
      uri,
      {
        buCode,
        manifestType,
        shipmentsNumbers,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        cancelToken: source.token,
      }
    );
    return response.data;
  } catch (error) {
  }

  return {
    message: "",
    didError: false,
    errorMessage: "",
    model: detailPendingValidation.map((d) => ({
      ...d,
      incidence: ManifestIncidence.CRITIC_ERROR,
      errors: "Hubo un error en el servidor validando las piezas"
    })),
  }
};

export const downloadManifest = async (
  manifestCode: string,
  type: number,
  userPrint: string,
  printType: number
) => {
  try {
    const uri = `${baseURLAPI}/manifiest/DownloadManifiest?`;

    const response = await axios.get(
      `${uri}manifestCode=${manifestCode}&type=${type}&userPrint=${userPrint}&printType=${printType}`,
      {
        responseType: "blob",
      }
    );

    // Extraer el nombre del archivo del header 'content-disposition'
    const contentDisposition = response.headers["content-disposition"];
    const fileNameMatch =
      contentDisposition && contentDisposition.match(/filename="(.+)"/);
    const fileName = fileNameMatch
      ? fileNameMatch[1]
      : `${manifestCode}_Manifiesto${type === 1 ? "_Agrupado" : "_Detallado"}.${
          printType === 1 ? "pdf" : "xlsx"
        }`;

    if (printType === 1) {
      // Mostrar PDF en una nueva ventana
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      const newWindow = window.open(url);
      if (newWindow) {
        newWindow.focus();
      } else {
        alertService.error(
          "No se pudo abrir el documento pdf. Asegúrate de que los pop-ups no estén bloqueados"
        );
      }
    } else {
      // Descargar archivo Excel
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (error) {
    alertService.error(`Error descargando el archivo: ${error}`);
  }
};

export const updateManifestStatus = async (
  masterShipmentHeaderID: string,
  manifestStatus: ManifestStatus,
  userLogin: string
): Promise<ResponseInterface<boolean>> => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/Manifiest/UpdateStatus?manifestID=${masterShipmentHeaderID}&newStatus=${manifestStatus}&userLogin=${userLogin}`;

  try {
    const response = await api.post(uri, null, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }

  return {
    message: "",
    model: null,
    didError: true,
    errorMessage: "Hubo un error postergando el manifiesto",
  };
};

export default downloadManifest;
