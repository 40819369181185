import api from "./api";
import { AttachmentResponseInterface, ResponseInterface } from "../interfaces";

const baseURL = `${process.env.REACT_APP_API_HOST}/Attachment`;

export const getAttachment = async (
  AttachmentID: string
): Promise<ResponseInterface<AttachmentResponseInterface>> => {
  try {
    const response = await api.get(baseURL, { params: { AttachmentID } });
    if (response.status === 200) {
      return response.data;
    }
    return {
      model: null,
      didError: true,
      message: "",
      errorMessage: "Server error: " + response.status,
    };
  } catch (error) {
    return (error as any)?.response?.data;
  }
};
