import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { FormSearch, FormTextInput } from "../../components/FormFields";
import { FC, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setDispatchCleaning,
  setDispatchForm,
  setDispatchHeader,
} from "../../store/slices/dispatch";
import {
  AccountDriver,
  ManifestHeaderInterface,
  ManifestHeaderUpdateInterface,
  ManifestStatus,
  ManifestType,
  Route,
  Vehicle,
} from "../../interfaces/ManifestInterface";
import {
  createManifestHeader,
  getDrivers,
  getPlatforms,
  getRoute,
  getVehicles,
  updateManifestHeader,
} from "../../services/manifestServices";
import { BusinessUnitDTO } from "../../interfaces/Dtos";
import { PrimaryButton } from "../../components/Buttons";
import { alertService, loaderService } from "../../services";
import classNames from "classnames";

export interface DispatchManifestFormValues {
  route: Route | null;
  consignee: BusinessUnitDTO | null;
  vehicle: Vehicle | null;
  driver: AccountDriver | null;
  seal1: string;
  seal2: string;
  seal3: string;
  observation: string;
}

interface FormProps {
  editMode: boolean;
  setEditMode: (b: boolean) => void;
  formik: FormikProps<DispatchManifestFormValues>;
}
const Form: FC<FormProps> = ({ editMode, setEditMode, formik }) => {
  const dispatch = useAppDispatch();
  const loading = loaderService.useIsLoading();
  const user = useAppSelector((state) => state.user);
  const cleaning = useAppSelector((state) => state.dispatch.cleaning);

  const [driverList, setDriverList] = useState<AccountDriver[]>([]);
  const [driverSearch, setDriverSearch] = useState("");
  const [consigneeSearch, setConsigneeSearch] = useState("");
  const [routeSearch, setRouteSearch] = useState("");
  const [vehicleList, setVehicleList] = useState<Vehicle[]>([]);
  const [vehicleSearch, setVehicleSearch] = useState("");

  const filteredVehicleList = useMemo(() => {
    const tokens = vehicleSearch
      .toLowerCase()
      .split(" ")
      .filter((t) => t !== "-");

    return vehicleList.filter((v) =>
      tokens.every(
        (t) =>
          v.transportPlate.toLowerCase().includes(t) ||
          v.transportModel.toLowerCase().includes(t)
      )
    );
  }, [vehicleList, vehicleSearch]);

  const filteredDriverList = useMemo(() => {
    const tokens = driverSearch
      .toLowerCase()
      .split(" ")
      .filter((t) => t !== "-");

    return driverList.filter((v) =>
      tokens.every(
        (t) =>
          v.identificationNumber.toLowerCase().includes(t) ||
          v.accountFullName.toLowerCase().includes(t)
      )
    );
  }, [driverList, driverSearch]);

  const handleSealChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.toUpperCase();
    formik.handleChange(e);
  };

  useEffect(() => {
    dispatch(setDispatchForm(formik.values));
  }, [formik.values, dispatch]);

  useEffect(() => {
    if (cleaning) {
      formik.setValues({
        ...formik.values,
        vehicle: null,
        driver: null,
        seal1: "",
        seal2: "",
        seal3: "",
        observation: "",
      });
      formik.setTouched({
        vehicle: false,
        driver: false,
        seal1: false,
        seal2: false,
        seal3: false,
        observation: false,
      });
      dispatch(setDispatchCleaning(false));
      setEditMode(true);
    }
  }, [cleaning, dispatch, formik, setEditMode]);

  useEffect(() => {
    const defaultRoute: Route = {
      buCodeConsignee: "",
      buConsigneeName: "",
      routeID: 0,
      routeName: "",
    };
    const buCode = user.businessUnit?.code;

    if (!buCode) {
      return;
    }

    const afunction = async () => {
      const routes = await getRoute(buCode as string);
      const route = routes && routes.length > 0 ? routes[0] : defaultRoute;
      formik.setFieldValue("route", route);
      setRouteSearch(route.routeName);
    };

    afunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.businessUnit?.code]);

  useEffect(() => {
    const route = formik.values.route;
    if (!route) {
      return;
    }

    const afunction = async () => {
      const platforms = await getPlatforms();
      if (!platforms) {
        return;
      }

      const platform = platforms.find(
        (p) => p.buCode === route.buCodeConsignee
      );
      if (!platform) {
        formik.setFieldValue("consignee", null);
        setConsigneeSearch("");
      } else {
        formik.setFieldValue("consignee", platform);
        setConsigneeSearch(platform.buName.toUpperCase());
      }
    };

    afunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.route]);

  useEffect(() => {
    const afunction = async () => {
      const vechileList = await getVehicles();
      setVehicleList(vechileList ?? []);
    };

    afunction();
  }, []);

  useEffect(() => {
    const afunction = async () => {
      const driverList = await getDrivers();
      setDriverList(driverList ?? []);
    };

    afunction();
  }, []);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="flex flex-col bg-white gap-8 rounded-lg border px-8 pb-6 pt-4"
    >
      <div className="flex flex-col sm:flex-row w-full gap-4">
        <div className="flex flex-1 gap-4">
          <div className="relative flex-1">
            <p className="font-medium leading-6 text-gray-900">Ruta</p>
            <p className="truncate mt-1 text-sm">{routeSearch}</p>
          </div>

          <div className="relative flex-1">
            <p className="font-medium leading-6 text-gray-900">Plataforma</p>
            <p className="truncate mt-1 text-sm">{consigneeSearch}</p>
          </div>
        </div>

        <div className="flex flex-1 gap-4">
          <div className="relative flex-1">
            {editMode && (
              <FormSearch
                label="Vehículo"
                name="vehicle"
                value={vehicleSearch}
                error={formik.touched.vehicle ? formik.errors.vehicle : ""}
                onChange={(e) => setVehicleSearch(e.target.value)}
                options={filteredVehicleList}
                onSelectOption={(option) => {
                  formik.setFieldValue("vehicle", option);
                  setVehicleSearch(
                    `${option.transportPlate} - ${option.transportModel}`
                  );
                }}
                RenderOption={({ option }) => (
                  <p>
                    {" "}
                    {`${option.transportPlate} - ${option.transportModel}`}{" "}
                  </p>
                )}
                onChangeFocus={(focus) => {
                  const vehicle = formik.values.vehicle;
                  if (!focus) {
                    if (vehicle) {
                      setVehicleSearch(
                        `${vehicle.transportPlate} - ${vehicle.transportModel}`
                      );
                    } else {
                      setVehicleSearch("");
                    }
                  }
                }}
                placeholder="Buscar vehículo"
              />
            )}

            {!editMode && (
              <>
                <p className="font-medium leading-6 text-gray-900">Vehículo</p>
                <p className="truncate mt-1 text-sm">{vehicleSearch}</p>
              </>
            )}
          </div>

          <div className="relative flex-1">
            {editMode && (
              <FormSearch
                label="Conductor"
                name="driver"
                value={driverSearch}
                error={formik.touched.driver ? formik.errors.driver : ""}
                onChange={(e) => setDriverSearch(e.target.value)}
                options={filteredDriverList}
                onSelectOption={(option) => {
                  formik.setFieldValue("driver", option);
                  setDriverSearch(
                    `${option.identificationNumber} - ${option.accountFullName}`
                  );
                }}
                RenderOption={({ option }) => (
                  <p>
                    {`${option.identificationNumber} - ${option.accountFullName}`}
                  </p>
                )}
                onChangeFocus={(focus) => {
                  const driver = formik.values.driver;
                  if (!focus) {
                    if (driver) {
                      setDriverSearch(
                        `${driver.identificationNumber} - ${driver.accountFullName}`
                      );
                    } else {
                      setDriverSearch("");
                    }
                  }
                }}
                placeholder="Buscar conductor"
              />
            )}

            {!editMode && (
              <>
                <p className="font-medium leading-6 text-gray-900">Conductor</p>
                <p className="truncate mt-1 text-sm">{driverSearch}</p>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex flex-col sm:flex-row flex-1 gap-4">
          <div className="flex-1">
            {editMode && (
              <FormTextInput
                id="seal1"
                name="seal1"
                label="Precinto 1"
                value={formik.values.seal1}
                error={formik.touched.seal1 ? formik.errors.seal1 : ""}
                onChange={handleSealChange}
                placeholder="Introduzca el precinto 1"
                maxLength={25}
              />
            )}

            {!editMode && (
              <>
                <p className="font-medium leading-6 text-gray-900">
                  Precinto 1
                </p>
                <p className="truncate mt-1 text-sm">{formik.values.seal1}</p>
              </>
            )}
          </div>

          <div className="flex-1">
            {editMode && (
              <FormTextInput
                id="seal2"
                name="seal2"
                label="Precinto 2"
                value={formik.values.seal2}
                error={formik.touched.seal2 ? formik.errors.seal2 : ""}
                onChange={handleSealChange}
                placeholder="Introduzca el precinto 2"
                maxLength={25}
              />
            )}

            {!editMode && (
              <>
                <p className="font-medium leading-6 text-gray-900">
                  Precinto 2
                </p>
                <p className="truncate mt-1 text-sm">
                  {formik.values.seal2 ? formik.values.seal2 : "N/D"}
                </p>
              </>
            )}
          </div>

          <div className="flex-1">
            {editMode && (
              <FormTextInput
                id="seal3"
                name="seal3"
                label="Precinto 3"
                value={formik.values.seal3}
                error={formik.touched.seal3 ? formik.errors.seal3 : ""}
                onChange={handleSealChange}
                placeholder="Introduzca el precinto 3"
                maxLength={25}
              />
            )}

            {!editMode && (
              <>
                <p className="font-medium leading-6 text-gray-900">
                  Precinto 3
                </p>
                <p className="truncate mt-1 text-sm">
                  {formik.values.seal3 ? formik.values.seal3 : "N/D"}
                </p>
              </>
            )}
          </div>
        </div>

        <div className="flex-1">
          {editMode && (
            <FormTextInput
              id="observation"
              name="observation"
              label="Observación"
              value={formik.values.observation}
              onChange={formik.handleChange}
              placeholder="Introduzca la observación"
              maxLength={150}
            />
          )}

          {!editMode && (
            <>
              <p className="font-medium leading-6 text-gray-900">Observación</p>
              <p className="mt-1 text-sm">{formik.values.observation}</p>
            </>
          )}
        </div>
      </div>

      <div className="flex w-full justify-end mt-4">
        <PrimaryButton
          className={classNames(!editMode && "hidden")}
          disabled={loading}
        >
          Guardar cabecera
        </PrimaryButton>

        <PrimaryButton
          type="button"
          className={classNames(editMode && "hidden")}
          onClick={() => setEditMode(true)}
        >
          Editar cabecera
        </PrimaryButton>
      </div>
    </form>
  );
};

const DispatchManifestForm: FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const dispatchForm = useAppSelector((state) => state.dispatch.dispatchForm);
  const dispatchHeader = useAppSelector(
    (state) => state.dispatch.dispatchHeader
  );

  const [editMode, setEditMode] = useState(true);

  const validationSchema = Yup.object().shape({
    vehicle: Yup.object().required("El vehículo es requerido"),
    driver: Yup.object().required("El conductor es requerido"),
    seal1: Yup.string().required("El primer precinto es requerido"),
  });

  const handleSubmit = async (values: DispatchManifestFormValues) => {
    const userLogin = user.user?.userLogin;
    const buSource = user.businessUnit;
    const route = values.route;
    const consignee = values.consignee;
    const vehicle = values.vehicle;
    const driver = values.driver;

    if (
      !route ||
      !consignee ||
      !vehicle ||
      !driver ||
      !userLogin ||
      !buSource
    ) {
      return;
    }

    // Create new dispatch manifest
    if (!dispatchHeader) {
      const manifestHeader: ManifestHeaderInterface = {
        buCodeSource: buSource.code,
        buSource: "",
        buCodeDestination: consignee.buCode,
        manifestTypeID: ManifestType.DESPACHO,
        routeID: route.routeID,
        route: route.routeName,
        transportID: vehicle.transportID ?? "",
        driver1ID: driver.accountID,
        seal1: values.seal1,
        seal2: values.seal2,
        seal3: values.seal3,
        creationUser: userLogin,
        masterShipmentHeaderObservation: values.observation,
        statusID: ManifestStatus.POSTERGADO,
      };
      const newManifestHeader = await createManifestHeader(manifestHeader);

      if (
        newManifestHeader.didError ||
        !newManifestHeader.model ||
        !newManifestHeader.model.masterShipmentHeaderID
      ) {
        alertService.error(
          "Hubo un error creando el despacho",
          newManifestHeader.errorMessage
        );
        return;
      }

      dispatch(setDispatchHeader(newManifestHeader.model));
      setEditMode(false);
    }

    // Update current dispatch manifest
    else {
      const manifestHeader: ManifestHeaderUpdateInterface = {
        masterShipmentHeaderID: dispatchHeader.masterShipmentHeaderID!,
        transportID: vehicle.transportID ?? "",
        driver1ID: driver.accountID,
        seal1: values.seal1,
        seal2: values.seal2,
        seal3: values.seal3,
        masterShipmentHeaderObservation: values.observation,
        updateUser: userLogin,
      };
      const updatedManifestHeader = await updateManifestHeader(manifestHeader);

      if (updatedManifestHeader.didError || !updatedManifestHeader.model) {
        alertService.error(
          "Hubo un error actualizando el despacho",
          updatedManifestHeader.errorMessage
        );
        return;
      }

      dispatch(
        setDispatchHeader({
          ...dispatchHeader,
          transportID: vehicle.transportID ?? "",
          driver1ID: driver.accountID,
          seal1: values.seal1,
          seal2: values.seal2,
          seal3: values.seal3,
          masterShipmentHeaderObservation: values.observation,
        })
      );
      setEditMode(false);
    }
  };

  useEffect(() => {
    setEditMode(!dispatchHeader);
  }, [dispatchHeader]);

  return (
    <Formik<DispatchManifestFormValues>
      initialValues={dispatchForm}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        loaderService.start();
        await handleSubmit(values);
        loaderService.stop();
      }}
    >
      {(formik) => (
        <Form editMode={editMode} setEditMode={setEditMode} formik={formik} />
      )}
    </Formik>
  );
};

export default DispatchManifestForm;
