import { FC, useEffect, useState } from "react";
import {
  DocumentTextIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { PrimaryButton, SecondaryButton } from "../components/Buttons";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import HorizontalPadding from "../components/HorizontalPadding";
import { useNavigate } from "react-router-dom";
import ReceiptDraftTable from "./Manifest/ReceiptDraftTable";
import ReceiptGeneratedTable from "./Manifest/ReceiptGeneratedTable";
import {
  ManifestHeaderInterface,
  ManifestStatus,
  ManifestType,
} from "../interfaces/ManifestInterface";
import {
  getManifestPostponed,
  updateManifestStatus,
} from "../services/manifestServices";
import moment from "moment";
import Modal from "../components/Modal";
import { alertService, loaderService } from "../services";
import { clearReceiptValues } from "../store/slices/receipt";

const Receipt: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = loaderService.useIsLoading();
  const user = useAppSelector((state) => state.user);

  const [loadingReceipts, setLoadingReceipts] = useState(true);
  const [openNewDispatchWarning, setOpenNewDispatchWarning] = useState(false);
  const [postponedReceiptList, setPostponedReceiptList] = useState<
    ManifestHeaderInterface[]
  >([]);

  const handleNewManifestClick = () => {
    if (postponedReceiptList.length > 0) {
      setOpenNewDispatchWarning(true);
      return;
    }

    dispatch(clearReceiptValues());
    navigate("/receipt/create");
  };

  const handleNewManifest = async () => {
    const userLogin = user.user?.userLogin;

    if (!userLogin) {
      alertService.error(
        "No hay un nombre de usuario asociado a la sesión actual"
      );
      return;
    }

    for (const receipt of postponedReceiptList) {
      const masterShipmentHeaderID = receipt.masterShipmentHeaderID;

      if (!masterShipmentHeaderID) {
        continue;
      }

      const response = await updateManifestStatus(
        masterShipmentHeaderID,
        ManifestStatus.INACTIVO,
        userLogin
      );

      if (response.didError) {
        alertService.error(
          `Hubo un error anulando el manifiesto ${receipt.masterShipmentHeaderCode}`,
          response.errorMessage
        );
        return;
      }
    }

    dispatch(clearReceiptValues());
    navigate("/receipt/create");
  };

  useEffect(() => {
    const buCode = user.businessUnit?.code;

    if (!buCode) {
      return;
    }

    const afunction = async () => {
      const postponedReceiptList = await getManifestPostponed(
        ManifestType.RECEPCION,
        moment().subtract(3, "days").toDate(),
        moment().toDate(),
        null,
        buCode
      );

      if (postponedReceiptList.didError || !postponedReceiptList.model) {
        setLoadingReceipts(false);
        return;
      }

      setPostponedReceiptList(
        postponedReceiptList.model.filter(
          (m) => m.statusID === ManifestStatus.POSTERGADO
        )
      );
      setLoadingReceipts(false);
    };

    afunction();
  }, [user]);

  return (
    <main className="lg:pl-72 pb-32">
      <div className="py-6 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32">
        <header className="ml-4 text-2xl font-bold text-gray-700 ">
          Manifiesto de Recepción
        </header>

        <PrimaryButton
          disabled={loading || loadingReceipts}
          onClick={handleNewManifestClick}
          className="pr-4"
        >
          <DocumentTextIcon
            className="h-5 w-5 flex-none text-gray-200 mr-1"
            aria-hidden="true"
          />
          Nuevo
        </PrimaryButton>
      </div>

      <HorizontalPadding paddingTop>
        <div className="flex flex-col w-full gap-12">
          <ReceiptDraftTable
            loadingReceipts={loadingReceipts}
            postponedReceiptList={postponedReceiptList}
          />

          <ReceiptGeneratedTable />
        </div>
      </HorizontalPadding>

      <Modal
        openModal={openNewDispatchWarning}
        setOpenModal={setOpenNewDispatchWarning}
      >
        <div className="flex flex-col gap-4 max-w-md mx-auto items-center">
          <div className="flex flex-col items-center">
            <ExclamationTriangleIcon className="text-orange-500 h-28 w-28 mx-auto opacity-90" />
            <h2 className="text-xl font-semibold leading-7 text-gray-700 text-center mt-2">
              Crear nueva recepción
            </h2>
          </div>

          <div className="flex flex-col gap-1">
            <p className="text-gray-600 text-center whitespace-pre-line">
              Ya existen recepciones postergados, crear uno nuevo anulará los
              existentes ¿Estás seguro de que quieres continuar con el proceso?
            </p>
          </div>

          <div className="flex w-full flex-row gap-3 items-center justify-between mt-4">
            <SecondaryButton
              onClick={() => setOpenNewDispatchWarning(false)}
              className="px-4 py-2"
            >
              Cancelar
            </SecondaryButton>

            <PrimaryButton
              onClick={async () => {
                loaderService.start();
                await handleNewManifest();
                loaderService.stop();
                setOpenNewDispatchWarning(false);
              }}
              className="px-4 py-2"
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </main>
  );
};

export default Receipt;
