import classNames from "classnames";
import { FC, useEffect, useMemo, useState } from "react";
import LoadingIcon from "../../components/LodingIcon";
import ManifestHeaderTable from "./ManifestHeaderTable";
import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import {
  AccountDriver,
  ManifestHeaderInterface,
  ManifestIncidence,
  ManifestStatus,
  ManifestType,
  Vehicle,
} from "../../interfaces/ManifestInterface";
import {
  createPendingDetails,
  removeAllProcessedDetails,
  setDispatchForm,
  setDispatchHeader,
  setDispatchLoadFromDB,
} from "../../store/slices/dispatch";
import { alertService, loaderService } from "../../services";
import {
  createObjectManifestDetailSingle,
  getDrivers,
  getManifestDetails,
  getVehicles,
} from "../../services/manifestServices";

interface DispatchDraftTableProps {
  loadingDispatches: boolean;
  postponedDispatchList: ManifestHeaderInterface[];
}
const DispatchDraftTable: FC<DispatchDraftTableProps> = ({ loadingDispatches, postponedDispatchList }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loadingDrivers, setLoadingDrivers] = useState(true);
  const [loadingVehicles, setLoadingVehicles] = useState(true);
  const [vehicleList, setVehicleList] = useState<Vehicle[]>([]);
  const [driverList, setDriverList] = useState<AccountDriver[]>([]);

  const handleClickPostponedManifest = async (
    manifest: ManifestHeaderInterface
  ) => {
    loaderService.start();
    const masterShipmentHeaderID = manifest.masterShipmentHeaderID;

    if (!masterShipmentHeaderID) {
      alertService.error("El manifiesto parece no estar registrado.");
      loaderService.stop();
      return;
    }

    const details = await getManifestDetails(masterShipmentHeaderID);

    if (details.didError || details.model === null) {
      alertService.error(
        "Hubo un error obteniendo los detalles del manifiesto."
      );
      loaderService.stop();
      return;
    }

    const filteredDetails = details.model.filter(
      (d) => d.statusID !== ManifestStatus.ELIMINADO
    );

    const dispatchDetails = createObjectManifestDetailSingle(
      filteredDetails.map((d) => ({
        ...d,
        incidence: ManifestIncidence.NOT_VALIDATED,
        errors: "",
        warnings: "",
        incidenceWarning: false,
      })),
      ManifestType.DESPACHO
    );

    const vehicle =
      vehicleList.find((v) => v.transportID === manifest.transportID) ?? null;
    const driver =
      driverList.find((d) => d.accountID === manifest.driver1ID) ?? null;

    dispatch(setDispatchLoadFromDB(true));
    dispatch(createPendingDetails(dispatchDetails));
    dispatch(removeAllProcessedDetails());
    dispatch(
      setDispatchHeader({
        ...manifest,
        seal1: manifest.seal1 ?? "",
        seal2: manifest.seal2 ?? "",
        seal3: manifest.seal3 ?? "",
        masterShipmentHeaderObservation:
          manifest.masterShipmentHeaderObservation ?? "",
      })
    );
    dispatch(
      setDispatchForm({
        route: null,
        consignee: null,
        vehicle,
        driver,
        seal1: manifest.seal1 ?? "",
        seal2: manifest.seal2 ?? "",
        seal3: manifest.seal3 ?? "",
        observation: manifest.masterShipmentHeaderObservation ?? "",
      })
    );

    loaderService.stop();
    navigate("/dispatch/create");
  };

  const loadingData = useMemo(() => {
    return loadingDispatches || loadingDrivers || loadingVehicles;
  }, [loadingDispatches, loadingDrivers, loadingVehicles]);

  useEffect(() => {
    const afunction = async () => {
      const vechileList = await getVehicles();
      setVehicleList(vechileList ?? []);
      setLoadingDrivers(false);
    };

    afunction();
  }, []);

  useEffect(() => {
    const afunction = async () => {
      const driverList = await getDrivers();
      setDriverList(driverList ?? []);
      setLoadingVehicles(false);
    };

    afunction();
  }, []);

  return (
    <div
      className={classNames(
        "flex flex-1 flex-col bg-white gap-4 rounded-lg border px-6 py-6 w-full"
      )}
    >
      <h1 className="text-2xl font-semibold text-gray-800 truncate mb-2">
        Despachos en Borrador
      </h1>
      <div
        className={classNames(
          "flex flex-1 flex-row bg-white gap-4 rounded-lg justify-center items-center"
        )}
      >
        {loadingData && (
          <div className="my-12">
            <LoadingIcon size="3.5rem" />
          </div>
        )}

        {!loadingData && (
          <ManifestHeaderTable
            manifests={postponedDispatchList}
            onManifestClick={handleClickPostponedManifest}
          />
        )}
      </div>
    </div>
  );
};

export default DispatchDraftTable;
