import { FC, useEffect, useMemo, useState } from "react";
import Modal from "../Modal";
import WarningModal from "../WarningModal";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { AccountInterface, ShipmentInterface } from "../../interfaces";
import FingerprintReader from "../FingerprintReader";
import WebcamCapture from "../Webcam";
import { getAttachment } from "../../services/attachmentServices";
import { useAppSelector } from "../../store/hooks";
import { base64ToFile } from "../../utils";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import {
  FormTextInput,
  FormTextAreaInput,
  FormFileUpload,
  FormSimpleRadioGroup,
} from "../FormFields";
import {
  alertService,
  getBUConfiguration,
  loaderService,
} from "../../services";
import {
  LiabilityWaiverGetResponse,
  LiabilityWaiverUpdateRequest,
} from "../../interfaces/Dtos/liabilityWaiverDTO";
import {
  getLiabilityWaiver,
  updateLiabilityWaiver,
} from "../../services/liabilityWaiverServices";

interface OriginModalProps {
  openModal: boolean;
  shipmentNumbers: string[];
  shipments: ShipmentInterface[];
  shipper: AccountInterface;
  isModification?: boolean;
  setExtFingerprint?: (extFingerprint: string | undefined) => void;
  setExtAttachments?: (setExtAttachments: string[]) => void;
  setExtName?: (extName: string | undefined) => void;
  setExtId?: (extId: string | undefined) => void;
  setExtObservation?: (extObservation: string | undefined) => void;
  setIsPhysical?: (isPhysical: boolean) => void;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export const OriginModal: FC<OriginModalProps> = ({
  openModal,
  setOpenModal,
  shipments,
  shipmentNumbers,
  shipper,
  isModification = false,
  setExtFingerprint,
  setExtAttachments,
  setExtName,
  setExtId,
  setExtObservation,
  setIsPhysical,
}) => {
  const user = useAppSelector((state) => state.user);
  const buCode = useAppSelector((state) => state.user.businessUnit?.code);
  const taxIdentificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );
  const loading = loaderService.useIsLoading();

  const [nameError, setNameError] = useState("");
  const [fingerprint, setFingerprint] = useState<string | undefined>();
  const [attachments, setAttachments] = useState<string[]>([""]);
  const [signatoryName, setSignatoryName] = useState<string>("");
  const [signatoryId, setSignatoryId] = useState<string>("");
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [idError, setIdError] = useState("");
  const [observation, setObservation] = useState<string>("");
  const [savedAttachments, setSavedAttachments] = useState<string[]>([]);
  const [openModalWarning, setOpenModalWarning] = useState(false);
  const [error, setError] = useState<string>("");
  const [savedFingerprint, setSavedFingerprint] = useState<
    string | undefined
  >();
  const [savedLiabilityWaver, setSavedLiabilityWaver] =
    useState<LiabilityWaiverGetResponse>();
  const [fingerprintTemplate, setFingerprintTemplate] = useState<
    string | undefined
  >();
  const [isDigitalLiabilityRequired, setIsDigitalLiabilityRequired] =
    useState<boolean>(false);
  const [attachmentModes, setAttachmentModes] = useState<string[]>(
    attachments.map(() => "capture")
  );


  const identificationType = useMemo(() => {
    return taxIdentificationTypes.find(
      (t) => t.taxIdentificationTypeId === shipper?.taxIdentificationTypeID
    );
  }, [shipper, taxIdentificationTypes]);

  const canSubmit = useMemo(() => {
    return (
      !loading &&
      signatoryName &&
      signatoryId &&
      signatoryId.length >= 7 &&
      (checkboxChecked || fingerprint)
    );
  }, [loading, signatoryName, signatoryId, checkboxChecked, fingerprint]);

  const canUpdate = useMemo(() => {
    return (
      signatoryName !== savedLiabilityWaver?.signatoryName ||
      (signatoryId !== savedLiabilityWaver?.signatoryIdentification &&
        signatoryId.length >= 7) ||
      observation !== savedLiabilityWaver?.observation ||
      checkboxChecked !== savedLiabilityWaver?.isPhysicalLiabilityWaiver ||
      fingerprint !== savedFingerprint ||
      attachments !== savedAttachments
    );
  }, [
    signatoryName,
    signatoryId,
    observation,
    checkboxChecked,
    fingerprint,
    attachments,
    savedLiabilityWaver,
    savedFingerprint,
    savedAttachments,
  ]);

  const handleAttachmentModeChange = (index: number, mode: string) => {
    const updatedModes = [...attachmentModes];
    updatedModes[index] = mode;
    setAttachmentModes(updatedModes);
  };

  const loadLiabilityWaiver = async () => {
    const liabilityWaiver = await getLiabilityWaiver(shipments[0].id!);

    if (liabilityWaiver.didError && isModification) {
      alertService.error("Error al cargar los datos de la declaración jurada");
      return;
    }

    if (liabilityWaiver.model !== null) {
      if (liabilityWaiver.model.observation === null)
        liabilityWaiver.model.observation = "";
      setSavedLiabilityWaver(liabilityWaiver.model);
      const liabilityData = liabilityWaiver.model;

      if (liabilityData.shipperFingerprintAttchID !== null) {
        const liabilityFingerprint = await getAttachment(
          liabilityData.shipperFingerprintAttchID
        );
        const url = `${liabilityFingerprint.model?.attachmentPath
          }?t=${new Date().getTime()}`;

        const response = await fetch(url);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          setSavedFingerprint(base64data as string);
          setFingerprint(base64data as string);
        };
      }

      if (liabilityData.shipperAttachmentIDs.length > 0) {
        const liabilityAttachment = await getAttachment(
          liabilityData.shipperAttachmentIDs[0]
        );
        const url = liabilityAttachment.model?.attachmentPath;
        const response = await fetch(url!);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          setSavedAttachments((prev) => [...(prev || []), base64data as string]);
          setAttachments((prev) => [...prev, base64data as string]);
        };
      }

      setSignatoryName(liabilityData.signatoryName);
      setSignatoryId(liabilityData.signatoryIdentification);
      setObservation(liabilityData.observation ?? "");
      setCheckboxChecked(liabilityData.isPhysicalLiabilityWaiver);
    }
  };

  const onUpdateLiabilityWaver = async () => {
    loaderService.start();
    const shipperId = shipments[0].shipper.identificationNumber;
    const shipmentHeaderID = shipments[0].id;

    const request: LiabilityWaiverUpdateRequest = {
      liabilityWaiverID: savedLiabilityWaver?.liabilityWaiverID!,
      updateUser: user.user?.userLogin!,
    };

    if (signatoryName !== savedLiabilityWaver?.signatoryName)
      request.signatoryName = signatoryName;

    if (signatoryId !== savedLiabilityWaver?.signatoryIdentification)
      request.signatoryIdentification = signatoryId;

    if (observation !== savedLiabilityWaver?.observation)
      request.observation = observation;

    if (checkboxChecked !== savedLiabilityWaver?.isPhysicalLiabilityWaiver)
      request.isPhysicalLiabilityWaiver = checkboxChecked;

    if (fingerprint !== savedFingerprint) {
      if (fingerprint && !savedFingerprint) {
        const file = base64ToFile(
          fingerprint,
          "image/png",
          `fingerprint-${shipperId}-${shipmentHeaderID}.png`
        );
        request.fingerprintToInsert = file;
      } else if (fingerprint && savedFingerprint) {
        const file = base64ToFile(
          fingerprint,
          "image/png",
          `${savedLiabilityWaver?.shipperFingerprintAttchID}.png`
        );
        request.attachmentToUpdate1 = file;
      }
    }

    if (attachments !== savedAttachments) {
      if (attachments && !savedAttachments) {
        const file = base64ToFile(
          attachments[0], // FIXME: attachments should be used to get several attachments
          "image/png",
          `attachment-${shipperId}-${shipmentHeaderID}.png`
        );
        request.attachmentToInsert1 = file;
      } else if (attachments && savedAttachments) {
        const file = base64ToFile(
          attachments[0], // FIXME: attachments should be used to get several attachments
          "image/png",
          `${savedLiabilityWaver?.shipperAttachmentIDs[0]}.png`
        );
        request.attachmentToUpdate2 = file;
      }
    }

    const response = await updateLiabilityWaiver(request);

    if (response.didError) {
      loaderService.stop();
      alertService.error("Error al actualizar la declaración jurada");
      return;
    }

    await loadLiabilityWaiver();

    loaderService.stop();
    alertService.success("Declaración jurada actualizada exitosamente");
    setOpenModal(false);
  };

  const onChangeCheckbox = (check: boolean) => {
    if (fingerprint || (attachments.length > 0 && attachments.some((a) => a !== ""))) {
      if (!checkboxChecked) {
        setOpenModalWarning(true);
      } else {
        setCheckboxChecked(false);
      }
    } else {
      setCheckboxChecked(check);
    }
  };

  const handleFileChange = (file: File[], index: number) => {
    // Verify that the file is an image
    if (file[0]?.type.startsWith("image/")) {
      setError("");
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onloadend = () => {
        const base64data = reader.result as string;
        const updatedAttachments = [...attachments];
        updatedAttachments[index] = base64data;
        setAttachments(updatedAttachments);
        setAttachmentModes((prev) => {
          const updatedModes = [...prev];
          updatedModes[index] = "capture";
          return updatedModes;
        });
      };
    } else {
      setError("El archivo debe ser una imagen");
    }
  };

  const addAttachmentSlot = () => {
    if (attachments.length < 5) {
      setAttachments([...attachments, ""]);
      setAttachmentModes([...attachmentModes, "capture"]);
    }
  };

  useEffect(() => {
    if (identificationType && !["J-", "G-"].includes(identificationType.abreviationName)) {
      setSignatoryName(shipper.accountFullName);
      setSignatoryId(shipper.identificationNumber);
    }
  }, [shipper, identificationType, setSignatoryName, setSignatoryId]);

  useEffect(() => {
    if (signatoryName === "")
      setNameError("El nombre del remitente es requerido");
    else if (signatoryName !== "") setNameError("");
  }, [signatoryName]);

  useEffect(() => {
    if (signatoryId === "") setIdError("La cédula de identidad es requerida");
    else if (signatoryId !== "") setIdError("");
    if (signatoryId.length < 7 && signatoryId !== "")
      setIdError("La cédula debe tener al menos 7 dígitos");
  }, [signatoryId]);

  useEffect(() => {
    if (signatoryName !== "") setExtName?.(signatoryName);
    else setExtName?.(undefined);

    if (signatoryId !== "") setExtId?.(signatoryId);
    else setExtId?.(undefined);

    if (fingerprint) setExtFingerprint?.(fingerprint);
    else setExtFingerprint?.(undefined);

    if (attachments) setExtAttachments?.(attachments);
    else setExtAttachments?.([]);

    if (observation !== "") setExtObservation?.(observation);
    else setExtObservation?.(undefined);

    if (checkboxChecked) setIsPhysical?.(true);
    else setIsPhysical?.(false);
  }, [
    signatoryName,
    signatoryId,
    fingerprint,
    attachments,
    observation,
    checkboxChecked,
    setExtFingerprint,
    setExtAttachments,
    setExtName,
    setExtId,
    setExtObservation,
    setIsPhysical,
  ]);

  useEffect(() => {
    const loadBUConfiguration = async () => {
      const buConfiguration = await getBUConfiguration(buCode!);
      setIsDigitalLiabilityRequired(
        buConfiguration.model?.digitalLiabilityWaiver!
      );
    };
    if (buCode) loadBUConfiguration();
  }, [buCode]);

  useEffect(() => {
    if (isModification) loadLiabilityWaiver();
  }, []);

  return (
    <Modal
      openModal={openModal}
      setOpenModal={() => null}
      className="w-full max-w-[40rem]"
    >
      <div className="flex flex-col md:flex-row md:gap-2 mb-4">
        <p className="font-light text-xl">
          {`Declaración Jurada de ${shipmentNumbers.length > 1 ? "las guías: " : "la guía: "
            }`}{" "}
          <span className="text-indigo-600">{shipmentNumbers.join(", ")}</span>
        </p>
      </div>

      <div className="flex justify-center items-center mb-4">
        <div className="bg-gray-100 p-4 rounded border border-gray-300 text-center">
          <p className="text-sm">
            <span className="font-bold">
              Declaro bajo fe de juramento, libre de apremio, coerción y por
              voluntad propia que:
            </span>
            <br />
            <span className="font-bold"> 1.</span> La encomienda consignada a
            <span className="font-bold"> TEALCA </span> tiene un origen, fines y
            naturaleza lícitos, no contiene ningún tipo de sustancia
            psicotrópica, estupefaciente, precursora o cualquiera señalada en la
            Ley Orgánica de Drogas ni bienes contrarios a la legislación
            venezolana vigente, y cumple con las{" "}
            <span className="font-bold">
              {" "}
              CONDICIONES GENERALES DEL SERVICIO{" "}
            </span>
            encontradas en{" "}
            <a
              className="text-indigo-600"
              href="https://www.tealca.com/legal/terminos-condiciones/"
            >
              https://www.tealca.com/legal/terminos-condiciones/
            </a>{" "}
            los cuales acepto íntegramente.
            <br />
            <span className="font-bold"> 2.</span> Acepto que la guía podrá ser
            objeto de inspección por parte de las autoridades competentes en
            materia de drogas u otras, por lo que libero de toda responsabilidad
            y mantendré indemne a <span className="font-bold"> TEALCA </span>,
            sus directivos y trabajadores, de toda responsabilidad en caso de
            efectuarse una retención o incautación de la guía por parte de las
            autoridades.
            <br />
            <span className="font-bold"> 3.</span> Soy responsable por todos
            aquellos daños, perjuicios y gastos ocasionados a{" "}
            <span className="font-bold"> TEALCA </span> y/o terceros producto de
            la falsedad o ausencia los datos aquí provistos y/o los documentos
            necesarios para transportar la encomienda.
          </p>
        </div>
      </div>
      {savedLiabilityWaver?.updateDate && (
        <div className="flex flex-col items-start my-4">
          <p className="text-sm font-semibold font text-gray-500">
            Última actualización de la guía:{" "}
            {new Date(savedLiabilityWaver.updateDate).toLocaleString("es-VE")}
          </p>
        </div>
      )}

      <div className="mb-6">
        <FormTextInput
          label="Nombre del remitente"
          isRequiredLabel
          name="fullName"
          type="text"
          maxLength={100}
          value={signatoryName}
          error={nameError}
          onChange={(e) => {
            const value = e.target.value;
            if (/^[a-zA-Z\s]*$/.test(value)) {
              setSignatoryName(value);
            }
          }}
        />
      </div>
      <div className="mb-6">
        <FormTextInput
          label="Cédula de Identidad"
          isRequiredLabel
          name="identityCard"
          type="text"
          maxLength={20}
          value={signatoryId}
          error={idError}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*$/.test(value)) {
              setSignatoryId(value);
            }
          }}
        />
      </div>

      {attachments.map((attachment, index) => (
        <div key={index} className="relative mb-4">
          {index > 0 && (
            <button
              className="absolute top-0 right-0 bg-transparent text-gray-600 rounded-full w-6 h-6 flex items-center justify-center"
              onClick={() => {
                const updatedAttachments = [...attachments];
                const updatedModes = [...attachmentModes];
                updatedAttachments.splice(index, 1);
                updatedModes.splice(index, 1);
                setAttachments(updatedAttachments);
                setAttachmentModes(updatedModes);
              }}
            >
              <XMarkIcon className="w-5 h-5" />
            </button>
          )}
          <div className="border border-gray-300 shadow-md rounded-xl p-4 mb-4">
            <FormSimpleRadioGroup
              horizontal
              className="mb-4"
              name={`liabilityWaiver-${index}`}
              selected={attachmentModes[index]}
              options={[
                { value: "capture", name: "Tomar foto" },
                { value: "upload", name: "Cargar Foto" },
              ]}
              onSelectOption={(option) =>
                handleAttachmentModeChange(index, option)
              }
              disabled={loading}
            />
            {attachmentModes[index] === "capture" ? (
              <WebcamCapture
                photo={attachment}
                setPhoto={(photo) => {
                  const updatedAttachments = [...attachments];
                  updatedAttachments[index] = photo!;
                  setAttachments(updatedAttachments);
                }}
                setCheck={setCheckboxChecked}
              />
            ) : (
              <FormFileUpload
                error={error}
                description="El archivo debe ser una imagen"
                onSelectFile={(file) => handleFileChange(file, index)}
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
              />
            )}
          </div>
        </div>
      ))}
      {attachments.length < 5 && !attachments.includes("") && (
        <div className="flex justify-center mb-8">
          <button
            onClick={addAttachmentSlot}
            className="flex flex-col items-center justify-center w-full border border-dashed border-gray-300 rounded-lg p-4 bg-gray-50 hover:bg-gray-100 transition"
          >
            <div className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full">
              <PlusIcon className="w-6 h-6 text-gray-600" />
            </div>
            <span className="mt-2 text-sm font-medium text-gray-600">
              Agregar otro adjunto
            </span>
          </button>
        </div>
      )}

      <div className="border border-gray-300 shadow-md rounded-xl p-4 mb-4">
        <FingerprintReader
          fingerprint={fingerprint}
          setFingerprint={setFingerprint}
          setTemplate={setFingerprintTemplate}
          setCheck={setCheckboxChecked}
        />
      </div>
      {!isDigitalLiabilityRequired && (
        <div className="flex items-center my-6">
          <input
            type="checkbox"
            id="confirmationCheckbox"
            checked={checkboxChecked}
            onChange={(e) => {
              onChangeCheckbox(e.target.checked);
            }}
            className="mr-2"
          />
          <label
            htmlFor="confirmationCheckbox"
            className="text-sm font-medium text-gray-900"
          >
            La huella y firma se registrarán en el documento físico
          </label>
        </div>
      )}

      <FormTextAreaInput
        rows={4}
        maxLength={250}
        id="observations"
        name="observations"
        label="Observaciones"
        className="resize-none"
        onChange={(e) => {
          setObservation(e.target.value);
        }}
        value={observation}
      />

      <hr className="my-4" />

      <div className="flex w-full items-center justify-between">
        <SecondaryButton
          className="w-32"
          onClick={() => {
            setOpenModal(false);
            if (!isModification) {
              setAttachments([""]);
              setFingerprint(undefined);
              setCheckboxChecked(false);
            }
          }}
        >
          {isModification ? "Cerrar" : "Cancelar"}
        </SecondaryButton>

        <PrimaryButton
          disabled={isModification ? !canUpdate : !canSubmit}
          className="w-32"
          onClick={() => {
            setOpenModal(false);
            if (isModification) onUpdateLiabilityWaver();
          }}
        >
          {isModification ? "Actualizar" : "Guardar"}
        </PrimaryButton>
      </div>
      <WarningModal
        openModal={openModalWarning}
        setOpenModal={setOpenModalWarning}
        message="¿Está seguro que registrará la declaración jurada en el documento físico? Esta acción eliminará las imágenes adjuntas y/o huella cargada."
        onPrimaryButton={() => {
          setOpenModalWarning(false);
          setAttachments([""]);
          setFingerprint(undefined);
          setCheckboxChecked(true);
        }}
      />
    </Modal>
  );
};
