import { useState } from "react";
import {
  ManifestDetailInterface,
  ManifestDetailSingleInterface,
  ManifestIncidence,
  ManifestShipmentIncidents,
} from "../../interfaces/ManifestInterface";
import { FormTextInput } from "../../components/FormFields";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import LoadingIcon from "../../components/LodingIcon";

interface dispatchTableProps {
  data?: ManifestDetailSingleInterface[] | null;
  showDayCreation?: boolean | true;
  showColumStatus?: boolean | true;
  onAdd?: (newRow: ManifestDetailInterface) => void;
  onRowClick?: (shipmentNumber: string) => void;
  ifConsult?: boolean | false;
  showItem?: boolean | true;
  showAllStatus?: boolean | false;
}

export const manifestShipmentIncident = (status: ManifestShipmentIncidents) => {
  switch (status) {
    case ManifestShipmentIncidents.ANULADAS:
      return "Anuladas";
    case ManifestShipmentIncidents.FALTANTES:
      return "Faltantes";
    default:
      return "Desconocida";
  }
};

// const INITIAL_SHIPMENT_INCIDENTS = [
//   ManifestShipmentIncidents.ANULADAS,
//   ManifestShipmentIncidents.FALTANTES,
// ].map((status) => ({
//   name: manifestShipmentIncident(status),
//   value: status,
//   checked: false,
// }));

const ManifestDetailsTable: React.FC<dispatchTableProps> = ({
  data,
  showDayCreation,
  showColumStatus,
  onRowClick,
  ifConsult,
  showItem,
  showAllStatus,
}) => {
  const [filterText, setFilterText] = useState("");

  const filteredData = data?.filter((row) => {
    const statusIsValid = showAllStatus || row.statusID !== 34; // Validación de statusID condicionada por showAllStatus

    return (
      statusIsValid && // Se aplica la validación de statusID (o se omite si showAllStatus es true)
      (row.shipmentNumber.includes(filterText) ||
        row.serviceName?.includes(filterText) ||
        row.locationDestinationCode.includes(filterText))
    );
  });

  // total de guías únicas
  const totalShipmentsUnique = filteredData
    ?.filter((x) => x.statusID !== 38 && x.statusID !== 34) // se excluyen los faltantes=38 y eliminados=34
    .reduce((acumulador, objeto) => {
      acumulador[objeto.shipmentNumber] =
        (acumulador[objeto.shipmentNumber] || 0) + 1;
      return acumulador;
    }, {} as Record<string, number>);

  const totalShipments = Object.keys(totalShipmentsUnique || {}).length;

  // total peso
  const sumTotalWeight = filteredData
    ?.filter((x) => x.statusID !== 38 && x.statusID !== 34) // se excluyen los faltantes=38 y eliminados=34
    .reduce((acumulador, objeto) => acumulador + objeto.physicalWeight, 0);

  // total de piezas, se excluyen las piezas con statusID faltantes
  const totalPiecesCount =
    filteredData?.filter((x) => x.statusID !== 38 && x.statusID !== 34)
      .length || 0;

  // const handleDelete = (id: string) => {
  //   const confirmDelete = window.confirm(
  //     "¿Estás seguro de que deseas eliminar este registro?"
  //   );
  //   if (confirmDelete) {
  //     const updatedData = tableData?.filter(
  //       (row) => row.containerDetailID !== id
  //     );
  //     setTableData(updatedData);
  //   }
  // };

  return (
    <div className="w-full">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between my-4">
        <div className="w-full sm:w-1/2 sm:pr-2 mb-4">
          <label className="block text-m font-medium leading-6 text-gray-800 mb-2 truncate">
            Filtrar
          </label>
          <FormTextInput
            type="text"
            placeholder="Por número de guía, origen, destino o servicio"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
        <div className="w-full sm:w-1/2 sm:pl-2 mb-4">
          <label className="block text-m font-medium leading-6 text-gray-800 mb-2 truncate">
            Totales
          </label>
          <div className="border bg-white rounded-xl shadow-md hover:shadow-lg flex flex-col sm:flex-row sm:items-center p-2">
            <div className="flex items-center w-full sm:w-1/3">
              <label className="block text-m leading-6 text-gray-800 truncate mb-1 sm:mb-0 mr-2">
                Total guías:
              </label>
              <div className="text-gray-700 text-sm font-semibold sm:my-1">
                {totalShipments}
              </div>
            </div>
            <div className="flex items-center w-full sm:w-1/3">
              <label className="block text-m leading-6 text-gray-800 truncate mb-1 sm:mb-0 mr-2">
                Total piezas:
              </label>
              <div className="text-gray-700 text-sm font-semibold sm:my-1">
                {totalPiecesCount}
              </div>
            </div>
            <div className="flex items-center w-full sm:w-1/3">
              <label className="block text-m leading-6 text-gray-800 truncate mb-1 sm:mb-0 mr-2">
                Total peso:
              </label>
              <div className="text-gray-700 text-sm font-semibold sm:my-1">
                {sumTotalWeight?.toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto max-h-96 overflow-y-auto">
        <table className="table-auto w-full">
          <thead>
            <tr className="text-center">
              {/* Item,Guía,Origen,Servicio,Pieza,Peso,Destino,Dias Activas,Estatus */}
              {showItem && (
                <th className="px-4 py-2 font-semibold text-xs text-gray-800">
                  ITEM
                </th>
              )}

              <th className="px-4 py-2 font-semibold text-xs text-gray-800">
                GUÍA
              </th>

              <th className="px-4 py-2 font-semibold text-xs text-gray-800">
                ORIGEN
              </th>

              <th className="px-4 py-2 font-semibold text-xs text-gray-800">
                DESTINO
              </th>

              <th className="px-4 py-2 font-semibold text-xs text-gray-800">
                SERVICIO
              </th>

              <th className="px-4 py-2 font-semibold text-xs text-gray-800">
                PIEZA
              </th>

              <th className="px-4 py-2 font-semibold text-xs text-gray-800">
                TOTAL PIEZAS
              </th>

              <th className="px-4 py-2 font-semibold text-xs text-gray-800">
                PESO
              </th>

              {showDayCreation && (
                <th className="px-4 py-2 font-semibold text-xs truncate text-gray-800">
                  DÍAS CREACIÓN
                </th>
              )}
              {showColumStatus && (
                <th className="px-4 py-2 font-semibold text-xs text-gray-800">
                  ESTATUS
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredData?.map((row, index) => (
              // <tr
              //   className={`cursor-pointer hover:bg-gray-100 text-center transition-opacity duration-500 ${
              //     index % 2 === 0 ? "bg-gray-50" : "bg-white"
              //   }`}
              <tr // si quieren resaltar los sobrantes solo falta agregar validacion statusID = 37
                className={`cursor-pointer hover:bg-gray-100 text-center transition-opacity duration-500 ${
                  row.statusID === 38 // faltante
                    ? "bg-orange-200"
                    : row.statusID === 34 // eliminado
                    ? "bg-red-200"
                    : index % 2 === 0
                    ? "bg-gray-50"
                    : "bg-white"
                }`}
                key={index}
                title={[row.errors, row.incidenceWarning ? row.warnings : ""]
                  .filter((message) => message)
                  .join(" | ")}
                onClick={() => onRowClick && onRowClick(row.shipmentNumber)}
              >
                {showItem && (
                  <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
                    {ifConsult ? index + 1 : row.lineSeqNumber}
                  </td>
                )}

                <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
                  {row.shipmentNumber}
                </td>

                <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
                  {row.buCodeSource?.toUpperCase()}
                </td>

                <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
                  {row.locationDestinationCode?.toUpperCase()}
                </td>

                <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
                  {row.serviceName?.toUpperCase()}
                </td>

                <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
                  {row.pieceNumber}
                </td>

                <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
                  {row.totalPieces}
                </td>

                <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
                  {row.physicalWeight.toFixed(2)}
                </td>

                {showDayCreation && (
                  <td className="px-4 py-4 text-xs text-gray-700 font-semibold truncate">
                    {row.daysCreation}
                  </td>
                )}

                {showColumStatus && (
                  <td className="px-4 py-2 text-xs text-gray-700 font-semibold">
                    <div className="flex justify-center items-center space-x-1">
                      <div className="w-7 h-7">
                        {row.incidence === ManifestIncidence.NOT_VALIDATED ? (
                          <LoadingIcon size="18px" />
                        ) : row.incidence === ManifestIncidence.NO_ERROR ? (
                          <CheckCircleIcon className="text-green-600 w-7 h-7" />
                        ) : row.incidence === ManifestIncidence.WARNING ? (
                          <ExclamationTriangleIcon className="text-orange-600 w-7 h-7" />
                        ) : row.incidence === ManifestIncidence.ERROR ||
                          row.incidence === ManifestIncidence.CRITIC_ERROR ? (
                          <ExclamationCircleIcon className="text-red-600 w-7 h-7" />
                        ) : (
                          <QuestionMarkCircleIcon className="text-red-600 w-7 h-7" />
                        )}
                      </div>
                      <div className="w-7 h-7">
                        {row.incidenceWarning ? (
                          <ExclamationTriangleIcon className="text-orange-600 w-7 h-7" />
                        ) : row.incidenceWarning === undefined ? (
                          <LoadingIcon size="18px" />
                        ) : (
                          <div className="w-7 h-7 invisible"></div>
                        )}
                      </div>
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManifestDetailsTable;
